<template>
  <div class="flex items-center">
    <span v-if="withText" class="font-medium text-slate-600" v-html="offText" />

    <button role="checkbox" tabindex="0" aria-checked="false" class="relative ml-3 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none" :class="toggleClasses" type="button" :disabled="disabled" @click="$emit('toggled')">
      <span aria-hidden="true" :class="{ 'translate-x-0': !value, 'translate-x-5': value }" class="inline-block size-5 transform rounded-full bg-white shadow transition duration-200 ease-in-out" />
    </button>

    <span v-if="withText" class="ml-3 font-medium text-slate-600" v-html="onText" />
  </div>
</template>

<script>
export default {
  props: {
    offText: {
      type: String,
    },
    onText: {
      type: String,
    },
    value: {
      type: Boolean,
      default: false,
    },
    onClasses: {
      type: String,
    },
    offClasses: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withText: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['toggled'],
  computed: {
    toggleClasses() {
      let classes = this.value ? this.toggleOnClasses : this.toggleOffClasses

      if (this.disabled) {
        classes += ' opacity-60 cursor-not-allowed'
      }

      return classes
    },
    toggleOnClasses() {
      return this.onClasses || 'bg-emerald-500'
    },
    toggleOffClasses() {
      return this.offClasses || 'bg-slate-100'
    },
  },
}
</script>
