<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import Avatar from '@/Components/Avatar.vue'
import { Link } from '@inertiajs/vue3'

const navigation = [
  { name: 'Features', href: '/#features', is: 'a' },
  { name: 'Pricing', href: '/pricing', is: Link },
  { name: 'Documentation', href: 'https://docs.checkmango.com', is: 'a' },
  { name: 'Developers', href: 'https://developers.checkmango.com', is: 'a' },
]

const mobileMenuOpen = ref(false)

function close() {
  mobileMenuOpen.value = false
}
</script>

<template>
  <header class="mb-4 border-b">
    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex lg:flex-1">
        <Link href="/" class="-m-1.5 p-1.5" title="Checkmango">
          <span class="sr-only">Checkmango</span>
          <Avatar class="h-12 w-12 rounded-full" />
        </Link>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-slate-900" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="size-6" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <component :is="item.is" v-for="item in navigation" :key="item.name" :href="item.href" class="font-display leading-6 text-slate-900">{{ item.name }}</component>
      </div>
      <div class="hidden items-center space-x-4 lg:flex lg:flex-1 lg:justify-end">
        <Link :href="route('login')" class="font-display leading-6 text-slate-900">Sign In</Link>
        <Link :href="route('register')" class="button button--success">Get Started</Link>
      </div>
    </nav>
    <Dialog as="div" class="lg:hidden" :open="mobileMenuOpen" @close="mobileMenuOpen = false">
      <div class="fixed inset-0 z-10" />
      <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-slate-900/10">
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Checkmango</span>
            <Avatar class="size-8 rounded-full" />
          </a>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-slate-700" @click="close">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="size-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-slate-500/10">
            <div class="space-y-2 py-6">
              <component :is="item.is" v-for="item in navigation" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-slate-900 hover:bg-slate-50" @click="close">{{ item.name }}</component>
            </div>
            <div class="py-6">
              <Link :href="route('login')" class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-slate-900 hover:bg-slate-50" @click="close">Sign In</Link>
              <Link :href="route('register')" class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-slate-900 hover:bg-slate-50" @click="close">Get Started</Link>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>
