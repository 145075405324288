<template>
  <Popper ref="dropdown">
    <slot name="trigger" />

    <template #content>
      <div class="absolute mt-2 rounded-md shadow-lg" :class="[widthClass, alignmentClasses]">
        <div class="rounded-md ring-1 ring-black ring-opacity-5" :class="contentClasses">
          <slot name="content" />
        </div>
      </div>
    </template>
  </Popper>
</template>

<script>
import { defineComponent } from 'vue'
import Popper from 'vue3-popper'

export default defineComponent({
  components: {
    Popper,
  },
  props: {
    align: {
      type: String,
      default: 'right',
    },
    width: {
      type: String,
      default: '48',
    },
    contentClasses: {
      type: Array,
      default: () => ['py-1', 'bg-white'],
    },
  },
  computed: {
    widthClass() {
      return {
        48: 'w-48',
      }[this.width.toString()]
    },
    alignmentClasses() {
      if (this.align === 'left') {
        return 'origin-top-left left-0'
      } else if (this.align === 'right') {
        return 'origin-top-right right-0'
      } else {
        return 'origin-top'
      }
    },
  },
})
</script>
