<template>
  <div :class="alertClass" class="flex flex-row rounded-lg border p-4 text-sm font-normal">
    <InformationCircleIcon :class="iconClass" class="size-6 flex-shrink-0" />
    <div class="ml-4 mt-0.5 flex flex-grow">
      <p :class="bannerClass"><slot /></p>
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    InformationCircleIcon,
  },
  props: {
    bannerClass: String,
    type: {
      type: String,
      default: 'info',
    },
  },
  computed: {
    alertClass() {
      if (this.type === 'success') {
        return 'bg-emerald-50 border-emerald-400 text-emerald-900'
      }

      if (this.type === 'error') {
        return 'bg-red-50 border-red-400 text-red-900'
      }

      if (this.type === 'warning') {
        return 'bg-yellow-50 border-yellow-400 text-yellow-900'
      }

      return 'bg-blue-50 border-blue-400 text-blue-900'
    },
    iconClass() {
      if (this.type === 'success') {
        return 'text-green-400'
      }

      if (this.type === 'error') {
        return 'text-red-400'
      }

      if (this.type === 'warning') {
        return 'text-yellow-400'
      }

      return 'text-blue-400'
    },
  },
}
</script>
