<template>
  <div>
    <!-- Generate API Token -->
    <FormSection @submitted="createApiToken">
      <template #title> Create API Token </template>

      <template #description> API tokens allow third-party services to authenticate with Checkmango on your behalf. </template>

      <template #form>
        <!-- Token Name -->
        <div class="col-span-6 sm:col-span-4">
          <Label for="name" value="Name" />
          <Input id="name" v-model="createApiTokenForm.name" type="text" class="mt-1 block w-full" autofocus />
          <InputError :message="createApiTokenForm.errors.name" class="mt-2" />
        </div>

        <!-- Token Permissions -->
        <div v-if="availablePermissions.length > 0" class="col-span-6">
          <Label for="permissions" value="Permissions" />

          <div class="mt-2 grid grid-cols-1 gap-4 md:grid-cols-2">
            <div v-for="permission in availablePermissions" :key="permission">
              <label class="flex items-center">
                <Checkbox v-model:checked="createApiTokenForm.permissions" :value="permission" />
                <span class="ml-2 text-slate-600">{{ permission }}</span>
              </label>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <ActionMessage :on="createApiTokenForm.recentlySuccessful" class="mr-3"> Created. </ActionMessage>

        <Button :class="{ 'opacity-25': createApiTokenForm.processing }" :disabled="createApiTokenForm.processing"> Create </Button>
      </template>
    </FormSection>

    <div v-if="tokens.length > 0">
      <SectionBorder />

      <!-- Manage API Tokens -->
      <div class="mt-10 sm:mt-0">
        <ActionSection>
          <template #title> Manage API Tokens </template>

          <template #description> You may delete any of your existing tokens if they are no longer needed. </template>

          <!-- API Token List -->
          <template #content>
            <div class="space-y-6">
              <div v-for="token in tokens" :key="token.id" class="flex items-center justify-between">
                <div>
                  {{ token.name }}
                </div>

                <div class="flex items-center">
                  <div v-if="token.last_used_ago" class="text-slate-400">Last used {{ token.last_used_ago }}</div>

                  <button v-if="availablePermissions.length > 0" class="ml-6 cursor-pointer text-slate-400 underline" @click="manageApiTokenPermissions(token)">Permissions</button>

                  <button class="ml-6 cursor-pointer text-red-500" @click="confirmApiTokenDeletion(token)">Delete</button>
                </div>
              </div>
            </div>
          </template>
        </ActionSection>
      </div>
    </div>

    <!-- Token Value Modal -->
    <DialogModal :show="displayingToken" @close="displayingToken = false">
      <template #title> API Token </template>

      <template #content>
        <div>Please copy your new API token. For your security, it won't be shown again.</div>

        <Clipboard v-if="$page.props.jetstream.flash.token" class="mt-4">
          {{ $page.props.jetstream.flash.token }}
        </Clipboard>
      </template>

      <template #footer>
        <SecondaryButton @click="displayingToken = false"> Close </SecondaryButton>
      </template>
    </DialogModal>

    <!-- API Token Permissions Modal -->
    <DialogModal :show="managingPermissionsFor" @close="managingPermissionsFor = null">
      <template #title> API Token Permissions </template>

      <template #content>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div v-for="permission in availablePermissions" :key="permission">
            <label class="flex items-center">
              <Checkbox v-model:checked="updateApiTokenForm.permissions" :value="permission" />
              <span class="ml-2 text-slate-600">{{ permission }}</span>
            </label>
          </div>
        </div>
      </template>

      <template #footer>
        <SecondaryButton @click="managingPermissionsFor = null"> Never mind </SecondaryButton>

        <Button class="ml-2" :class="{ 'opacity-25': updateApiTokenForm.processing }" :disabled="updateApiTokenForm.processing" @click="updateApiToken"> Save </Button>
      </template>
    </DialogModal>

    <!-- Delete Token Confirmation Modal -->
    <ConfirmationModal :show="apiTokenBeingDeleted" @close="apiTokenBeingDeleted = null">
      <template #title> Delete API Token </template>

      <template #content> Are you sure you would like to delete this API token? </template>

      <template #footer>
        <SecondaryButton @click="apiTokenBeingDeleted = null"> Never mind </SecondaryButton>

        <DangerButton class="ml-2" :class="{ 'opacity-25': deleteApiTokenForm.processing }" :disabled="deleteApiTokenForm.processing" @click="deleteApiToken"> Delete </DangerButton>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script>
import ActionMessage from '@/Jetstream/ActionMessage.vue'
import ActionSection from '@/Jetstream/ActionSection.vue'
import Button from '@/Jetstream/Button.vue'
import ConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import DialogModal from '@/Jetstream/DialogModal.vue'
import FormSection from '@/Jetstream/FormSection.vue'
import Input from '@/Jetstream/Input.vue'
import Checkbox from '@/Jetstream/Checkbox.vue'
import InputError from '@/Jetstream/InputError.vue'
import Label from '@/Jetstream/Label.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import SectionBorder from '@/Jetstream/SectionBorder.vue'
import Clipboard from '@/Components/Clipboard.vue'

export default {
  components: {
    Clipboard,
    ActionMessage,
    ActionSection,
    Button,
    ConfirmationModal,
    DangerButton,
    DialogModal,
    FormSection,
    Input,
    Checkbox,
    InputError,
    Label,
    SecondaryButton,
    SectionBorder,
  },

  props: {
    availablePermissions: Array,
    defaultPermissions: Array,
    tokens: Array,
  },

  data() {
    return {
      createApiTokenForm: this.$inertia.form({
        name: '',
        permissions: this.defaultPermissions,
      }),

      updateApiTokenForm: this.$inertia.form({
        permissions: [],
      }),

      deleteApiTokenForm: this.$inertia.form({}),

      displayingToken: false,
      managingPermissionsFor: null,
      apiTokenBeingDeleted: null,
    }
  },

  methods: {
    createApiToken() {
      this.createApiTokenForm.post(this.route('api-tokens.store'), {
        preserveScroll: true,
        onSuccess: () => {
          this.displayingToken = true
          this.createApiTokenForm.reset()
        },
      })
    },

    manageApiTokenPermissions(token) {
      this.updateApiTokenForm.permissions = token.abilities

      this.managingPermissionsFor = token
    },

    updateApiToken() {
      this.updateApiTokenForm.put(this.route('api-tokens.update', this.managingPermissionsFor), {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => (this.managingPermissionsFor = null),
      })
    },

    confirmApiTokenDeletion(token) {
      this.apiTokenBeingDeleted = token
    },

    deleteApiToken() {
      this.deleteApiTokenForm.delete(this.route('api-tokens.destroy', this.apiTokenBeingDeleted), {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => (this.apiTokenBeingDeleted = null),
      })
    },
  },
}
</script>
