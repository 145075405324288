<template>
  <component :is="component" v-bind="$attrs" :href="href">
    <slot />
  </component>
</template>

<script>
import JetDropdownButton from '@/Jetstream/DropdownButton.vue'
import JetDropdownItem from '@/Jetstream/DropdownItem.vue'
import JetDropdownButtonItem from '@/Jetstream/DropdownButtonItem.vue'
import JetDropdownInertiaItem from '@/Jetstream/DropdownInertiaItem.vue'

export default {
  components: {
    JetDropdownButton,
    JetDropdownItem,
    JetDropdownInertiaItem,
  },
  props: {
    href: String,
    as: String,
  },
  computed: {
    component() {
      if (this.as === 'button') {
        return JetDropdownButtonItem
      }

      if (this.as === 'anchor') {
        return JetDropdownItem
      }

      return JetDropdownInertiaItem
    },
  },
}
</script>
