<template>
  <div>
    <Head :title="title">
      <meta name="theme-color" content="#ffffff" />
    </Head>

    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 xl:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-slate-900/60" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="size-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>

              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 ring-1 ring-white/10">
                <div class="flex h-16 shrink-0 items-center">
                  <ApplicationLogo class="h-10 w-auto" />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <Link :href="item.href" :class="[route().current(item.active) ? 'bg-emerald-500 text-white' : 'text-slate-600 hover:bg-emerald-500 hover:text-white', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']" :title="item.title">
                            <component :is="item.icon" class="size-6 shrink-0" aria-hidden="true" />
                            {{ item.name }}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div class="text-xs font-semibold leading-6 text-slate-400">Your teams</div>
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="team in teams" :key="team.name">
                          <form @submit.prevent="switchToTeam(team)">
                            <button :class="[team.id === currentTeam.id && !route().current('teams.create') ? 'bg-slate-100 text-slate-600' : 'text-slate-600 hover:bg-slate-200 hover:text-slate-600', 'group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']">
                              <div class="flex shrink-0 items-center justify-center rounded-full bg-slate-100 font-bold text-emerald-100 group-hover:text-white" :class="[maxSidebar && 'size-6 text-[0.825rem]', !maxSidebar && 'h-12 w-12 text-lg']">
                                <img :src="`https://source.boringavatars.com/marble/120/${team.name}?colors=FFAF3F,F74D3B,7BAB93`" :alt="team.name" />
                              </div>
                              <span class="truncate" :class="{ hidden: !maxSidebar }">{{ team.name }}</span>
                            </button>
                          </form>
                        </li>
                        <li>
                          <Link :href="route('teams.create')" :class="route().current('teams.create') ? 'bg-emerald-500 text-white' : 'text-slate-600 hover:bg-slate-200 hover:text-slate-600'" class="group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6">
                            <span class="flex size-6 shrink-0 items-center justify-center rounded-full bg-yellow-400 text-[0.825rem] font-bold text-yellow-700 group-hover:text-white">+</span>
                            <span class="truncate">Create New Team</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <Menu as="li" class="-mx-6 mt-auto">
                      <Float portal>
                        <MenuButton class="flex w-full items-center gap-x-4 px-6 py-3 font-semibold leading-6 text-slate-600 hover:bg-slate-100">
                          <span class="flex w-full items-center justify-between">
                            <span class="flex min-w-0 items-center justify-between space-x-3">
                              <img class="size-10 flex-shrink-0 rounded-full bg-slate-300" :src="user.profile_photo_url" :alt="user.name" />
                              <span class="flex min-w-0 flex-1 flex-col text-left">
                                <span class="truncate text-sm font-medium text-slate-900">{{ user.name }}</span>
                                <span class="truncate text-sm text-slate-500">{{ currentTeam.name }}</span>
                              </span>
                            </span>
                            <ChevronUpDownIcon class="size-5 flex-shrink-0 text-slate-400 group-hover:text-slate-500" aria-hidden="true" />
                          </span>
                        </MenuButton>

                        <MenuItems as="div" class="absolute bottom-16 left-4 w-72 origin-top-right divide-y divide-slate-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div v-if="currentTeam.subscription.freeAccount" class="px-1 py-1">
                            <MenuItem as="div">
                              <span class="flex items-center justify-between gap-x-2 rounded px-4 py-2 font-semibold text-slate-700">FREE Account! <SparklesIcon class="size-5 text-yellow-500" /></span>
                            </MenuItem>
                          </div>

                          <div class="px-1 py-1">
                            <MenuItem v-slot="{ active, close }" as="div">
                              <Link :href="route('profile.show')" :class="[active ? 'bg-slate-100' : '', 'block rounded px-4 py-2 text-slate-700']" @click="close">Manage Your Account</Link>
                            </MenuItem>
                          </div>

                          <div class="px-1 py-1">
                            <MenuItem v-slot="{ active }" as="div">
                              <a href="https://docs.checkmango.com" :class="[active ? 'bg-slate-100' : '', 'block rounded px-4 py-2 text-slate-700']">Documentation</a>
                            </MenuItem>

                            <MenuItem v-slot="{ active }" as="div">
                              <a href="https://developers.checkmango.com" :class="[active ? 'bg-slate-100' : '', 'block rounded px-4 py-2 text-slate-700']">Developer Documentation</a>
                            </MenuItem>
                          </div>

                          <div class="px-1 py-1">
                            <template v-if="user.is_admin">
                              <MenuItem v-slot="{ active, close }" as="div">
                                <Link :href="route('dashboard.nova')" :class="[active ? 'bg-slate-100' : '', 'block rounded px-4 py-2 text-slate-700']" @click="close">Admin Panel</Link>
                              </MenuItem>

                              <MenuItem v-slot="{ active, close }" as="div">
                                <Link :href="route('horizon.index')" :class="[active ? 'bg-slate-100' : '', 'block rounded px-4 py-2 text-slate-700']" @click="close">Horizon</Link>
                              </MenuItem>

                              <MenuItem v-slot="{ active, close }" as="div">
                                <Link href="/pulse" :class="[active ? 'bg-slate-100' : '', 'block rounded px-4 py-2 text-slate-700']" @click="close">Pulse</Link>
                              </MenuItem>
                            </template>

                            <MenuItem as="div">
                              <form class="my-0" @submit.prevent="logout">
                                <DropdownLink as="button" class="block rounded px-4 py-2 !text-left text-slate-700"> Log out </DropdownLink>
                              </form>
                            </MenuItem>
                          </div>
                        </MenuItems>
                      </Float>
                    </Menu>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex" :class="{ 'lg:w-72': maxSidebar, 'lg:w-24 ': !maxSidebar }">
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 shadow ring-1 ring-white/5">
        <div class="flex shrink-0 items-center justify-center py-2">
          <Link :href="route('dashboard')">
            <Avatar class="h-12 w-12 rounded-full" />
          </Link>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <Link :href="item.href" :class="[route().current(item.active) ? 'bg-emerald-500 text-white' : 'text-slate-500 hover:bg-emerald-500 hover:text-white', !maxSidebar && ' justify-center px-2 py-4', maxSidebar && 'px-3 py-2', 'group flex gap-x-3 rounded-md text-sm font-semibold leading-6']" :title="item.title">
                    <component :is="item.icon" :class="[maxSidebar && 'size-6', !maxSidebar && 'size-6', 'shrink-0']" aria-hidden="true" />
                    <span :class="{ hidden: !maxSidebar }">{{ item.name }}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div class="text-xs font-semibold leading-6 text-slate-400">Teams</div>
              <ul role="list" class="-mx-2 mt-2 space-y-1">
                <li v-for="team in teams" :key="team.name">
                  <form @submit.prevent="switchToTeam(team)">
                    <button v-tippy :class="[team.id === currentTeam.id && !route().current('teams.create') ? 'bg-slate-100 text-slate-600' : 'text-slate-600 hover:bg-slate-200 hover:text-slate-600', 'group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']" :title="`Switch to ${team.name}`">
                      <div class="flex shrink-0 items-center justify-center rounded-full bg-slate-100 font-bold text-emerald-100 group-hover:text-white" :class="[maxSidebar && 'size-6 text-[0.825rem]', !maxSidebar && 'h-12 w-12 text-lg']">
                        <img :src="`https://source.boringavatars.com/marble/120/${team.name}?colors=FFAF3F,F74D3B,7BAB93`" :alt="team.name" />
                      </div>
                      <span class="truncate" :class="{ hidden: !maxSidebar }">{{ team.name }}</span>
                    </button>
                  </form>
                </li>
                <li>
                  <Link :href="route('teams.create')" :class="route().current('teams.create') ? 'bg-emerald-500 text-white' : 'text-slate-600 hover:bg-slate-200 hover:text-slate-600'" class="group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6">
                    <span class="flex shrink-0 items-center justify-center rounded-full bg-yellow-400 font-bold text-yellow-700 group-hover:text-white" :class="[maxSidebar && 'size-6 text-[0.825rem]', !maxSidebar && 'h-12 w-12 text-lg']">+</span>
                    <span class="truncate" :class="{ hidden: !maxSidebar }">Create New Team</span>
                  </Link>
                </li>
              </ul>
            </li>
            <Menu as="li" class="-mx-6 mt-auto">
              <Float portal>
                <MenuButton class="flex w-full items-center gap-x-4 px-6 py-3 font-semibold leading-6 text-slate-600 hover:bg-slate-100">
                  <span class="flex w-full items-center" :class="[maxSidebar && 'justify-between', !maxSidebar && 'justify-center']">
                    <span class="flex min-w-0 items-center justify-between space-x-3">
                      <img class="size-10 flex-shrink-0 rounded-full bg-slate-300" :src="user.profile_photo_url" :alt="user.name" />
                      <span class="flex min-w-0 flex-1 flex-col text-left" :class="[!maxSidebar && 'hidden']">
                        <span class="truncate text-sm font-medium text-slate-900">{{ user.name }}</span>
                        <span class="truncate text-sm text-slate-500">{{ currentTeam.name }}</span>
                      </span>
                    </span>
                    <ChevronUpDownIcon class="size-5 flex-shrink-0 text-slate-400 group-hover:text-slate-500" :class="[!maxSidebar && 'hidden']" aria-hidden="true" />
                  </span>
                </MenuButton>

                <MenuItems class="absolute bottom-16 left-4 w-64 origin-top-right divide-y divide-slate-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div v-if="currentTeam.subscription.freeAccount" class="px-1 py-1">
                    <MenuItem as="div">
                      <span class="block flex items-center justify-between gap-x-2 rounded px-4 py-2 font-semibold text-slate-700">FREE Account! <SparklesIcon class="size-5 text-yellow-500" /></span>
                    </MenuItem>
                  </div>

                  <div class="px-1 py-1">
                    <MenuItem v-slot="{ active, close }" as="div">
                      <Link :href="route('profile.show')" :class="[active ? 'bg-slate-100' : '', 'block rounded px-4 py-2 text-slate-700']" @click="close">Manage Your Account</Link>
                    </MenuItem>
                  </div>

                  <div class="px-1 py-1">
                    <MenuItem v-slot="{ active }" as="div">
                      <a href="https://docs.checkmango.com" :class="[active ? 'bg-slate-100' : '', 'block rounded px-4 py-2 text-slate-700']">Documentation</a>
                    </MenuItem>

                    <MenuItem v-slot="{ active }" as="div">
                      <a href="https://developers.checkmango.com" :class="[active ? 'bg-slate-100' : '', 'block rounded px-4 py-2 text-slate-700']">Developer Documentation</a>
                    </MenuItem>
                  </div>

                  <div class="px-1 py-1">
                    <MenuItem v-slot="{ active, close }" as="div">
                      <Link v-if="user.is_admin" :href="route('dashboard.nova')" :class="[active ? 'bg-slate-100' : '', 'block rounded px-4 py-2 text-slate-700']" @click="close">Admin Panel</Link>
                    </MenuItem>
                    <MenuItem as="div">
                      <form class="my-0" @submit.prevent="logout">
                        <DropdownLink as="button" class="block rounded px-4 py-2 !text-left text-slate-700"> Log out </DropdownLink>
                      </form>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Float>
            </Menu>
          </ul>
        </nav>
      </div>
      <!-- Sidebar Hover Border -->
      <div class="absolute inset-y-0 right-0 w-0.5 cursor-ew-resize bg-slate-50 hover:w-1.5 hover:bg-slate-200" @click="toggleSidebarDisplay" />
    </div>

    <div :class="{ 'lg:pl-72': maxSidebar, 'lg:pl-24  ': !maxSidebar }">
      <Impersonate :user="user" />

      <TrialBanner :subscription="currentTeam.subscription" />

      <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-white px-4 shadow-sm sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="size-5" aria-hidden="true" />
        </button>

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <div class="flex flex-1 justify-end">
            <div class="hidden flex-1 py-0 sm:flex">
              <Breadcrumbs :links="links" />
            </div>

            <div v-if="$slots.headerActions">
              <div class="ml-4 flex h-full items-center md:ml-6">
                <slot name="headerActions" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <main>
        <header v-if="secondaryNavigation.length" class="flex overflow-x-auto bg-slate-100">
          <div class="w-full p-4 sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select id="tabs" name="tabs" class="block w-full rounded-md border-slate-300 py-2 pl-3 pr-10 text-base focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm" @change="changeTab">
              <option v-for="tab in secondaryNavigation" :key="tab.name" :selected="tab.current" :value="tab.href">{{ tab.name }}</option>
            </select>
          </div>
          <div class="hidden w-full sm:block">
            <div class="border-b border-slate-200">
              <nav class="-mb-px flex space-x-8 px-4" aria-label="Tabs">
                <a v-for="tab in secondaryNavigation" :key="tab.name" :href="tab.href" :class="[tab.current ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700', 'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</a>
              </nav>
            </div>
          </div>
        </header>

        <section class="divide-y divide-white/5">
          <div class="px-4 py-8 sm:px-6 lg:px-8">
            <slot />
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot, Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import { BeakerIcon, BoltIcon, CheckCircleIcon, ChevronUpDownIcon, UsersIcon, HomeIcon, KeyIcon, SparklesIcon, UserPlusIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { Bars3Icon } from '@heroicons/vue/20/solid'
import Breadcrumbs from '@/Components/Breadcrumbs.vue'
import ApplicationMark from '@/Jetstream/ApplicationMark.vue'
import Avatar from '@/Components/Avatar.vue'
import { defineAsyncComponent, markRaw } from 'vue'
import { Head, Link } from '@inertiajs/vue3'
import TrialBanner from '@/Components/TrialBanner.vue'
import DropdownLink from '@/Jetstream/DropdownLink.vue'
const Impersonate = defineAsyncComponent(() => import('@/Components/Impersonate.vue'))
import ApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
import ToggleIcon from '@/Icons/ToggleIcon.vue'
import ParticipantsIcon from '@/Icons/ParticipantsIcon.vue'
import WebhooksIcon from '@/Icons/WebhooksIcon.vue'

export default {
  components: {
    ApplicationLogo,
    ApplicationMark,
    Avatar,
    Bars3Icon,
    BeakerIcon,
    BoltIcon,
    Breadcrumbs,
    CheckCircleIcon,
    ChevronUpDownIcon,
    UsersIcon,
    Dialog,
    DialogPanel,
    DropdownLink,
    Float,
    Head,
    HomeIcon,
    Impersonate,
    KeyIcon,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    SparklesIcon,
    TransitionChild,
    TransitionRoot,
    TrialBanner,
    UserPlusIcon,
    XMarkIcon,
    ParticipantsIcon,
  },
  props: {
    links: Array,
    title: String,
    secondaryNavigation: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sidebarOpen: false,
      maxSidebar: true,
    }
  },
  computed: {
    navigation() {
      return [
        {
          name: 'Dashboard',
          active: 'dashboard',
          href: this.route('dashboard'),
          icon: markRaw(HomeIcon),
          title: 'Dashboard',
        },
        {
          name: 'Experiments',
          active: 'experiments*',
          href: this.route('experiments'),
          icon: markRaw(BeakerIcon),
          title: 'Experiments',
        },
        {
          name: 'Events',
          active: 'events*',
          href: this.route('events'),
          icon: markRaw(BoltIcon),
          title: 'Events',
        },
        {
          name: 'Features',
          active: 'features*',
          href: this.route('features'),
          icon: markRaw(ToggleIcon),
          title: 'Features',
        },
        {
          name: 'Participants',
          active: 'participants*',
          href: this.route('participants'),
          icon: markRaw(ParticipantsIcon),
          title: 'Participants',
        },
        {
          name: 'Webhooks',
          active: 'webhooks*',
          href: this.route('webhooks'),
          icon: markRaw(WebhooksIcon),
          title: 'Webhooks',
        },
        {
          name: 'API Tokens',
          active: 'api-tokens*',
          href: this.route('api-tokens.index'),
          icon: markRaw(KeyIcon),
          title: 'API Tokens',
        },
        {
          name: 'Team Settings',
          active: 'teams*',
          href: this.route('teams.show', this.currentTeam),
          icon: markRaw(UsersIcon),
          title: 'Manage Your Team',
        },
      ]
    },
    user() {
      return this.$page.props.user
    },
    currentTeam() {
      return this.user.current_team
    },
    teams() {
      return this.user.all_teams
    },
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler() {
        this.updateHelpScoutBeacon()
      },
    },
  },
  mounted() {
    if (window.localStorage.getItem('maxSidebar') === 'small') {
      this.$nextTick(() => (this.maxSidebar = false))
    }
  },
  methods: {
    changeTab(event) {
      this.$inertia.visit(event.target.value)
    },
    switchToTeam(team) {
      this.$inertia.put(
        this.route('current-team.update'),
        {
          team_id: team.id,
        },
        {
          preserveState: false,
        },
      )
    },
    toggleSidebarDisplay() {
      this.maxSidebar = !this.maxSidebar

      window.localStorage.setItem('maxSidebar', this.maxSidebar ? 'big' : 'small')
    },
    updateHelpScoutBeacon() {
      if (typeof window === 'undefined' || typeof window.Beacon === 'undefined') {
        return
      }
      window.Beacon('identify', {
        id: this.user.id,
        avatar: this.user.profile_photo_url,
        name: this.user.name,
        email: this.user.email,
      })
    },
    logout() {
      this.$inertia.post(this.route('logout'))
    },
  },
}
</script>
