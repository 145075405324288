<template>
  <AppLayout title="Events" :links="links" :user="user">
    <template #headerActions>
      <LinkButton v-if="can.create_event" :href="route('events.create')"> Create Event </LinkButton>
    </template>

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="mb-8 overflow-hidden bg-white shadow-sm ring-1 ring-slate-900/5 md:rounded-xl">
        <EventsTable :events="events" title="Events" subtitle="The goals of your experiments." :no-filter-results="noFilterResults" />
      </div>
    </div>

    <JetConfirmationModal :show="eventToDelete !== null" @close="eventToDelete = null">
      <template #title> Delete Event </template>

      <template #content>
        <p>Are you sure you want to delete this event? It will no longer be tracked against any running experiments.</p>

        <p>Keep in mind that this action cannot be undone.</p>
      </template>

      <template #footer>
        <JetSecondaryButton @click="eventToDelete = null"> Never mind </JetSecondaryButton>

        <JetDangerButton class="ml-2" @click="deleteEvent"> Delete Event </JetDangerButton>
      </template>
    </JetConfirmationModal>
  </AppLayout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import LinkButton from '@/Components/LinkButton.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import EventsTable from './Partials/Table.vue'

export default {
  components: {
    AppLayout,
    EventsTable,
    LinkButton,
    JetConfirmationModal,
    JetSecondaryButton,
    JetDangerButton,
  },
  props: {
    can: Object,
    links: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Object,
    },
    counts: Object,
    noFilterResults: Boolean,
    user: Object,
  },
  data: () => ({
    eventToDelete: null,
  }),
  methods: {
    deleteEvent() {
      this.$inertia.delete(this.route('events.destroy', this.eventToDelete.key))

      this.eventToDelete = null
    },
  },
}
</script>
