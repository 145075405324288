<template>
  <Modal :show="show" :max-width="maxWidth" :closeable="closeable" @close="close">
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon class="size-6 text-red-600" />
        </div>

        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="font-display text-lg font-semibold tracking-normal"><slot name="title" /></h3>

          <div class="prose mt-2">
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>

    <div class="bg-slate-100 px-6 py-4 text-right">
      <slot name="footer" />
    </div>
  </Modal>
</template>

<script>
import Modal from './Modal.vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    ExclamationTriangleIcon,
    Modal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '2xl',
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['close'],

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
