<template>
  <div>
    <ul role="list" class="grid grid-cols-1 gap-4" :class="[`md:${gridCols}`]">
      <li v-for="variant in variants" :key="variant.id" class="overflow-hidden rounded-xl border border-slate-200">
        <div class="flex items-center gap-x-4 border-b border-slate-900/5 bg-white p-6">
          <div class="font-mono text-sm font-medium leading-6 text-slate-900">{{ variant.key }}</div>

          <span v-if="experiment.winning_variant_id === variant.id" class="flex items-center gap-1 text-emerald-500">
            <CheckBadgeIcon class="size-5" />
            Winner
          </span>

          <Menu as="div" class="relative ml-auto">
            <MenuButton class="-m-2.5 block p-2.5 text-slate-400 hover:text-slate-500">
              <span class="sr-only">Open options</span>
              <EllipsisHorizontalIcon class="size-5" aria-hidden="true" />
            </MenuButton>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-slate-900/5 focus:outline-none">
                <MenuItem v-if="variant.can.view_variant" v-slot="{ active }" as="div">
                  <Link :href="route('experiments.variants.show', [experiment.key, variant.key])" :class="[active ? 'bg-slate-50' : '', 'block px-3 py-1 text-sm leading-6 text-slate-900']">
                    View<span class="sr-only">, {{ variant.key }}</span>
                  </Link>
                </MenuItem>
                <MenuItem v-if="experiment.is_running && can.stop_experiment" v-slot="{ active }" as="div">
                  <span :class="[active ? 'bg-slate-50' : '', 'block cursor-pointer px-3 py-1 text-sm leading-6 text-slate-900']" @click="variantToWin = variant">Set Winner<span class="sr-only">, {{ variant.key }}</span></span>
                </MenuItem>
                <MenuItem v-if="variant.can.delete_variant && experiment.is_draft" v-slot="{ active }" as="div">
                  <span :class="[active ? 'bg-slate-50 text-red-400' : '', 'block cursor-pointer px-3 py-1 text-sm leading-6 text-slate-900']" @click="variantToDelete = variant">Delete<span class="sr-only">, {{ variant.key }}</span></span>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <dl class="-my-3 divide-y divide-slate-100 bg-white/40 px-6 py-4 text-sm leading-6">
          <div class="flex justify-between gap-x-4 py-3">
            <dt class="text-slate-500">Control</dt>
            <dd class="text-slate-700">
              <CheckIcon v-if="variant.control" class="size-5 text-emerald-500" />
              <XMarkIcon v-else class="size-5" />
            </dd>
          </div>
          <div class="flex justify-between gap-x-4 py-3">
            <dt class="text-slate-500">Participants</dt>
            <dd class="font-mono text-slate-700">
              {{ numberFormat(variant.participant_count) }}
            </dd>
          </div>
          <div class="flex justify-between gap-x-4 py-3">
            <dt class="text-slate-500">Impressions</dt>
            <dd class="flex items-start gap-x-2">
              <div class="font-mono text-slate-700">{{ numberFormat(variant.impression_count) }}</div>
            </dd>
          </div>
          <div class="flex justify-between gap-x-4 py-3">
            <dt class="text-slate-500">Conversions</dt>
            <dd class="flex items-start gap-x-2">
              <div class="font-mono text-slate-700">{{ numberFormat(variant.conversion_count) }}</div>
            </dd>
          </div>
          <div class="flex justify-between gap-x-4 py-3">
            <dt class="text-slate-500">Conversion Rate</dt>
            <dd class="flex items-start gap-x-2">
              <div class="font-mono text-slate-700">{{ percentFormatter(experiment.event.type === 'count' ? variant.count_conversion_rate : variant.unique_conversion_rate) }}</div>
            </dd>
          </div>
          <div class="flex justify-between gap-x-4 py-3">
            <dt class="text-slate-500">Relative Uplift</dt>
            <dd class="flex items-start gap-x-2">
              <div v-if="variant.control" class="font-medium text-slate-400">&mdash;</div>
              <div v-else class="flex items-center gap-2 font-mono" :class="[variant.uplift > 0 && 'font-semibold text-emerald-500', variant.uplift < 0 && 'text-rose-700']">
                {{ percentFormatter(variant.uplift) }}
                <template v-if="variant.uplift">
                  <ArrowTrendingUpIcon v-if="variant.uplift > 0" class="size-4" />
                  <ArrowTrendingDownIcon v-else class="size-4" />
                </template>
              </div>
            </dd>
          </div>
          <div class="flex justify-between gap-x-4 py-3">
            <dt class="flex items-center text-slate-500">
              <span>Observed Power</span>
              <QuestionMarkCircleIcon v-tippy="{ content: observedPowerTitle }" class="ml-2 size-4 cursor-help" />
            </dt>
            <dd class="flex items-start gap-x-2">
              <div v-if="variant.control" class="font-medium text-slate-400">&mdash;</div>
              <div v-else class="font-mono text-slate-700">{{ percentFormatter(variant.power || 0) }}</div>
            </dd>
          </div>
          <div class="flex justify-between gap-x-4 py-3">
            <dt class="flex items-center text-slate-500">
              <span>Confidence</span>
            </dt>
            <dd class="flex items-start gap-x-2">
              <div v-if="variant.control" class="font-medium text-slate-400">&mdash;</div>
              <div v-else class="font-mono text-slate-700">{{ percentFormatter(variant.confidence || 0) }}</div>
            </dd>
          </div>
          <!--          <div class="flex justify-between gap-x-4 py-3">-->
          <!--            <dt class="text-slate-500">Z-Score</dt>-->
          <!--            <dd class="flex items-start gap-x-2">-->
          <!--              <div v-if="variant.control" class="font-medium text-slate-400">&mdash;</div>-->
          <!--              <div v-else class="text-slate-700 font-mono">{{ variant.z_score || 0.0 }}</div>-->
          <!--            </dd>-->
          <!--          </div>-->
          <!--          <div class="flex justify-between gap-x-4 py-3">-->
          <!--            <dt class="text-slate-500">P-Value</dt>-->
          <!--            <dd class="flex items-start gap-x-2">-->
          <!--              <div v-if="variant.control" class="font-medium text-slate-400">&mdash;</div>-->
          <!--              <div v-else class="text-slate-700 font-mono">{{ variant.p_value || 0.0 }}</div>-->
          <!--            </dd>-->
          <!--          </div>-->
        </dl>
      </li>

      <li v-if="can.create_variant" class="overflow-hidden rounded-xl border border-slate-200">
        <div class="flex h-full w-full items-center justify-center gap-x-4 border-b border-slate-900/5 bg-white/40 p-6">
          <Link v-if="can.create_variant" :href="route('experiments.variants.create', [experiment.key])" class="group relative block w-full rounded-lg border-2 border-dashed border-slate-300 p-12 text-center no-underline hover:border-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">
            <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-xl bg-white text-slate-400 ring-1 ring-black/5 group-hover:text-rose-500 group-hover:shadow-xl group-hover:shadow-rose-500/10">
              <AdjustmentsVerticalIcon class="size-6" />
            </div>

            <span class="mt-2 block font-semibold text-slate-800"> Create Variant </span>
          </Link>
          <div v-else class="relative block w-full rounded-lg border-2 border-dashed border-slate-300 p-12 text-center no-underline">
            <AdjustmentsVerticalIcon class="mx-auto h-12 w-12 text-slate-400" />

            <span class="mt-2 block font-semibold text-slate-800"> Variants will appear here </span>
          </div>
        </div>
      </li>
    </ul>

    <!-- Variant Delete Modal -->
    <JetConfirmationModal :show="variantToDelete !== null" @close="variantToDelete = null">
      <template #title> Delete Variant </template>

      <template #content>
        <p>Are you sure you want to delete this variant? Collected data for this variant will be lost.</p>

        <p>Keep in mind that this action cannot be undone.</p>
      </template>

      <template #footer>
        <JetSecondaryButton @click="variantToDelete = null"> Never mind </JetSecondaryButton>

        <JetDangerButton class="ml-2" @click="variantDeleteRequest"> Delete Variant </JetDangerButton>
      </template>
    </JetConfirmationModal>

    <!-- Variant Winner Modal -->
    <JetConfirmationModal v-if="variantToWin !== null" :show="variantToWin != null" @close="variantToWin = null">
      <template #title> Set Variant Winner </template>

      <template #content>
        <div class="rounded-md bg-blue-50 px-4 text-slate-900">
          <div class="ml-4 mt-0.5 flex flex-grow">
            <p class="text-slate-700">Once a winner has been selected, the experiment will be stopped. Existing participants will continue to receive their assigned variant. New participants will be enrolled into the winning variant.<slot /></p>
          </div>
        </div>

        <p>
          Are you sure you want to set this experiment's winner to the
          <code>{{ variantToWin.key }}</code> variant?
        </p>
      </template>

      <template #footer>
        <JetSecondaryButton @click="variantToWin = null"> Never mind </JetSecondaryButton>

        <JetButton class="ml-2" @click="variantWinnerRequest"> Set Winner </JetButton>
      </template>
    </JetConfirmationModal>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import JetButton from '@/Jetstream/Button.vue'
import { AdjustmentsVerticalIcon, ArrowTrendingDownIcon, ArrowTrendingUpIcon, CheckBadgeIcon, EllipsisHorizontalIcon, QuestionMarkCircleIcon, CheckIcon, XMarkIcon } from '@heroicons/vue/20/solid'

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

export default {
  components: {
    QuestionMarkCircleIcon,
    AdjustmentsVerticalIcon,
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon,
    CheckBadgeIcon,
    CheckIcon,
    XMarkIcon,
    Link,
    JetConfirmationModal,
    JetSecondaryButton,
    JetDangerButton,
    JetButton,
    EllipsisHorizontalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  props: {
    experiment: Object,
    can: Object,
  },
  data() {
    return {
      variantToDelete: null,
      variantToWin: null,
      observedPowerTitle: 'Statistical Power is a measure of our ability to detect a difference when it truly exists. Typically, a statistical power of 80% or higher is considered the threshold for statistical validity.',
    }
  },
  computed: {
    gridCols() {
      if (this.experiment.variants.length > 1 && this.experiment.variants.length <= 3) {
        return `grid-cols-${this.experiment.variants.length}`
      } else if (this.experiment.variants.length >= 4) {
        return 'grid-cols-2'
      }

      return 'grid-cols-1'
    },
    variants() {
      const variants = this.experiment.variants

      return variants.sort((a, b) => (a.id > b.id ? 1 : -1))
    },
  },
  methods: {
    deleteVariant(variant) {
      this.variantToDelete = variant
    },
    variantDeleteRequest() {
      this.$inertia.delete(this.route('experiments.variants.destroy', [this.experiment.key, this.variantToDelete.key]), {
        preserveState: true,
        preserveScroll: true,
      })

      this.variantToDelete = null
    },
    setWinner(variant) {
      this.variantToWin = variant
    },
    variantWinnerRequest() {
      this.$inertia.post(this.route('experiments.variants.winner', [this.experiment.key, this.variantToWin.key]), {
        preserveState: true,
        preserveScroll: true,
      })

      this.variantToWin = null
    },
  },
}
</script>
