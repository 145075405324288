<template>
  <BaseTrendMetric :chart-data="data" :loading="loading" :title="title" :help-text="helpText" />
</template>

<script>
import Axios from 'axios'
import BaseTrendMetric from './Base/TrendMetric.vue'
import Minimum from '@/Util/minimum.js'

export default {
  components: {
    BaseTrendMetric,
  },
  props: {
    endpoint: String,
    title: String,
    helpText: String,
  },
  data: () => ({
    loading: true,
    value: '',
    data: [],
    format: '(0[.]00a)',
  }),
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      Minimum(Axios.get(this.endpoint), 750).then(({ data: { multiple, value, data } }) => {
        this.value = value
        this.labels = Object.keys(data)
        this.data = this.formatData(data, multiple)

        this.loading = false
      })
    },

    formatData(data, multiple) {
      if (multiple) {
        return data
      }

      return [data]
    },
  },
}
</script>
