<template>
  <div>
    <Head title="Create Variant" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <JetFormSection @submitted="createVariant">
        <template #title> Create Variant </template>

        <template #description>
          <p>Variants are the different versions of your experiment. Each variant is a unique experience that you want to test. For example, if you are testing a new landing page, you might create a variant that has a different headline or call-to-action.</p>
          <p>Every experiment needs two variants before it can be started.</p>
          <p>
            Need more help? Check out the
            <a href="https://docs.checkmango.com/experiments/variants.html" target="_blank">documentation</a>.
          </p>
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <JetLabel for="key" value="Key" />

            <JetInput id="key" v-model="form.key" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

            <JetInputError :message="form.errors.key" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <JetLabel for="description" value="Description" />

            <Editor id="description" @content-changed="handleEditorContentChange" />

            <JetInputError :message="form.errors.description" class="mt-2" />
          </div>

          <div v-if="experiment.algorithm_uses_weights" class="col-span-6 sm:col-span-4">
            <JetLabel for="traffic" value="Traffic" />

            <JetInput id="traffic" v-model="form.traffic" type="number" min="1" :max="totalRemainingTraffic" class="mt-1 block w-full" />

            <JetLabel class="mt-2" :value="remainingTrafficMessage" />

            <JetInputError :message="form.errors.traffic" class="mt-2" />
          </div>

          <div v-if="!hasControl" class="col-span-5 sm:col-span-4">
            <ul class="divide-y divide-slate-200">
              <li class="flex flex-wrap items-center justify-between pb-4">
                <div class="flex flex-col">
                  <JetLabel for="control" value="Control" />
                  <p class="text-slate-500">The "control" variant should be your current implementation.</p>
                  <JetInputError :message="form.errors.control" />
                </div>
                <div class="flex">
                  <Toggle off-text="No" on-text="Yes" :value="form.control" class="mt-1" @toggled="toggleControl" />
                </div>
              </li>
            </ul>
          </div>
        </template>

        <template #actions>
          <JetActionMessage :on="form.recentlySuccessful" class="mr-3"> Created. </JetActionMessage>

          <JetSecondaryButton :disabled="form.processing" class="mr-2" @click="$inertia.visit(route('experiments.show', [experiment.key]))"> Cancel </JetSecondaryButton>

          <JetButton :class="{ 'opacity-25': form.processing }" class="ml-2" :disabled="form.processing"> Create </JetButton>
        </template>
      </JetFormSection>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import AppLayout from '@/Layouts/AppLayout.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import JetButton from '@/Jetstream/Button.vue'
import Toggle from '@/Components/Toggle.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Head,
    Editor: defineAsyncComponent(() => import('@/Components/Editor.vue')),
    JetFormSection,
    JetLabel,
    JetInput,
    JetInputError,
    JetActionMessage,
    JetButton,
    Toggle,
    JetSecondaryButton,
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
    },
    totalTraffic: {
      type: Number,
      required: true,
    },
    experiment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        key: '',
        control: false,
        description: '',
        traffic: null,
        active: false,
      }),
    }
  },
  computed: {
    totalRemainingTraffic() {
      return 100 - this.totalTraffic
    },
    newRemainingTraffic() {
      return this.totalRemainingTraffic - this.form.traffic
    },
    remainingTrafficMessage() {
      if (this.totalRemainingTraffic === 0) {
        return 'This experiment\'s variants have already been allocated 100% of traffic.'
      }

      return `Remaining traffic allocation: ${this.newRemainingTraffic}%`
    },
    hasControl() {
      return !!this.experiment.variants.find((variant) => variant.control)
    },
  },
  methods: {
    createVariant() {
      this.form.post(this.route('experiments.variants.store', this.experiment.key), {
        preserveScroll: true,
      })
    },
    handleEditorContentChange(content) {
      this.form.description = content
    },
    toggleControl() {
      this.form.control = !this.form.control
    },
  },
}
</script>
