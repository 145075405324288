<template>
  <div>
    <Head :title="`${team.name} | Teams`" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <UpdateTeamNameForm :team="team" :permissions="permissions" :industries="industries" />

      <!--      <JetSectionBorder />-->

      <!--      <UpdateTeamTimezoneForm :team="team" :permissions="permissions" :timezones="timezones" />-->

      <!--      <JetSectionBorder />-->

      <!--      <UpdateTeamCurrencyForm :team="team" :permissions="permissions" />-->

      <TeamMemberManager class="mt-10 sm:mt-0" :team="team" :available-roles="availableRoles" :user-permissions="permissions" />

      <template v-if="permissions.canDeleteTeam && !team.personal_team">
        <JetSectionBorder />

        <DeleteTeamForm class="mt-10 sm:mt-0" :team="team" />
      </template>
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import TeamMemberManager from './TeamMemberManager.vue'
import DeleteTeamForm from './DeleteTeamForm.vue'
import UpdateTeamNameForm from './UpdateTeamNameForm.vue'
// import UpdateTeamCurrencyForm from './UpdateTeamCurrencyForm.vue'
// import UpdateTeamTimezoneForm from './UpdateTeamTimezoneForm.vue'
import JetSectionBorder from '@/Jetstream/SectionBorder.vue'

export default {
  components: {
    Head,
    DeleteTeamForm,
    TeamMemberManager,
    UpdateTeamNameForm,
    // UpdateTeamCurrencyForm,
    // UpdateTeamTimezoneForm,
    JetSectionBorder,
  },
  layout: [AppLayout],
  props: {
    team: Object,
    availableRoles: Array,
    permissions: Object,
    timezones: Array,
    industries: Array,
  },
}
</script>
