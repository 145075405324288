<template>
  <Banner v-if="hasErrors" type="error">
    <div class="font-medium text-red-600">Whoops! Something went wrong.</div>

    <ul class="mt-2 list-inside list-disc text-red-600">
      <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
    </ul>
  </Banner>
</template>

<script>
import Banner from '@/Components/Banner.vue'

export default {
  components: { Banner },
  computed: {
    errors() {
      return this.$page.props.errors
    },

    hasErrors() {
      return Object.keys(this.errors).length > 0
    },
  },
}
</script>
