<template>
  <AppLayout title="Experiments" :links="links" :user="user">
    <template #headerActions>
      <LinkButton v-if="can.create_experiments" :href="route('experiments.create')"> Create Experiment </LinkButton>
    </template>

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="mb-8 overflow-hidden bg-white shadow-sm ring-1 ring-slate-900/5 md:rounded-xl">
        <ExperimentsTable title="Experiments" subtitle="What will you test today?" :experiments="experiments" :no-filter-results="noFilterResults">
          <template #filter>
            <div class="space-x-2">
              <ButtonGroup>
                <LinkButton :class="{ 'bg-slate-200': statusFilter === 'draft' }" type="secondary" :href="route('experiments', { 'filter[status]': 'draft' })" :only="['statusFilter', 'experiments', 'noFilterResults']">
                  Draft <template v-if="counts.draftCount > 0">({{ counts.draftCount }})</template>
                </LinkButton>
                <LinkButton :class="{ 'bg-slate-200': statusFilter === 'stopped' }" type="secondary" :href="route('experiments', { 'filter[status]': 'stopped' })" :only="['statusFilter', 'experiments', 'noFilterResults']">
                  Stopped <template v-if="counts.stoppedCount > 0">({{ counts.stoppedCount }})</template>
                </LinkButton>
                <LinkButton :class="{ 'bg-slate-200': statusFilter === 'running' }" type="secondary" :href="route('experiments', { 'filter[status]': 'running' })" :only="['statusFilter', 'experiments', 'noFilterResults']">
                  Running <template v-if="counts.runningCount > 0">({{ counts.runningCount }})</template>
                </LinkButton>
                <LinkButton :class="{ 'bg-slate-200': statusFilter === 'all' }" type="secondary" :href="route('experiments', { 'filter[status]': 'all' })" :only="['statusFilter', 'experiments', 'noFilterResults']">
                  All <template v-if="counts.allCount > 0">({{ counts.allCount }})</template>
                </LinkButton>
              </ButtonGroup>
            </div>
          </template>
        </ExperimentsTable>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ExperimentsTable from './Partials/Table.vue'
import LinkButton from '@/Components/LinkButton.vue'
import ButtonGroup from '@/Components/ButtonGroup.vue'

export default {
  components: {
    AppLayout,
    ButtonGroup,
    ExperimentsTable,
    LinkButton,
  },
  props: {
    can: Object,
    links: {
      type: Array,
      default: () => [],
    },
    experiments: {
      type: Object,
    },
    statusFilter: {
      type: String,
    },
    counts: Object,
    noFilterResults: Boolean,
    user: Object,
  },
}
</script>
