<template>
  <GuestLayout title="Terms of Service">
    <section class="relative overflow-hidden py-12 text-slate-700 md:py-24">
      <Container>
        <header class="flex flex-col items-center text-center">
          <h1 class="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">Terms of Service</h1>

          <p class="marketing-heading mt-6">Please read these terms and conditions carefully before using our service.</p>

          <p class="text-sm">Version — {{ versionTimestamp }}</p>

          <Button as="a" href="mailto:support@checkmango.com" class="mt-6">Contact us</Button>
        </header>
      </Container>
    </section>

    <section class="relative pb-12 md:pb-24">
      <div class="container">
        <article class="prose-blockquote:first-letter: prose prose-lg mx-auto prose-headings:font-display prose-a:border-b-2 prose-a:border-current prose-a:text-pink-500 prose-a:no-underline prose-blockquote:rounded-r-md prose-blockquote:border-l-blue-500 prose-blockquote:bg-slate-50 prose-blockquote:p-6 prose-blockquote:font-normal prose-blockquote:not-italic" v-html="policy" />
      </div>
    </section>

    <RegisterInterest />
  </GuestLayout>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const GuestLayout = defineAsyncComponent(() => import('@/Layouts/GuestLayout.vue'))
const RegisterInterest = defineAsyncComponent(() => import('@/Components/RegisterInterest.vue'))
import Button from '@/Jetstream/Button.vue'
import Container from '@/Components/Container.vue'

export default {
  components: {
    Container,
    Button,
    GuestLayout,
    RegisterInterest,
  },
  props: {
    versionTimestamp: {
      type: String,
    },
    policy: {
      type: String,
    },
  },
}
</script>
