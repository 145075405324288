<template>
  <div class="inline-block">
    <div v-clipboard:copy="clipboardContents" v-clipboard:success="onCopy" :class="{ 'text-slate-400': hasCopied, 'text-slate-500': !hasCopied }" class="flex cursor-pointer items-center space-x-3 rounded-md bg-slate-100 px-3 py-2 font-mono text-sm lg:px-4">
      <div class="max-w-sm truncate break-words">
        <slot />
      </div>

      <div v-tippy="{ content: 'Click to copy' }">
        <component :is="clipboardIcon" class="size-4 text-current" />
      </div>
    </div>
  </div>
</template>

<script>
import { copyText } from 'vue3-clipboard'
import { ClipboardDocumentListIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline'
import { markRaw } from 'vue'

export default {
  components: {
    ClipboardDocumentListIcon,
    ClipboardDocumentCheckIcon,
  },
  data() {
    return {
      hasCopied: false,
    }
  },
  computed: {
    clipboardContents() {
      return this.$slots.default ? this.$slots.default()[0].children : null
    },
    clipboardIcon() {
      if (!this.hasCopied) {
        return markRaw(ClipboardDocumentListIcon)
      }

      return markRaw(ClipboardDocumentCheckIcon)
    },
  },
  methods: {
    doCopy() {
      copyText(this.$slots.default().text)
    },
    onCopy() {
      this.hasCopied = true

      setTimeout(() => (this.hasCopied = false), 300)
    },
  },
}
</script>
