<template>
  <GuestLayout title="Pricing">
    <section class="relative overflow-hidden py-12 text-slate-700 md:py-24">
      <div class="container relative">
        <header class="flex flex-col items-center text-center">
          <h2 class="marketing-headline">Pricing</h2>

          <p class="mt-2">Checkmango is priced by API requests per month. Choose yearly billing and <strong class="font-semibold">save 10%</strong>.</p>
        </header>
      </div>
    </section>

    <section class="relative pb-12 md:pb-24">
      <header class="flex flex-col items-center justify-center text-center">
        <div class="flex justify-center">
          <RadioGroup v-model="frequency" class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-slate-200">
            <RadioGroupLabel class="sr-only">Payment frequency</RadioGroupLabel>
            <RadioGroupOption v-for="option in frequencies" :key="option.value" v-slot="{ checked }" as="template" :value="option">
              <div :class="[checked ? 'bg-emerald-500 text-white' : 'text-slate-500', 'cursor-pointer rounded-full px-2.5 py-1']">
                <span>{{ option.label }}</span>
              </div>
            </RadioGroupOption>
          </RadioGroup>
        </div>
      </header>

      <div class="container">
        <div class="isolate mx-auto mt-10 flex max-w-md flex-col md:max-w-2xl lg:flex-row lg:max-w-4xl xl:mx-0 xl:max-w-none gap-4">
          <Plan v-for="plan in plans" :key="plan.id" :frequency="frequency" :tier="plan" :marketing="true" />

          <div class="p-2 pl-0">
            <div class="rounded-md bg-white p-6 shadow-xl ring-1 ring-white/10 sm:mx-0">
              <h3 id="custom" class="text-xl font-semibold leading-8 text-slate-900">Custom</h3>
              <p class="mt-4 leading-6 text-slate-600">Need something bespoke? Let's work together.</p>
              <p class="mt-4 flex items-baseline gap-x-1">
                <span class="text-2xl font-bold tracking-tight text-slate-900">Custom</span>
              </p>
              <a href="mailto:support@checkmango.com?subject=Custom Pricing" aria-describedby="custom" class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-emerald-500 ring-1 ring-inset ring-emerald-200 hover:ring-emerald-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </GuestLayout>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const GuestLayout = defineAsyncComponent(() => import('@/Layouts/GuestLayout.vue'))
import Plan from '@/Pages/Marketing/Partials/Plan.vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'yearly', label: 'Yearly', priceSuffix: '/year' },
]

export default {
  components: {
    RadioGroupOption,
    RadioGroupLabel,
    RadioGroup,
    Plan,
    GuestLayout,
  },
  props: {
    plans: {
      type: Array,
    },
  },
  data() {
    return {
      frequencies: frequencies,
      frequency: frequencies[0],
    }
  },
}
</script>
