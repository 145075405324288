<script setup>
import Container from '@/Components/Container.vue'

const faqs = [
  [
    {
      question: 'What does "full stack" mean?',
      answer: 'Full stack is a development term used to group together two areas of code - front end (everything you interact with) and back end (all the stuff behind the scenes).\n' + '\n' + 'Checkmango is a full stack A/B testing platform that allows you to implement changes anywhere within your application.',
    },
    {
      question: 'What is A/B testing?',
      answer: 'A/B testing is a method of comparing two or more versions of a page or individual component against each other to determine which one performs better. A/B testing is essentially an experiment where two or more variants of a page are shown to users at random, and statistical analysis is used to determine which variation performs better for a given conversion goal.',
    },
    {
      question: 'Can I use Checkmango on multiple sites?',
      answer: 'Yes, you can use Checkmango on as many sites as you’d like. You are only limited by the number of <code>/api/ingest</code> requests per your subscription.',
    },
  ],
  [
    {
      question: 'What is statistical significance?',
      answer: 'Statistical significance is a mathematical tool used to determine whether the results of an experiment are valid or not. It is used to determine whether the results of a study can be applied to the general population or whether they were likely the result of chance.',
    },
    {
      question: 'What is "ingest"?',
      answer: 'Checkmango tracks your participants actions by ingesting events. An event is a record of something that happened on your website or application. For example, when you enroll a participant into an experiment or when a conversion happens, you would make an "ingest" request to Checkmango.',
    },
    {
      question: 'Can I try Checkmango for free?',
      answer: 'All Checkmango plans come with a <strong>14-day free trial</strong>. You may cancel at any time.',
    },
  ],
  [
    {
      question: 'Does Checkmango support my language?',
      answer: 'We\'ve recently released the <a href="https://github.com/checkmango/php-sdk" class="hover:underline text-emerald-500 font-semibold">PHP SDK</a> and <a href="https://github.com/checkmango/checkmango.js" class="hover:underline text-emerald-500 font-semibold">JavaScript SDK</a>. Of course, you can use Checkmango with any language or framework via the <a href="https://developers.checkmango.com" class="hover:underline text-emerald-500 font-semibold">API</a>.',
    },
    {
      question: 'Can I bring my team?',
      answer: 'Yes, you can invite your team members to your Checkmango team. You can also set permissions for each team member to control what they can access.',
    },
    {
      question: 'I forgot my password!',
      answer: 'No problem, you can <a href="/forgot-password" class="hover:underline text-emerald-500 font-semibold">reset your password</Link> at any time.',
    },
    {
      question: 'Can I cancel my subscription?',
      answer: 'Yes, you may cancel your subscription at any time. This can be done via your team\'s billing dashboard.',
    },
  ],
]
</script>

<template>
  <section id="faq" aria-labelledby="faq-title" class="relative overflow-hidden bg-slate-50 py-20 sm:py-32">
    <Container class="relative">
      <div class="mx-auto max-w-3xl lg:mx-0">
        <h2 id="faq-title" class="marketing-headline">Frequently asked questions</h2>
        <p class="prose-lg mt-4">Here are some of our most frequently asked questions. If you have any other questions, we'd be happy to help.</p>
      </div>
      <ul role="list" class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
        <li v-for="(column, columnIndex) in faqs" :key="columnIndex">
          <ul role="list" class="flex flex-col gap-y-8">
            <li v-for="(faq, faqIndex) in column" :key="faqIndex">
              <h3 class="marketing-heading">
                {{ faq.question }}
              </h3>
              <p class="prose-lg mt-4" v-html="faq.answer" />
            </li>
          </ul>
        </li>
      </ul>
    </Container>
  </section>
</template>
