<template>
  <nav v-if="links && links.length" class="flex" aria-label="Breadcrumb">
    <ol role="list" class="mx-auto flex w-full space-x-4">
      <li class="flex">
        <div class="flex items-center">
          <Link :href="route('dashboard')" class="text-slate-500 hover:text-slate-500">
            <HomeIcon class="size-5 flex-shrink-0" />

            <span class="sr-only">Home</span>
          </Link>
        </div>
      </li>

      <li v-for="(link, index) in links" :key="link.href" class="flex">
        <div class="flex items-center text-sm">
          <svg class="h-full w-6 flex-shrink-0 text-slate-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>

          <Link
            v-if="link.href"
            :href="link.href"
            class="ml-4 tracking-tight text-slate-500 hover:text-slate-700"
            :class="{
              'text-slate-500 hover:text-slate-400': index + 1 === links.length || links.length === 1,
            }"
          >
            {{ link.name }}
          </Link>

          <span v-else class="ml-4 tracking-tight text-slate-500">
            {{ link.name }}
          </span>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import { HomeIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    HomeIcon,
    Link,
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
