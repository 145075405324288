<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div class="overflow-hidden shadow-sm ring-1 ring-slate-900/5 md:rounded-xl">
          <div class="flex items-start justify-between p-6">
            <div>
              <h2 class="font-display text-lg font-semibold leading-6 text-slate-900">{{ title }}</h2>
              <p v-if="subtitle" class="pt-1 text-slate-600">
                {{ subtitle }}
              </p>
            </div>

            <div v-if="data.length > 0">
              <slot name="filter" />
            </div>
          </div>

          <template v-if="data.length > 0">
            <table class="min-w-full divide-y divide-slate-200 border-0 border-t border-slate-200">
              <thead class="bg-slate-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-sm font-medium tracking-tight text-slate-500">
                    <div class="flex items-center space-x-2">
                      <span>Key</span>

                      <TableSort column="key" :active-sort="sort" @sort="sortTable" />
                    </div>
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-sm font-medium tracking-tight text-slate-500">
                    <div class="flex items-center space-x-2">
                      <span>Experiments</span>

                      <TableSort column="experiment_count" :active-sort="sort" @sort="sortTable" />
                    </div>
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-sm font-medium tracking-tight text-slate-500">
                    <div class="flex items-center space-x-2">
                      <span>Conversions</span>

                      <TableSort column="conversions_count" :active-sort="sort" @sort="sortTable" />
                    </div>
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-sm font-medium tracking-tight text-slate-500">
                    <div class="flex items-center space-x-2">
                      <span>Impressions</span>

                      <TableSort column="impressions_count" :active-sort="sort" @sort="sortTable" />
                    </div>
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-sm font-medium tracking-tight text-slate-500">
                    <div class="flex items-center space-x-2">
                      <span>Attributes</span>

                      <TableSort column="attributes_count" :active-sort="sort" @sort="sortTable" />
                    </div>
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-sm font-medium tracking-tight text-slate-500">Created At</th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(participant, idx) in data" :key="participant.id" class="border-b border-slate-200" :class="idx % 2 === 0 ? 'bg-white' : 'bg-slate-100 bg-opacity-40'">
                  <td class="whitespace-nowrap px-6 py-3 text-sm">
                    <Clipboard>{{ participant.key }}</Clipboard>
                  </td>
                  <td class="whitespace-nowrap px-6 py-3 text-sm">
                    {{ numberFormat(participant.experiment_count) }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-3 text-sm">
                    {{ numberFormat(participant.conversions_count) }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-3 text-sm">
                    {{ numberFormat(participant.impressions_count) }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-3 text-sm">
                    {{ numberFormat(participant.attributes_count) }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-3 text-sm">
                    {{ dateTimeFormat(participant.created_at) }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-3 text-right text-sm font-medium">
                    <Link v-if="participant.can.view_participant" :href="route('participants.show', [participant.key])"> View </Link>
                  </td>
                </tr>
              </tbody>
            </table>

            <Pagination :data="participants" />
          </template>

          <div v-else class="px-4 py-5 sm:p-6">
            <div class="group relative block w-full rounded-lg border-2 border-dashed border-slate-300 p-12 text-center">
              <div class="mx-auto flex h-12 w-12 animate-pulse items-center justify-center rounded-xl bg-white text-emerald-500 shadow-xl shadow-emerald-500/10 ring-1 ring-black/5">
                <UsersIcon class="size-6" />
              </div>

              <span class="mt-2 block font-semibold text-slate-800"> Waiting for participants... </span>

              <span class="block font-normal text-slate-800"> Participants will show here when created via the API. </span>

              <div class="flex justify-center space-x-2">
                <a href="https://docs.checkmango.com/events/" target="_blank" class="mt-2 block font-medium text-slate-600 underline"> Documentation </a>
                <a href="https://developers.checkmango.com/#endpoint-events" target="_blank" class="mt-2 block font-medium text-slate-600 underline"> API Documentation </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import Clipboard from '@/Components/Clipboard.vue'
import { UsersIcon } from '@heroicons/vue/24/outline'
import Pagination from '@/Components/Pagination.vue'
import TableSort from '@/Components/TableSort.vue'

export default {
  components: {
    TableSort,
    Clipboard,
    Link,
    Pagination,
    UsersIcon,
  },
  props: {
    participants: Object,
    title: String,
    subtitle: String,
    sort: String,
  },
  computed: {
    data() {
      return this.participants.data
    },
  },
  methods: {
    sortTable(sort) {
      this.activeSort = sort

      this.$inertia.visit(
        this.route('participants', {
          sort: sort,
        }),
        {
          preserveState: true,
        },
      )
    },
  },
}
</script>
