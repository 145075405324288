<template>
  <div>
    <Head title="Create Webhook" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <FormSection @submitted="createWebhook">
        <template #title>Webhook Details</template>

        <template #description>
          <p>Get notified via webhook when something happens in your team.</p>
          <p>
            Need more help? Check out the
            <a href="https://docs.checkmango.com/guide/webhooks" target="_blank">documentation</a>.
          </p>
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="url" value="URL" />

            <Input id="url" v-model="form.url" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

            <InputError :message="form.errors.url" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="secret" value="Secret" />

            <Input id="secret" v-model="form.secret" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

            <InputError :message="form.errors.secret" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Toggle off-text="Disabled" on-text="Enabled" :value="form.enabled" class="mt-1" @toggled="form.enabled = !form.enabled" />

            <InputError :message="form.errors.enabled" class="mt-2" />
          </div>
        </template>

        <template #actions>
          <SecondaryButton class="mr-2" @click="$inertia.visit(route('webhooks'))">Cancel</SecondaryButton>

          <Button type="submit"> Create </Button>
        </template>
      </FormSection>
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import FormSection from '@/Jetstream/FormSection.vue'
import Button from '@/Jetstream/Button.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import InputError from '@/Jetstream/InputError.vue'
import Input from '@/Jetstream/Input.vue'
import Label from '@/Jetstream/Label.vue'
import Toggle from '@/Components/Toggle.vue'

export default {
  components: {
    Toggle,
    Label,
    Input,
    InputError,
    Head,
    FormSection,
    Button,
    SecondaryButton,
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        url: '',
        secret: '',
        enabled: true,
      }),
    }
  },
  methods: {
    createWebhook() {
      this.form.post(this.route('webhooks.store'), {
        preserveScroll: true,
      })
    },
  },
}
</script>
