<template>
  <div>
    <Head :title="`Edit ${variant.key} (${experiment.key}) | Variants`" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <FormSection @submitted="updateVariant">
        <template #title> Update Variant </template>

        <template #description>
          <p>Variants are the different versions of your experiment. Each variant is a unique experience that you want to test. For example, if you are testing a new landing page, you might create a variant that has a different headline or call-to-action.</p>
          <p>Every experiment needs two variants before it can be started.</p>
          <p>
            Need more help? Check out the
            <a href="https://docs.checkmango.com/experiments/variants.html" target="_blank">documentation</a>.
          </p>
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="key" value="Key" />

            <Input id="key" v-model="form.key" type="text" class="mt-1 block w-full" autofocus :disabled="!experiment.is_draft" :class="{ 'bg-slate-100': !experiment.is_draft }" autocomplete="off" />

            <InputError :message="form.errors.key" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="description" value="Description" />

            <Editor id="description" :initial-content="form.description" @content-changed="handleEditorContentChange" />

            <InputError :message="form.errors.description" class="mt-2" />
          </div>

          <div v-if="experiment.algorithm_uses_weights" class="col-span-6 sm:col-span-4">
            <Label for="traffic" value="Traffic" />

            <Input id="traffic" v-model="form.traffic" type="number" min="1" :max="totalRemainingTraffic" class="mt-1 block w-full" :disabled="!experiment.is_draft" :class="{ 'bg-slate-100': !experiment.is_draft }" />

            <Label class="mt-2" :value="remainingTrafficMessage" />

            <InputError :message="form.errors.traffic" class="mt-2" />
          </div>

          <div class="col-span-5 sm:col-span-4">
            <ul class="divide-y divide-slate-200">
              <li class="flex flex-wrap items-center justify-between pb-4">
                <div class="flex flex-col">
                  <Label for="control" value="Control" />
                  <p class="text-slate-500">The "control" variant should be your current implementation.</p>
                  <InputError :message="form.errors.control" />
                </div>
                <div class="flex">
                  <Toggle off-text="No" on-text="Yes" :value="form.control" class="mt-1" :disabled="!experiment.is_draft" @toggled="toggleControl" />
                </div>
              </li>
            </ul>
          </div>
        </template>

        <template #actions>
          <ActionMessage :on="form.recentlySuccessful" class="mr-3"> Updated. </ActionMessage>

          <SecondaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing" @click="$inertia.visit(route('experiments.show', experiment.key))"> Cancel </SecondaryButton>

          <Button :class="{ 'opacity-25': form.processing }" class="ml-3" :disabled="form.processing"> Update </Button>
        </template>
      </FormSection>
    </div>

    <ConfirmationModal :show="showTrafficWarning" @close="showTrafficWarning = false">
      <template #title> Allocate 100% of Traffic? </template>

      <template #content>
        <p>Are you sure that you want to allocate 100% of the experiments traffic to this variant?</p>

        <p>You will not be able to create any new variants whilst this variant holds 100%.</p>
      </template>

      <template #footer>
        <SecondaryButton @click="showTrafficWarning = false"> Never mind </SecondaryButton>

        <DangerButton class="ml-2" @click="updateVariant(true)"> Yes, I Understand </DangerButton>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import AppLayout from '@/Layouts/AppLayout.vue'
import FormSection from '@/Jetstream/FormSection.vue'
import Label from '@/Jetstream/Label.vue'
import Input from '@/Jetstream/Input.vue'
import InputError from '@/Jetstream/InputError.vue'
import ActionMessage from '@/Jetstream/ActionMessage.vue'
import Button from '@/Jetstream/Button.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import ConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import Toggle from '@/Components/Toggle.vue'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Head,
    FormSection,
    Label,
    Input,
    InputError,
    ActionMessage,
    Button,
    SecondaryButton,
    Editor: defineAsyncComponent(() => import('@/Components/Editor.vue')),
    ConfirmationModal,
    DangerButton,
    Toggle,
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
    },
    totalTraffic: {
      type: Number,
      required: true,
    },
    experiment: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showTrafficWarning: false,
      form: this.$inertia.form({
        key: this.variant.key,
        control: this.variant.control,
        description: this.variant.description,
        traffic: this.variant.traffic,
        active: this.variant.active,
      }),
    }
  },
  computed: {
    totalRemainingTraffic() {
      return 100 - this.totalTraffic
    },
    newRemainingTraffic() {
      return this.totalRemainingTraffic - this.form.traffic
    },
    remainingTrafficMessage() {
      if (this.totalRemainingTraffic === 0) {
        return 'This experiment\'s variants have already been allocated 100% of traffic.'
      }

      if (this.newRemainingTraffic > 0) {
        return `Remaining traffic allocation: ${this.newRemainingTraffic}%`
      }

      return `You must lower the allocated traffic to this variant to < ${this.totalRemainingTraffic}%.`
    },
  },
  methods: {
    updateVariant(force = false) {
      if (this.form.traffic >= 100 && !force) {
        this.showTrafficWarning = true

        return
      }

      this.form.put(this.route('experiments.variants.update', [this.experiment.key, this.variant.key]))
    },
    handleEditorContentChange(content) {
      this.form.description = content
    },
    toggleControl() {
      this.form.control = !this.form.control
    },
  },
}
</script>
