<template>
  <JetConfirmationModal :show="experiment !== null" @close="$emit('close')">
    <template #title> Stop Experiment </template>

    <template #content>
      <p>Are you sure you want to stop this experiment? Your application must be prepared to stop selecting a variant.</p>

      <p class="font-semibold">Stopped experiments cannot be restarted.</p>
    </template>

    <template #footer>
      <JetSecondaryButton @click="$emit('close')"> Never mind </JetSecondaryButton>

      <JetDangerButton class="ml-2" @click="$emit('confirm')"> Stop Experiment </JetDangerButton>
    </template>
  </JetConfirmationModal>
</template>

<script>
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'

export default {
  components: {
    JetConfirmationModal,
    JetSecondaryButton,
    JetDangerButton,
  },
  props: {
    experiment: {
      type: Object,
      required: false,
    },
  },
  emits: ['close', 'confirm'],
}
</script>
