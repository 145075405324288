<template>
  <button :type="type" class="button" :class="buttonClasses">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    outline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      if (this.outline) {
        return 'button--danger button--outline'
      }

      return 'button--danger'
    },
  },
}
</script>
