<template>
  <AuthenticationCard>
    <Head title="Two Factor Authentication" />

    <template #logo>
      <div class="flex flex-row items-center space-x-2">
        <div class="mt-6 font-display text-xl font-bold tracking-tight text-slate-700 md:text-3xl">Two factor time</div>
      </div>
    </template>

    <div class="mb-4 text-slate-600">
      <template v-if="!recovery"> Please confirm access to your account by entering the authentication code provided by your authenticator application. </template>

      <template v-else> Please confirm access to your account by entering one of your emergency recovery codes. </template>
    </div>

    <Banner v-if="sessionExpired" type="danger">Your Checkmango session has expired. Please try again.</Banner>

    <ValidationErrors class="mb-4" />

    <form @submit.prevent="submit">
      <div v-if="!recovery">
        <Label for="code" value="Code" />
        <Input id="code" ref="code" v-model="form.code" type="text" inputmode="numeric" class="mt-1 block w-full" autofocus autocomplete="one-time-code" />
      </div>

      <div v-else>
        <Label for="recovery_code" value="Recovery Code" />
        <Input id="recovery_code" ref="recovery_code" v-model="form.recovery_code" type="text" class="mt-1 block w-full" autocomplete="one-time-code" />
      </div>

      <div class="mt-4 flex items-center justify-end">
        <button type="button" class="cursor-pointer text-slate-600 underline hover:text-slate-900" @click.prevent="toggleRecovery">
          <template v-if="!recovery"> Use a recovery code </template>

          <template v-else> Use an authentication code </template>
        </button>

        <Button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Log in </Button>
      </div>
    </form>
  </AuthenticationCard>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import AuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
import Button from '@/Jetstream/Button.vue'
import Input from '@/Jetstream/Input.vue'
import Label from '@/Jetstream/Label.vue'
import ValidationErrors from '@/Jetstream/ValidationErrors.vue'
import AuthenticationLayout from '@/Layouts/AuthenticationLayout.vue'
import Banner from '@/Jetstream/Banner.vue'

export default {
  components: {
    Banner,
    Head,
    AuthenticationCard,
    Button,
    Input,
    Label,
    ValidationErrors,
  },

  layout: [AuthenticationLayout],

  props: {
    sessionExpired: Boolean,
  },

  data() {
    return {
      recovery: false,
      form: this.$inertia.form({
        code: '',
        recovery_code: '',
      }),
    }
  },

  methods: {
    toggleRecovery() {
      this.recovery ^= true

      this.$nextTick(() => {
        if (this.recovery) {
          this.$refs.recovery_code.focus()
          this.form.code = ''
        } else {
          this.$refs.code.focus()
          this.form.recovery_code = ''
        }
      })
    },

    submit() {
      this.form.post(this.route('two-factor.login'))
    },
  },
}
</script>
