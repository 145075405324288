<template>
  <div class="flex flex-1 flex-col relative space-y-4">
    <span ref="output" class="font-mono text-xs absolute font-semibold" :style="{ left: `${thumbPosition}px` }">{{ parseFloat(modelValue).toPrecision(2) }}%</span>

    <div>
      <input ref="input" class="rounded-md focus:border-gray-500 focus:outline-none focus:ring-gray-500" type="range" :value="modelValue" v-bind="$attrs" :min="min" :max="max" @input="handleInput" />

      <div class="flex justify-between w-full font-mono text-xs mt-1">
        <div>{{ parseFloat(min).toPrecision(2) }}%</div>
        <div>{{ max }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: [Number, String],
    min: Number,
    max: Number,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      thumbPosition: 0,
    }
  },
  mounted() {
    setTimeout(() => this.recalculateTooltip(), 100)
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    handleInput(event) {
      this.$emit('update:modelValue', event.target.value)
      this.recalculateTooltip()
    },
    recalculateTooltip() {
      const slider = this.$refs.input
      const sliderWidth = slider?.getBoundingClientRect().width
      const outputWidth = this.$refs.output?.getBoundingClientRect().width

      this.thumbPosition = ((slider.value - slider.min) / (slider.max - slider.min)) * (sliderWidth - outputWidth)
    },
  },
}
</script>
