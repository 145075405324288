<script setup>
import { inject, computed } from 'vue'

const route = inject('route')

const props = defineProps({
  tier: Object,
  frequency: Object,
  team: {
    type: Object,
    default: () => ({}),
  },
})

const linkForPlan = (tier) => {
  const variantId = tier.price[props.frequency.value].variantId

  return route('teams.billing.checkout', { team: props.team, variantId })
}

const currentPlan = computed(() => props.team.subscription?.plan)

const buttonText = computed(() => {
  if (props.team.subscription.subscribed) {
    return 'Switch Plan'
  }

  return 'Subscribe'
})

const isCurrentPlan = (plan) => {
  return plan.id === props.currentPlan.id
}
</script>

<template>
  <div
    class="rounded-lg p-2"
    :class="{
      'border-2': !(currentPlan && isCurrentPlan(tier)),
      '-mx-2 bg-gradient-to-tr from-rose-500/50 to-yellow-500/50': currentPlan && isCurrentPlan(tier),
    }"
  >
    <div
      :class="{
        'ring-white/10': !(currentPlan && isCurrentPlan(tier)),
        'rounded-md shadow-rose-600/40 ring-white/5': currentPlan && isCurrentPlan(tier),
      }"
      class="rounded-md bg-white p-6 ring-1 sm:mx-0"
    >
      <h3 :id="tier.id" :class="[tier.mostPopular ? 'text-emerald-500' : 'text-slate-900', 'text-lg font-semibold leading-8']">{{ tier.name }}</h3>
      <p class="mt-4 text-sm leading-6 text-slate-600">{{ tier.description }}</p>
      <p class="mt-6 flex items-baseline gap-x-1">
        <span class="text-4xl font-bold tracking-tight text-slate-900">${{ tier.price[frequency.value].price }}</span>
        <span class="text-sm font-semibold leading-6 text-slate-600">{{ frequency.priceSuffix }}</span>
      </p>
      <template v-if="currentPlan" />
      <a :href="linkForPlan(tier)" :aria-describedby="tier.id" :class="[currentPlan && isCurrentPlan(tier) ? 'bg-emerald-500 text-white shadow-sm hover:bg-emerald-500' : 'text-emerald-500 ring-1 ring-inset ring-emerald-200 hover:ring-emerald-300', 'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500']">{{ buttonText }}</a>
    </div>
  </div>
</template>
