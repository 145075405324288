<template>
  <div>
    <Head title="Create Experiment" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <FormSection @submitted="createExperiment">
        <template #title> Experiment Details </template>

        <template #description>
          <p>An experiment tests a single, unique hypothesis by assigning random participants to each variant. Think of experiments as a ”test” or ”campaign” within your website or application.</p>
          <p>This could be the current implementation (known as the <em>"control"</em> variant) or a different version.</p>
          <p>For example, if you are testing whether a button color improves conversion you could call your experiment <code>ADD_TO_BASKET_BUTTON_COLOR</code></p>

          <p>
            Need more help? Check out the
            <a href="https://docs.checkmango.com/experiments/" target="_blank">documentation</a>.
          </p>
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="key" value="Key" />

            <Input id="key" v-model="form.key" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

            <InputError :message="form.errors.key" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="description" value="Description" />

            <Editor id="description" @content-changed="handleEditorContentChange" />

            <InputError :message="form.errors.description" class="mt-2" />
          </div>

          <!--          <div class="col-span-6 sm:col-span-4">-->
          <!--            <jet-label for="event" value="Algorithm" title="The algorithm will determine how Checkmango enrolls users into variants." />-->

          <!--            <select id="algorithm" v-model="form.algorithm_type" class="mt-1 block w-full pl-3 pr-10 py-2 border-slate-300 focus:outline-none focus:ring-slate-500 focus:border-slate-500 rounded-md">-->
          <!--              <option value="blockRandomization">Equal Distribution</option>-->
          <!--              <option value="weightedSample">Weighted Variants</option>-->
          <!--              <option value="whiplash">Best Performing</option>-->
          <!--            </select>-->

          <!--            <jet-label :value="algorithmDescription(form.algorithm_type)" class="mt-2" :sub-label="true" />-->

          <!--            <jet-input-error :message="form.errors.algorithm_type" class="mt-2" />-->
          <!--          </div>-->

          <div class="col-span-6 sm:col-span-4">
            <Label for="event" value="Primary Event Metric" title="Checkmango will consider a variant converted when this event is triggered." />

            <div class="flex items-center space-x-4">
              <div v-if="Object.keys(events).length" class="flex-1">
                <select id="event" v-model="form.event_key" class="block w-full rounded-md border-slate-300 bg-white shadow-sm focus:border-slate-500 focus:outline-none focus:ring-slate-500">
                  <option selected />
                  <option v-for="(key, id) in events" :key="id" :value="key">
                    {{ key }}
                  </option>
                </select>
              </div>

              <SecondaryButton type="button" class="h-10 py-2" :disabled="!can.create_events" @click="showCreateEventModal = true"> + Event </SecondaryButton>
            </div>

            <InputError :message="form.errors.event_key" class="mt-2" />
          </div>
        </template>
        <template #actions>
          <ActionMessage :on="form.recentlySuccessful" class="mr-3"> Created. </ActionMessage>

          <SecondaryButton class="mr-2" @click="$inertia.visit(route('experiments'))">Cancel</SecondaryButton>

          <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Create </Button>
        </template>
      </FormSection>

      <SectionBorder />

      <FormSection>
        <template #title> Sample Size Calculator </template>

        <template #description> To generate the sample size, please provide your current conversion rate and the <abbr title="Minimum Detectable Effect">MDE</abbr> you wish to detect. </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="mde" value="Minimum Detectable Effect (MDE)" title="This is the smallest effect size that you want your test to be able to detect. Smaller effects require larger sample sizes to detect with confidence." />

            <RangeSlider id="mde" v-model.number="sampleSizeForm.mde" :min="1" :max="50" step="1.0" class="mt-1 block w-full" />

            <InputError :message="sampleSizeForm.errors.mde" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="baseline_conversion_rate" value="Baseline Conversion Rate" />

            <div class="relative mt-1">
              <Input id="baseline_conversion_rate" v-model.number="sampleSizeForm.baseline_conversion_rate" type="number" class="block w-full" />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">%</div>
            </div>

            <InputError :message="sampleSizeForm.errors.baseline_conversion_rate" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Banner v-if="sampleSizeCalculated" type="info">
              Given an equal split of traffic between variants, each will need to receive at least <strong>{{ numberFormat(sampleSize) }}</strong> participants.
            </Banner>
            <Banner v-else type="error"> There was an error calculating the sample size. </Banner>
          </div>
        </template>
      </FormSection>
    </div>

    <CreateEventModal :show="showCreateEventModal" @event-created="eventCreated" @close="closeCreateEventModal" />
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import Label from '@/Jetstream/Label.vue'
import FormSection from '@/Jetstream/FormSection.vue'
import Button from '@/Jetstream/Button.vue'
import Input from '@/Jetstream/Input.vue'
import InputError from '@/Jetstream/InputError.vue'
import ActionMessage from '@/Jetstream/ActionMessage.vue'
import CreateEventModal from '@/Modals/CreateEventModal.vue'
import SectionBorder from '@/Jetstream/SectionBorder.vue'
import RangeSlider from '@/Jetstream/RangeSlider.vue'
import Banner from '@/Components/Banner.vue'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Banner,
    RangeSlider,
    SectionBorder,
    Head,
    InputError,
    Input,
    Label,
    Button,
    SecondaryButton,
    FormSection,
    ActionMessage,
    Editor: defineAsyncComponent(() => import('@/Components/Editor.vue')),
    CreateEventModal,
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    events: null,
    can: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        key: '',
        description: '',
        algorithm_type: 'blockRandomization',
        event_key: '',
        allow_multiple_experiments: false,
      }),
      sampleSizeForm: this.$inertia.form({
        significance_level: 0.95,
        baseline_conversion_rate: 20,
        mde: 5,
        tails: 2,
        variants: 2,
      }),
      showCreateEventModal: false,
      sampleSize: null,
      sampleSizeCalculated: false,
    }
  },
  watch: {
    'sampleSizeForm.mde'() {
      this.calculateSampleSize()
    },
    'sampleSizeForm.baseline_conversion_rate'() {
      this.calculateSampleSize()
    },
    'sampleSizeForm.variants'() {
      this.calculateSampleSize()
    },
  },
  created() {
    this.calculateSampleSize()
  },
  methods: {
    createExperiment() {
      this.form.post(this.route('experiments.store'), {
        preserveScroll: true,
      })
    },
    handleEditorContentChange(content) {
      this.form.description = content
    },
    closeCreateEventModal() {
      this.showCreateEventModal = false
    },
    eventCreated(event) {
      this.closeCreateEventModal()

      this.form.event_key = event
    },
    calculateSampleSize() {
      const conversion_rate = this.sampleSizeForm.baseline_conversion_rate * 0.01
      const alpha_level = 0.05
      const power_level = 0.8
      const p = this.sampleSizeForm.mde * 0.01

      if (conversion_rate > 0 && this.validateDelta(p, conversion_rate)) {
        let n = this.num_subjects(alpha_level, power_level, conversion_rate, p)

        if (!isNaN(n)) {
          this.sampleSizeCalculated = true
          this.sampleSize = Math.round(n)
        }
      } else {
        this.sampleSizeCalculated = false
        this.sampleSize = null
      }
    },
    ppnd(p) {
      var a0 = 2.50662823884
      var a1 = -18.61500062529
      var a2 = 41.39119773534
      var a3 = -25.44106049637
      var b1 = -8.4735109309
      var b2 = 23.08336743743
      var b3 = -21.06224101826
      var b4 = 3.13082909833
      var c0 = -2.78718931138
      var c1 = -2.29796479134
      var c2 = 4.85014127135
      var c3 = 2.32121276858
      var d1 = 3.54388924762
      var d2 = 1.63706781897
      var r
      var split = 0.42
      var value

      /*
         0.08 < P < 0.92
         */
      if (Math.abs(p - 0.5) <= split) {
        r = (p - 0.5) * (p - 0.5)

        value = ((p - 0.5) * (((a3 * r + a2) * r + a1) * r + a0)) / ((((b4 * r + b3) * r + b2) * r + b1) * r + 1.0)
      } else if (0.0 < p && p < 1.0) {
        /*
         P < 0.08 or P > 0.92,
         R = min ( P, 1-P )
         */
        if (0.5 < p) {
          r = Math.sqrt(-Math.log(1.0 - p))
        } else {
          r = Math.sqrt(-Math.log(p))
        }

        value = (((c3 * r + c2) * r + c1) * r + c0) / ((d2 * r + d1) * r + 1.0)

        if (p < 0.5) {
          value = -value
        }
      } else {
        /*
         P <= 0.0 or 1.0 <= P
         */
        value = NaN
      }

      return value
    },
    num_subjects(alpha, power_level, p, delta) {
      if (p > 0.5) {
        p = 1.0 - p
      }
      var t_alpha2 = this.ppnd(1.0 - alpha / 2)
      var t_beta = this.ppnd(power_level)

      var sd1 = Math.sqrt(2 * p * (1.0 - p))
      var sd2 = Math.sqrt(p * (1.0 - p) + (p + delta) * (1.0 - p - delta))

      return ((t_alpha2 * sd1 + t_beta * sd2) * (t_alpha2 * sd1 + t_beta * sd2)) / (delta * delta)
    },
    validateDelta(p, conversionRate) {
      return p > 0 && p < 1 && p + conversionRate < 1
    },
  },
}
</script>
