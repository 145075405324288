<template>
  <div>
    <Head title="Create Team" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <CreateTeamForm />
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import CreateTeamForm from './CreateTeamForm.vue'

export default {
  components: {
    Head,
    CreateTeamForm,
  },
  layout: [AppLayout],
}
</script>
