<template>
  <AppLayout title="Features" :links="links" :user="user">
    <template #headerActions>
      <LinkButton v-if="can.create_feature" :href="route('features.create')"> Create Feature </LinkButton>
    </template>

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="mb-8 overflow-hidden bg-white shadow-sm ring-1 ring-slate-900/5 md:rounded-xl">
        <FeaturesTable :features="features" title="Features" subtitle="Feature flags." :no-filter-results="noFilterResults" />
      </div>
    </div>

    <ConfirmationModal :show="featureToDelete !== null" @close="featureToDelete = null">
      <template #title> Delete Feature </template>

      <template #content>
        <p>Are you sure you want to delete this feature flag?</p>

        <p>Keep in mind that this action cannot be undone.</p>
      </template>

      <template #footer>
        <SecondaryButton @click="featureToDelete = null"> Never mind </SecondaryButton>

        <DangerButton class="ml-2" @click="deleteEvent"> Delete Feature </DangerButton>
      </template>
    </ConfirmationModal>
  </AppLayout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import LinkButton from '@/Components/LinkButton.vue'
import ConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import FeaturesTable from './Partials/Table.vue'

export default {
  components: {
    AppLayout,
    FeaturesTable,
    LinkButton,
    ConfirmationModal,
    SecondaryButton,
    DangerButton,
  },
  props: {
    can: Object,
    links: {
      type: Array,
      default: () => [],
    },
    features: {
      type: Object,
    },
    counts: Object,
    noFilterResults: Boolean,
    user: Object,
  },
  data: () => ({
    featureToDelete: null,
  }),
  methods: {
    deleteEvent() {
      this.$inertia.delete(this.route('features.destroy', this.featureToDelete.key))

      this.featureToDelete = null
    },
  },
}
</script>
