<template>
  <div>
    <Head :title="`${participant.key} | Participants`" />
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <FormSection>
        <template #title>Details</template>

        <template #description> Here you'll find the participant's details. </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label>Key</Label>

            <Clipboard>{{ participant.key }}</Clipboard>
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Active Experiment Enrollments</Label>

            {{ numberFormat(participant.running_experiments_count) }}
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Total Experiment Enrollments</Label>

            {{ numberFormat(participant.experiments.length) }}
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Attributes</Label>

            {{ numberFormat(participant.attributes_count) }}
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>First Enrolled</Label>

            {{ dateTimeFormat(participant.created_at) }}
          </div>

          <div v-if="participant.blocked_at" class="col-span-6 sm:col-span-4">
            <Label>Blocked At</Label>

            {{ dateTimeFormat(participant.blocked_at) }}
          </div>
        </template>

        <template #actions>
          <div class="space-x-2">
            <DangerButton v-if="can.block_participant" outline @click="participantToBlock = participant">Block Participant</DangerButton>
            <SecondaryButton v-else-if="can.unblock_participant" @click="participantToUnblock = participant">Unblock Participant</SecondaryButton>
            <Button v-if="can.edit_participant" @click="$inertia.visit(route('participants.edit', [participant.key]))">Edit</Button>
          </div>
        </template>
      </FormSection>

      <SectionBorder />

      <WideFormSection>
        <template #title>Attributes</template>

        <template #description>
          <p>Use Attributes to store additional data about your Participants.</p>
          <!--          <p>Attributes may be used in combination with <Link :href="route('segments')">Segments</Link> to create targeted <Link :href="route('features')">Features</Link>.</p>-->
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4 p-6">
            <dl class="divide-y divide-gray-100">
              <div v-if="participant.attributes.length">
                <div v-for="attribute in participant.attributes" :key="attribute.key" class="py-2 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
                  <dt class="">
                    <Clipboard>{{ attribute.attribute.key }}</Clipboard>
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex justify-between items-center">
                    <div>
                      <span class="font-mono">{{ attribute.value }}</span>
                    </div>
                    <div class="flex gap-2">
                      <SecondaryButton
                        v-if="can.edit_participant"
                        class="button--small"
                        @click="editParticipantAttribute(participant, attribute)"
                      >
                        Edit
                      </SecondaryButton>
                      <DangerButton v-if="can.edit_participant" outline class="button--small" @click="attributeToDelete = attribute">
                        <TrashIcon class="h-4 w-4" />
                      </DangerButton>
                    </div>
                  </dd>
                </div>
              </div>
              <div v-else class="text-center text-sm text-gray-500">This participant does not have any attributes.</div>
            </dl>
          </div>
        </template>

        <template #actions>
          <div class="space-x-2">
            <Button
              v-if="can.edit_participant"
              @click="editParticipantAttribute(participant, null)"
            >
              Create Attribute
            </Button>
          </div>
        </template>
      </WideFormSection>

      <SectionBorder />

      <FormSection>
        <template #title>Notes</template>

        <template #description> Use notes to store additional information about a Participant. </template>

        <template #form>
          <div class="prose prose-slate col-span-full text-slate-600" v-html="notes" />
        </template>
      </FormSection>

      <SectionBorder />

      <WideFormSection>
        <template #title>Timeline</template>

        <template #description>Here's a timeline of this participant's engagements with your experiments and events.</template>

        <template #form>
          <ParticipantTimeline :participant="participant" :timeline="timeline" />
        </template>
      </WideFormSection>

      <SectionBorder />

      <DangerActionSection>
        <template #title>Danger Zone</template>
        <template #description>You may delete the participant at any time however, this will impact the outcome of any running experiments they are currently enrolled in.</template>

        <template #content>
          <div class="space-y-4">
            <div class="max-w-xl">
              <p>Once you delete a participant, there is no going back. Please be certain.</p>
            </div>

            <div>
              <DangerButton v-if="can.delete_participant" @click="participantToDelete = participant">Delete Participant</DangerButton>
            </div>
          </div>
        </template>
      </DangerActionSection>
    </div>

    <DeleteParticipantModal :participant="participantToDelete" @close="participantToDelete = null" @confirm="deleteParticipant" />
    <BlockParticipantModal :participant="participantToBlock" @close="participantToBlock = null" @confirm="blockParticipant" />
    <UnblockParticipantModal :participant="participantToUnblock" @close="participantToUnblock = null" @confirm="unblockParticipant" />
    <CreateParticipantAttributeModal :participant="participantToEdit" :attribute="attributeToEdit" @close="participantToEdit = null" @attribute-created="participantToEdit = null" />
    <DeleteParticipantAttributeModal :attribute="attributeToDelete" @close="attributeToDelete = null" @confirm="deleteAttribute" />
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import { XMarkIcon } from '@heroicons/vue/16/solid'
import { TrashIcon } from '@heroicons/vue/24/outline'
import Clipboard from '@/Components/Clipboard.vue'
import Label from '@/Jetstream/Label.vue'
import WideFormSection from '@/Jetstream/WideFormSection.vue'
import Button from '@/Jetstream/Button.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import FormSection from '@/Jetstream/FormSection.vue'
import SectionBorder from '@/Jetstream/SectionBorder.vue'
import DeleteParticipantModal from '@/Modals/DeleteParticipantModal.vue'
import ParticipantTimeline from '@/Pages/Participants/Partials/Timeline.vue'
import DangerActionSection from '@/Jetstream/DangerActionSection.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import BlockParticipantModal from '@/Modals/BlockParticipantModal.vue'
import UnblockParticipantModal from '@/Modals/UnblockParticipantModal.vue'
import CreateParticipantAttributeModal from '@/Modals/CreateParticipantAttributeModal.vue'
import DeleteParticipantAttributeModal from '@/Modals/DeleteParticipantAttributeModal.vue'

export default {
  components: {
    DeleteParticipantAttributeModal,
    CreateParticipantAttributeModal,
    UnblockParticipantModal,
    SecondaryButton,
    DangerActionSection,
    Clipboard,
    Head,
    Label,
    Button,
    SectionBorder,
    WideFormSection,
    DangerButton,
    FormSection,
    DeleteParticipantModal,
    ParticipantTimeline,
    BlockParticipantModal,
    TrashIcon,
    XMarkIcon,
  },
  layout: [AppLayout],
  props: {
    can: Object,
    links: {
      type: Array,
      default: () => [],
    },
    participant: {
      type: Object,
    },
    timeline: {
      type: Object,
    },
  },
  data() {
    return {
      participantToBlock: null,
      participantToUnblock: null,
      participantToEdit: null,
      participantToDelete: null,

      attributeToEdit: null,
      attributeToDelete: null,
    }
  },
  computed: {
    notes() {
      return this.participant.notes || '&mdash;'
    },
  },
  methods: {
    deleteParticipant() {
      this.$inertia.delete(this.route('participants.destroy', [this.participantToDelete.key]))

      this.participantToDelete = null
    },
    blockParticipant() {
      this.$inertia.post(this.route('participants.block', [this.participantToBlock.key]), {
        preserveScroll: true,
      })

      this.participantToBlock = null
    },
    unblockParticipant() {
      this.$inertia.delete(this.route('participants.unblock', [this.participantToUnblock.key]), {
        preserveScroll: true,
      })

      this.participantToUnblock = null
    },
    deleteAttribute() {
      this.$inertia.delete(this.route('participants.attributes.destroy', [this.participant.key, this.attributeToDelete.id]), {
        preserveScroll: true,
      })

      this.attributeToDelete = null
    },
    editParticipantAttribute(participant, attribute) {
      this.participantToEdit = participant
      this.attributeToEdit = attribute
    },
  },
}
</script>
