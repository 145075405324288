<template>
  <div>
    <Head :title="title" />

    <section class="relative overflow-hidden py-12 text-slate-700 md:py-24">
      <Container>
        <header class="flex flex-col items-center text-center">
          <h1 class="marketing-headline">
            {{ title }}
          </h1>

          <p class="marketing-heading mt-6">
            {{ description }}
          </p>

          <Button as="a" href="mailto:support@checkmango.com" class="mt-6">Contact us</Button>
        </header>
      </Container>

      <div class="h-12 md:h-24" />
    </section>

    <RegisterInterest />
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import { defineAsyncComponent } from 'vue'
const GuestLayout = defineAsyncComponent(() => import('@/Layouts/GuestLayout.vue'))
const RegisterInterest = defineAsyncComponent(() => import('@/Components/RegisterInterest.vue'))
import Button from '@/Jetstream/Button.vue'
import Container from '@/Components/Container.vue'

export default {
  components: {
    Container,
    Button,
    Head,
    RegisterInterest,
  },
  layout: [GuestLayout],
  props: {
    status: Number,
  },
  computed: {
    title() {
      return {
        403: 'Forbidden',
        404: 'Page Not Found',
        500: 'Internal Server Error',
        503: 'Service Unavailable',
      }[this.status]
    },
    description() {
      return {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'Whoops, something went wrong on our servers.',
        404: 'Sorry, the page you are looking for could not be found.',
        403: 'Sorry, you are forbidden from accessing this page.',
      }[this.status]
    },
  },
}
</script>
