<template>
  <div class="flow-root overflow-hidden px-4 py-5 sm:p-6">
    <ul class="-mb-8">
      <li v-for="(event, $index) in timeline.data" :key="`event-${$index}`">
        <div class="relative pb-8">
          <span class="absolute top-4 left-4 -ml-px w-0.5 bg-slate-200" :class="{ 'h-full': $index < timeline.length - 1 }" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div>
              <span class="flex size-8 items-center justify-center rounded-full ring-8 ring-slate-50" :class="event.color">
                <component :is="heroIcons[event.icon]" class="size-5 text-white" />
              </span>
            </div>
            <div class="flex min-w-0 flex-1 items-center justify-between space-x-4">
              <div class="prose prose-slate w-5/6 text-sm" v-html="event.description" />
              <div class="whitespace-nowrap text-right text-sm tracking-tight text-slate-500">
                <time v-tippy="{ content: event.created_at }" :datetime="event.created_at" class="cursor-help">{{ dateTimeFormat(event.created_at) }}</time>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import * as heroIcons from '@heroicons/vue/16/solid'

export default {
  props: {
    participant: {
      type: Object,
    },
    timeline: {
      type: Object,
    },
  },
  data() {
    return {
      heroIcons: heroIcons,
    }
  },
}
</script>
