<template>
  <div>
    <Head title="Your Profile" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div v-if="$page.props.jetstream.canUpdateProfileInformation">
        <UpdateProfileInformationForm :user="$page.props.user" />

        <JetSectionBorder />
      </div>

      <div>
        <ConnectSocialPlatforms :user="$page.props.user" :connected-to-git-hub="connectedToGithub" :has-account-password="hasAccountPassword" />

        <JetSectionBorder />
      </div>

      <div v-if="$page.props.jetstream.canUpdatePassword && hasAccountPassword">
        <UpdatePasswordForm class="mt-10 sm:mt-0" />

        <JetSectionBorder />
      </div>

      <div v-if="$page.props.jetstream.canManageTwoFactorAuthentication">
        <TwoFactorAuthenticationForm class="mt-10 sm:mt-0" />

        <JetSectionBorder />
      </div>

      <LogoutOtherBrowserSessionsForm :sessions="sessions" class="mt-10 sm:mt-0" />

      <template v-if="$page.props.jetstream.hasAccountDeletionFeatures">
        <JetSectionBorder />

        <DeleteUserForm class="mt-10 sm:mt-0" />
      </template>
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import DeleteUserForm from './DeleteUserForm.vue'
import JetSectionBorder from '@/Jetstream/SectionBorder.vue'
import LogoutOtherBrowserSessionsForm from './LogoutOtherBrowserSessionsForm.vue'
import TwoFactorAuthenticationForm from './TwoFactorAuthenticationForm.vue'
import UpdatePasswordForm from './UpdatePasswordForm.vue'
import UpdateProfileInformationForm from './UpdateProfileInformationForm.vue'
import ConnectSocialPlatforms from '@/Pages/Profile/ConnectSocialPlatforms.vue'

export default {
  components: {
    ConnectSocialPlatforms,
    Head,
    DeleteUserForm,
    JetSectionBorder,
    LogoutOtherBrowserSessionsForm,
    TwoFactorAuthenticationForm,
    UpdatePasswordForm,
    UpdateProfileInformationForm,
  },
  layout: [AppLayout],
  props: {
    sessions: Array,
    connectedToGithub: Boolean,
    connectedToGoogle: Boolean,
    hasAccountPassword: Boolean,
  },
}
</script>
