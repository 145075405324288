<template>
  <div>
    <Head title="Billing" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <ActionSection v-if="hasFreeAccount">
        <template #title>Your Subscription</template>
        <template #description>Looks like you're doing pretty well.</template>

        <template #content>
          <p>Hey, we think you're pretty cool.</p>
          <p>This means you're currently getting Checkmango for <strong class="font-semibold">FREE</strong>. Why? Because you're flippin' awesome 😎</p>
        </template>
      </ActionSection>

      <template v-else>
        <div v-if="hasSubscription">
          <FormSection>
            <template #title>Your Subscription</template>
            <template #description>Manage your Checkmango subscription.</template>

            <template #form>
              <div v-if="$page.props.user.current_team.subscription.trialing" class="col-span-6 sm:col-span-4">
                <Banner>
                  You are currently trialing the <strong class="font-semibold">{{ subscription.plan.name }}</strong> plan. Your trial will end in {{ $page.props.subscription.trialEndsAt }} days, at which point you will be billed for the next month.
                </Banner>
              </div>
              <template v-if="subscription.plan">
                <div class="col-span-6 sm:col-span-4">
                  <Label value="Your Plan" />
                  {{ subscription.plan.name }} &ndash; {{ subscription.plan.description }}
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <Label value="Status" />
                  {{ subscription.humanStatus }}
                </div>

                <div v-if="subscription.status === 'cancelled'" class="col-span-6 sm:col-span-4">
                  <Label value="Subscription Ends At" />
                  {{ dateFormat(subscription.ends_at) }}
                </div>

                <div v-if="['active', 'on_trial'].includes(subscription.status)" class="col-span-6 sm:col-span-4">
                  <Label value="Subscription Renews" />
                  {{ dateFormat(subscription.renews_at) }}
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <Label value="API Requests This Month" />
                  {{ numberFormat(currentTeam.api_requests) }}
                </div>
              </template>

              <div v-else-if="subscription.status === 'cancelled'" class="col-span-6 sm:col-span-4">
                <p>Your subscription is now cancelled.</p>
              </div>
            </template>

            <template #actions>
              <div class="space-x-3">
                <LinkButton is="a" v-if="canModifyPaymentDetails" type="secondary" :href="subscription.customerPortalUrl">Manage Subscription</LinkButton>
              </div>
            </template>
          </FormSection>

          <div v-if="canCancelSubscription">
            <SectionBorder />

            <DangerActionSection>
              <template #title>Cancel Subscription</template>
              <template #description>You may cancel your subscription at any time.</template>

              <template #content>
                <p>Once your subscription has been cancelled, you will have the option to resume the subscription until the end of your current billing cycle.</p>

                <div class="mt-5 flex justify-end space-x-3">
                  <DangerButton @click="confirmingSubscriptionCancellation = true"> Cancel Subscription </DangerButton>
                </div>
              </template>
            </DangerActionSection>
          </div>
        </div>

        <template v-if="!hasSubscription || ['cancelled'].includes(subscription.status)">
          <SectionBorder v-if="hasSubscription || ['cancelled'].includes(subscription.status)" />

          <ActionSection>
            <template #title>Subscription</template>
            <template #description>
              <p>Checkmango offers a variety of plans that grows with your business.</p>

              <p>Only requests made to the <code>/ingest</code> endpoint will count towards your team's API request limit.</p>
            </template>

            <template #content>
              <div class="flex justify-center">
                <RadioGroup v-model="frequency" class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-slate-200">
                  <RadioGroupLabel class="sr-only">Payment frequency</RadioGroupLabel>
                  <RadioGroupOption v-for="option in frequencies" :key="option.value" v-slot="{ checked }" as="template" :value="option">
                    <div :class="[checked ? 'bg-emerald-500 text-white' : 'text-slate-500', 'cursor-pointer rounded-full px-2.5 py-1']">
                      <span>{{ option.label }}</span>
                    </div>
                  </RadioGroupOption>
                </RadioGroup>
              </div>
              <div class="isolate mx-auto mt-10 grid max-w-full grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                <Plan v-for="tier in tiers" :key="tier.id" :frequency="frequency" :tier="tier" :team="currentTeam" />
              </div>
            </template>
          </ActionSection>
        </template>
      </template>

      <ConfirmationModal :show="confirmingSubscriptionCancellation">
        <template #title>Cancel Subscription</template>

        <template #content>
          <p>Are you sure you want to cancel your Checkmango subscription?</p>

          <p>If you have any running experiments, they will automatically stop and Checkmango will no longer collect data for you.</p>
        </template>

        <template #footer>
          <SecondaryButton @click="confirmingSubscriptionCancellation = false"> Never mind </SecondaryButton>
          <DangerButton class="ml-2" :class="{ 'opacity-25': cancelSubscriptionForm.processing }" :disabled="cancelSubscriptionForm.processing" @click="cancelSubscription"> Cancel Subscription </DangerButton>
        </template>
      </ConfirmationModal>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import Plan from './Partials/Plan.vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import ActionSection from '@/Jetstream/ActionSection.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import FormSection from '@/Jetstream/FormSection.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import ConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import LinkButton from '@/Components/LinkButton.vue'
import DangerActionSection from '@/Jetstream/DangerActionSection.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import SectionBorder from '@/Jetstream/SectionBorder.vue'
import Label from '@/Jetstream/Label.vue'
import Banner from '@/Jetstream/Banner.vue'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'yearly', label: 'Yearly', priceSuffix: '/year' },
]

export default {
  components: {
    Banner,
    Label,
    SectionBorder,
    SecondaryButton,
    DangerActionSection,
    LinkButton,
    ConfirmationModal,
    DangerButton,
    FormSection,
    ActionSection,
    Plan,
    Head,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },
  layout: [AppLayout],
  props: {
    tiers: Array,
    subscription: Object,
    updatePaymentMethodUrl: String,
  },
  data() {
    return {
      frequencies: frequencies,

      frequency: frequencies[0],

      confirmingSubscriptionCancellation: false,
      cancelSubscriptionForm: this.$inertia.form({}),
    }
  },
  computed: {
    currentTeam() {
      return this.$page.props.user.current_team
    },
    hasFreeAccount() {
      return this.currentTeam?.subscription?.freeAccount || false
    },
    canManageBilling() {
      return this.$page.props.user.current_team?.subscription?.canManageBilling || false
    },
    hasSubscription() {
      return !!this.subscription.plan
    },
    canModifyPaymentDetails() {
      return this.subscription && !['cancelled'].includes(this.subscription.status) && this.canManageBilling
    },
    canCancelSubscription() {
      return !this.hasFreeAccount && this.hasSubscription && !['cancelled'].includes(this.subscription.status) && this.canManageBilling
    },
  },
  methods: {
    cancelSubscription() {
      this.cancelSubscriptionForm.delete(this.route('teams.billing.cancel', [this.currentTeam]), {
        errorBag: 'cancelSubscription',
        onFinish: () => {
          this.confirmingSubscriptionCancellation = false
          this.$inertia.reload({
            only: ['subscription', 'team'],
          })
        },
      })
    },
  },
}
</script>
