<template>
  <div class="mx-auto max-w-7xl overflow-hidden bg-white shadow-sm ring-1 ring-slate-900/5 sm:rounded-xl">
    <div class="px-4 sm:px-6">
      <div class="flex items-center justify-between">
        <div class="flex-1 py-5">
          <h3 class="font-display text-lg font-semibold leading-6 text-slate-900">
            <slot name="title" />
          </h3>
        </div>

        <div class="">
          <slot name="actions" />
        </div>
      </div>
    </div>
    <div :class="contentWrapperClasses">
      <article class="max-w-full" :class="contentClasses">
        <slot />
      </article>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    withProse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    contentWrapperClasses() {
      let classes = 'bg-white text-slate-500'

      if (this.withProse) {
        classes += ' px-4 py-5 sm:p-6'
      }

      return classes
    },
    contentClasses() {
      let classes = 'max-w-full'

      if (this.withProse) {
        classes += ' prose prose-blue text-slate-600'
      }

      return classes
    },
  },
}
</script>
