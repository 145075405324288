const colors = {
  'chart-a': '#3366cc',
  'chart-b': '#dc3912',
  'chart-c': '#ff9900',
  'chart-d': '#109618',
  'chart-e': '#990099',
  'chart-f': '#0099c6',
  'chart-g': '#dd4477',
  'chart-h': '#66aa00',
  'chart-i': '#b82e2e',
  'chart-j': '#316395',
  'chart-k': '#994499',
  'chart-l': '#22aa99',
  'chart-m': '#aaaa11',
  'chart-n': '#6633cc',
  'chart-o': '#e67300',
  'chart-p': '#8b0707',
  'chart-q': '#651067',
  'chart-r': '#329262',
  'chart-s': '#5574a6',
  'chart-t': '#3b3eac',
  'chart-u': '#b77322',
  'chart-v': '#16d620',
  'chart-w': '#b91383',
  'chart-x': '#f4359e',
  'chart-y': '#9c5935',
  'chart-z': '#a9c413',
  // '#2a778d',
  // '#668d1c',
  // '#bea413',
  // '#0c5922',
  // '#743411',
}

export default colors
