<template>
  <ConfirmationModal :show="participant !== null" @close="$emit('close')">
    <template #title> Block Participant </template>

    <template #content>
      <p>Are you sure you want to block this participant? Checkmango will no accept ingestion requests made by this participant.</p>
      <p class="font-semibold">Ingestion requests made by this participant will still count towards your team's API usage.</p>
    </template>

    <template #footer>
      <SecondaryButton @click="$emit('close')"> Never mind </SecondaryButton>

      <DangerButton class="ml-2" @click="$emit('confirm')"> Block Participant </DangerButton>
    </template>
  </ConfirmationModal>
</template>

<script>
import ConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'

export default {
  components: {
    ConfirmationModal,
    SecondaryButton,
    DangerButton,
  },
  props: {
    participant: {
      type: Object,
      required: false,
    },
  },
  emits: ['close', 'confirm'],
}
</script>
