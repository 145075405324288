<template>
  <AppLayout title="Webhooks" :links="links" :user="user">
    <template #headerActions>
      <LinkButton v-if="can.create_webhook" :href="route('webhooks.create')"> Create Webhook </LinkButton>
    </template>

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="mb-8 overflow-hidden bg-white shadow-sm ring-1 ring-slate-900/5 md:rounded-xl">
        <WebhooksTable :webhooks="webhooks" title="Webhooks" subtitle="Get notified via webhook when something changes in your Checkmango team." :no-filter-results="noFilterResults" />
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import WebhooksTable from './Partials/Table.vue'
import LinkButton from '@/Components/LinkButton.vue'

export default {
  components: {
    LinkButton,
    AppLayout,
    WebhooksTable,
  },
  props: {
    can: Object,
    links: {
      type: Array,
      default: () => [],
    },
    webhooks: {
      type: Object,
    },
    counts: Object,
    noFilterResults: Boolean,
    user: Object,
  },
  data: () => ({
    featureToDelete: null,
  }),
  methods: {
    deleteEvent() {
      this.$inertia.delete(this.route('features.destroy', this.featureToDelete.key))
      this.featureToDelete = null
    },
  },
}
</script>
