<template>
  <div>
    <Head :title="`Edit ${participant.key} | Participants`" />
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <JetFormSection @submitted="editParticipant">
        <template #title>Participant Details</template>

        <template #description>
          <p>Participants represent the customers or users of your application. The key that is assigned to a participant should be unique within your application.</p>
          <p>
            You may only edit a participant once they have been enrolled into
            {{ $page.props.app_name }}.
          </p>
          <p>
            Need more help? Check out the
            <a href="https://docs.checkmango.com/participants" target="_blank">documentation</a>.
          </p>
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <JetLabel for="key" value="Key" />

            <JetInput id="key" v-model="form.key" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

            <JetInputError :message="form.errors.key" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <JetLabel for="notes" value="Notes" />

            <Editor id="notes" :initial-content="form.notes" @content-changed="handleEditorContentChange" />

            <JetInputError :message="form.errors.notes" class="mt-2" />
          </div>
        </template>

        <template #actions>
          <JetActionMessage :on="form.recentlySuccessful" class="mr-3"> Updated. </JetActionMessage>

          <JetSecondaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing" @click="$inertia.visit(route('participants.show', participant.key))"> Cancel </JetSecondaryButton>

          <JetButton :class="{ 'opacity-25': form.processing }" class="ml-3" :disabled="form.processing"> Update </JetButton>
        </template>
      </JetFormSection>
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetButton from '@/Jetstream/Button.vue'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Head,
    JetFormSection,
    JetLabel,
    JetInput,
    JetInputError,
    JetActionMessage,
    JetSecondaryButton,
    JetButton,
    Editor: defineAsyncComponent(() => import('@/Components/Editor.vue')),
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    participant: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        key: this.participant.key,
        notes: this.participant.notes,
      }),
    }
  },
  methods: {
    editParticipant() {
      this.form.put(this.route('participants.update', this.participant.key), {
        preserveScroll: true,
      })
    },
    handleEditorContentChange(content) {
      this.form.notes = content
    },
  },
}
</script>
