<template>
  <div class="md:grid md:grid-cols-8 md:gap-6">
    <JetSectionTitle>
      <template #title>
        <span class="font-display tracking-normal"><slot name="title" /></span>
      </template>
      <template #description>
        <div class="prose prose-sm prose-slate">
          <slot name="description" />
        </div>
      </template>
    </JetSectionTitle>

    <div class="mt-5 md:col-span-6 md:mt-0">
      <form class="" @submit.prevent="$emit('submitted')">
        <div class="overflow-hidden">
          <slot name="form" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import JetSectionTitle from './SectionTitle.vue'

export default {
  components: {
    JetSectionTitle,
  },

  emits: ['submitted'],

  computed: {
    hasActions() {
      return !!this.$slots.actions
    },
  },
}
</script>
