<template>
  <a href="/">
    <JetApplicationMark class="w-64 fill-current" />
  </a>
</template>

<script>
import JetApplicationMark from '@/Jetstream/ApplicationMark.vue'

export default {
  components: {
    JetApplicationMark,
  },
}
</script>
