<template>
  <AuthenticationCard>
    <Head title="Confirm Your Password" />

    <div class="mb-4 text-slate-600">This is a secure area of the application. Please confirm your password before continuing.</div>

    <Banner v-if="sessionExpired" type="danger">Your Checkmango session has expired. Please try again.</Banner>

    <ValidationErrors class="mb-4" />

    <form @submit.prevent="submit">
      <div>
        <Label for="password" value="Password" />
        <Input id="password" v-model="form.password" type="password" class="mt-1 block w-full" required autocomplete="current-password" autofocus />
      </div>

      <div class="mt-4 flex justify-end">
        <Button class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Confirm </Button>
      </div>
    </form>
  </AuthenticationCard>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import AuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
import Button from '@/Jetstream/Button.vue'
import Input from '@/Jetstream/Input.vue'
import Label from '@/Jetstream/Label.vue'
import ValidationErrors from '@/Jetstream/ValidationErrors.vue'
import AuthenticationLayout from '@/Layouts/AuthenticationLayout.vue'
import Banner from '@/Jetstream/Banner.vue'

export default {
  components: {
    Banner,
    Head,
    AuthenticationCard,
    Button,
    Input,
    Label,
    ValidationErrors,
  },

  layout: [AuthenticationLayout],

  props: {
    sessionExpired: Boolean,
  },

  data() {
    return {
      form: this.$inertia.form({
        password: '',
      }),
    }
  },

  methods: {
    submit() {
      this.form.post(this.route('password.confirm'), {
        onFinish: () => this.form.reset(),
      })
    },
  },
}
</script>
