<template>
  <div>
    <Head title="API Tokens" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <ApiTokenManager :tokens="tokens" :available-permissions="availablePermissions" :default-permissions="defaultPermissions" />
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import ApiTokenManager from './ApiTokenManager.vue'
import AppLayout from '@/Layouts/AppLayout.vue'

export default {
  components: {
    Head,
    ApiTokenManager,
  },
  layout: [AppLayout],
  props: {
    tokens: {
      type: Array,
      default: () => [],
    },
    availablePermissions: {
      type: Array,
      default: () => [],
    },
    defaultPermissions: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
