/* eslint-disable vue/no-multiple-template-root */
<template>
  <div class="col-span-6 sm:col-span-4">
    <JetLabel for="key" value="Key" />

    <JetInput id="key" v-model="form.key" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

    <JetInputError :message="form.errors.key" class="mt-2" />
  </div>

  <div class="col-span-6 sm:col-span-4">
    <JetLabel for="type" value="Type" />

    <select id="type" v-model="form.type" class="block w-full rounded-md border-slate-300 bg-white shadow-sm focus:border-slate-500 focus:outline-none focus:ring-slate-500">
      <option value="count" selected>Count</option>
      <option value="unique">Unique</option>
      <!-- <option value="duration">Duration - How long something takes (time on site, loading speed etc)</option> -->
      <!-- <option value="revenue">Revenue - How much money a customer pays (revenue, average order value, etc)</option> -->
    </select>

    <JetLabel :value="eventTypeDescription" class="mt-2" :sub-label="true" />

    <JetInputError :message="form.errors.type" class="mt-2" />
  </div>

  <div class="col-span-6 sm:col-span-4">
    <JetLabel for="description" value="Description" />

    <Editor id="description" :initial-content="form.description" @content-changed="handleEditorContentChange" />

    <JetInputError :message="form.errors.description" class="mt-2" />
  </div>
</template>

<script>
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    JetLabel,
    JetInput,
    JetInputError,
    Editor: defineAsyncComponent(() => import('@/Components/Editor.vue')),
  },
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['eventCreated'],
  data() {
    return {
      form: this.$inertia.form({
        key: '',
        description: '',
        type: 'count',
      }),
    }
  },
  computed: {
    eventTypeDescription() {
      switch (this.form.type) {
      case 'unique':
        return 'Counts one conversion per participant (created account, logged in)'
      case 'count':
        return 'Counts multiple conversions per participant (clicks, views, downloads)'
      default:
        return 'Unknown event type'
      }
    },
  },
  methods: {
    handleEditorContentChange(content) {
      this.form.description = content
    },
    createEvent() {
      const key = this.form.key

      this.form.post(
        this.route('events.store', {
          inline: this.inline,
        }),
        {
          preserveScroll: true,
          onSuccess: () => {
            this.form.reset()
            this.$emit('eventCreated', key)
          },
        },
      )
    },
  },
}
</script>
