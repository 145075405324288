<template>
  <div>
    <ul role="list" class="grid grid-cols-1 gap-6 sm:py-6 lg:grid-cols-2" :class="{ 'border-b border-slate-200': hasFooterSlot }">
      <li v-for="(item, itemIdx) in items" :key="itemIdx" class="flow-root">
        <div :class="[itemIsChecked(item) ? 'opacity-60' : null]" class="z-0 -m-2 flex items-start space-x-4 rounded-xl border-2 border-transparent p-2 focus-within:ring-2 focus-within:ring-slate-500 hover:border-slate-200 hover:bg-slate-100">
          <div :class="[item.background || 'bg-gray-400', 'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg']">
            <component :is="item.icon" class="size-6 text-white" aria-hidden="true" />
          </div>
          <component :is="item.link.component" v-bind="item.link" class="focus:outline-none">
            <h3 class="text-normal font-display font-semibold text-slate-900">
              <div class="flex items-center" :class="[itemIsChecked(item) && 'text-emerald-600']">
                <CheckCircleIcon v-if="itemIsChecked(item)" class="mr-1 size-6" />
                {{ item.title }}
              </div>
            </h3>
            <p class="mt-1 text-sm text-slate-500">{{ item.description }}</p>
          </component>
        </div>
      </li>
    </ul>
    <div v-if="hasFooterSlot" class="mt-4 flex">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import { CheckCircleIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    CheckCircleIcon,
    Link,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },
  methods: {
    itemIsChecked(item) {
      return item.checked || false
    },
  },
}
</script>
