<template>
  <div>
    <div class="align-middle">
      <table class="whitespace-no-wrap min-w-full divide-y divide-slate-200">
        <thead class="bg-slate-50">
          <tr class="text-left">
            <th scope="col" />
            <th scope="col" class="px-2 py-5 font-medium tracking-tight text-slate-900">
              <span class="sr-only">View</span>
            </th>
            <th scope="col" class="py-5 pr-2 font-medium tracking-tight text-slate-900 md:pr-4">Key</th>
            <th v-if="experiment.algorithm_uses_weights" scope="col" class="px-2 py-5 font-medium tracking-tight text-slate-900">Traffic</th>
            <th scope="col" class="px-2 py-5 font-medium tracking-tight text-slate-900">Participants</th>
            <th scope="col" class="px-2 py-5 font-medium tracking-tight text-slate-900">Conversions</th>
            <th scope="col" class="px-2 py-5 font-medium tracking-tight text-slate-900">Conversion Rate</th>
            <th scope="col" class="px-2 py-5 font-medium tracking-tight text-slate-900">
              <select v-model="variantScore" class="block w-full rounded-md border-slate-300 bg-white shadow-sm focus:border-slate-500 focus:outline-none focus:ring-slate-500">
                <option value="probability">Prob. of Winning</option>
                <option value="confidence">Confidence</option>
              </select>
            </th>
            <th v-if="experiment.winning_variant_id" scope="col" />
            <th scope="col" />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(variant, index) in variants"
            :key="variant.id"
            class="divide-y divide-slate-200"
            :class="{
              'opacity-50': experiment.winning_variant_id && experiment.winning_variant_id !== variant.id,
            }"
          >
            <th
              scope="col"
              :style="{
                'background-color': computeVariantColorVariable(index),
              }"
            >
              &nbsp;
            </th>
            <td class="w-px bg-white px-2 py-3">
              <Squares2X2Icon v-if="variant.control" v-tippy="{ content: 'This is the control variant' }" class="size-5 cursor-help text-emerald-500" />
            </td>
            <td class="bg-white py-3 pr-2">
              <Clipboard class="m-0">
                {{ variant.key }}
              </Clipboard>
            </td>
            <td v-if="experiment.algorithm_uses_weights" class="bg-white px-2 py-3 font-mono">{{ variant.traffic }}%</td>
            <td class="bg-white px-2 py-3">
              <span v-if="!experiment.is_draft" class="font-mono">
                {{ numberFormat(variant.participant_count) }}
              </span>
              <span v-else>&mdash;</span>
            </td>
            <td class="bg-white px-2 py-3">
              <span v-if="!experiment.is_draft" class="font-mono">
                {{ numberFormat(variant.conversion_count) }}
              </span>
              <span v-else>&mdash;</span>
            </td>
            <td class="bg-white px-2 py-3">
              <span v-if="!experiment.is_draft" class="font-mono">
                {{ percentFormatter(variant.conversion_rate) }}
                <template v-if="experiment.control.conversion_rate > 0 && !variant.control">
                  <span
                    v-if="variant.conversion_rate > experiment.control.conversion_rate"
                    v-tippy="{
                      content: 'This variant is performing better than the control',
                    }"
                    class="block cursor-help font-bold tracking-tight text-emerald-500"
                  >
                    (+{{ percentFormatter(variant.conversion_rate / experiment.control.conversion_rate - 1) }})
                  </span>
                  <span
                    v-if="variant.conversion_rate < experiment.control.conversion_rate"
                    v-tippy="{
                      content: 'This variant is performing worse than the control',
                    }"
                    class="block cursor-help font-bold tracking-tight text-red-500"
                  >
                    ({{ percentFormatter(variant.conversion_rate / experiment.control.conversion_rate - 1) }})
                  </span>
                </template>
              </span>

              <span v-else>&mdash;</span>
            </td>
            <td class="bg-white px-2 py-3">
              <template v-if="showProbabilityScore">
                <span v-if="!experiment.is_draft" class="font-mono">
                  {{ percentFormatter(variant.winner_percent) }}
                </span>
                <span v-else>&mdash;</span>
              </template>
              <template v-else-if="showConfidenceScore">
                <span v-if="!experiment.is_draft" v-tippy="{ content: `z-score: ${round(variant.z_score, 3)}` }" class="cursor-help font-mono">{{ formatConfidenceLevel(variant) }}</span>
                <span v-else>&mdash;</span>
              </template>
            </td>
            <td v-if="experiment.winning_variant_id" class="bg-white px-2 py-3">
              <CheckBadgeIcon
                v-if="experiment.winning_variant_id === variant.id"
                v-tippy="{
                  content: 'This variant was selected as the winner!',
                }"
                class="size-5 cursor-help text-emerald-500"
              />
            </td>
            <td class="bg-white px-2 py-3">
              <JetDropdown>
                <template #trigger>
                  <JetDropdownButton>
                    <EllipsisVerticalIcon class="size-4" />
                  </JetDropdownButton>
                </template>

                <template #content>
                  <JetDropdownInertiaItem :href="route('experiments.variants.show', [experiment.key, variant.key])"> View Variant </JetDropdownInertiaItem>

                  <form v-if="experiment.is_running" @submit.prevent="variantToWin = variant">
                    <JetDropdownLink as="button">
                      <div class="flex items-center">Set Variant Winner</div>
                    </JetDropdownLink>
                  </form>
                </template>
              </JetDropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <JetConfirmationModal v-if="variantToWin !== null" :show="variantToWin" @close="variantToWin = null">
      <template #title> Set Variant Winner </template>

      <template #content>
        <p>
          Are you sure you want to set this experiment's winner to the
          <code>{{ variantToWin.key }}</code> variant?
        </p>

        <p class="font-semibold">Once a winner has been selected, the experiment will be stopped. Existing participants will continue to receive their variant. New participants will be enrolled into the winning variant.</p>
      </template>

      <template #footer>
        <JetSecondaryButton @click="variantToWin = null"> Never mind </JetSecondaryButton>

        <JetButton class="ml-2" @click="setWinningVariant"> Set Winner </JetButton>
      </template>
    </JetConfirmationModal>
  </div>
</template>

<script>
import chartColors from '@/chartColors.js'
import Clipboard from '@/Components/Clipboard.vue'
import { CheckBadgeIcon, EllipsisVerticalIcon, Squares2X2Icon } from '@heroicons/vue/24/outline'
import JetDropdown from '@/Jetstream/Dropdown.vue'
import JetDropdownButton from '@/Jetstream/DropdownButton.vue'
import JetDropdownInertiaItem from '@/Jetstream/DropdownInertiaItem.vue'
import JetDropdownLink from '@/Jetstream/DropdownLink.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetButton from '@/Jetstream/Button.vue'

export default {
  components: {
    Clipboard,
    EllipsisVerticalIcon,
    Squares2X2Icon,
    CheckBadgeIcon,
    JetDropdown,
    JetDropdownButton,
    JetDropdownInertiaItem,
    JetDropdownLink,
    JetConfirmationModal,
    JetSecondaryButton,
    JetButton,
  },
  props: {
    experiment: {
      type: Object,
      required: true,
    },
    variants: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      variantScore: 'probability',
      variantToWin: null,
    }
  },
  computed: {
    showProbabilityScore() {
      return this.variantScore === 'probability'
    },
    showConfidenceScore() {
      return this.variantScore === 'confidence'
    },
  },
  methods: {
    computeVariantColorVariable(index) {
      const colors = Object.keys(chartColors)

      return `var(--${colors[index]})`
    },
    formatConfidenceLevel(variant) {
      if (variant.control) {
        return '—'
      }

      if (variant.confidence === 0.0) {
        return 'No Confidence'
      }

      return this.percentFormatter(variant.confidence)
    },
    setWinningVariant() {
      this.$inertia.post(this.route('experiments.variants.winner', [this.experiment.key, this.variantToWin.key]))

      this.variantToWin = null
    },
  },
}
</script>
