<template>
  <div>
    <Head :title="`${experiment.key} | Experiments`" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div v-if="experiment.variants.length && !experiment.is_draft" class="grid grid-cols-1 gap-8 md:grid-cols-2 mb-8">
        <TrendMetric class="w-full" title="Recent Enrollments" help-text="Showing the enrollments over the last 7 days." :endpoint="route('stats.experiments.variants', experiment.key)" />

        <TrendMetric class="w-full" title="Event Tracking" help-text="Breakdown of event hits per-variant." :endpoint="route('stats.experiments.events', experiment.key)" />
      </div>

      <Banner v-if="experiment.is_draft" class="mb-8" type="warning">This experiment is not running. It will not enroll new participants.</Banner>

      <FormSection>
        <template #title>Details</template>

        <template #description> Here you'll find the experiment's details. </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label>Key</Label>

            <Clipboard>{{ experiment.key }}</Clipboard>
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Status</Label>

            <ExperimentStatusBadge :experiment="experiment" />
          </div>

          <div v-if="experiment.is_stopped" class="col-span-6 sm:col-span-4">
            <Label>Date Stopped</Label>

            {{ dateTimeFormat(experiment.stopped_at) }}
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Primary Event Metric</Label>

            <div v-if="experiment.event" class="flex items-center space-x-2">
              <Clipboard>{{ experiment.event.key }}</Clipboard>
              <Link :href="route('events.show', experiment.event.key)" class="text-emerald-500">
                <LinkIcon class="size-5" />
              </Link>
            </div>
            <span v-else>&mdash;</span>
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>No. Participants Enrolled</Label>

            {{ numberFormat(experiment.participant_count) }}
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>API URL</Label>

            <Clipboard>{{ route('api.experiments.show', [experiment.team_id, experiment.key]) }}</Clipboard>
          </div>

          <div v-if="experiment.is_running" class="col-span-6 sm:col-span-4">
            <Label>Experiment Started</Label>

            {{ experiment.running_time }}
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Date Created</Label>

            {{ dateTimeFormat(experiment.created_at) }}
          </div>

          <div v-if="experiment.started_at" class="col-span-6 sm:col-span-4">
            <Label>Date Started</Label>

            {{ dateTimeFormat(experiment.started_at) }}
          </div>

          <div v-if="experiment.last_simulation_at" class="col-span-6 sm:col-span-4">
            <Label title="Further analyses will run every hour.">Last Analysis</Label>

            {{ dateTimeFormat(experiment.last_simulation_at) }}
          </div>

          <div v-if="experiment.stopped_at" class="col-span-6 sm:col-span-4">
            <Label>Date Stopped</Label>

            {{ dateTimeFormat(experiment.stopped_at) }}
          </div>
        </template>

        <template v-if="!experiment.is_stopped" #actions>
          <ActionMessage :on="Object.keys(experimentRunningForm.errors).length" class="mr-3">
            <InputError :message="experimentRunningForm.errors.experiment" />
          </ActionMessage>

          <DangerButton v-if="can.stop_experiment" @click="experimentToStop = experiment"> Stop Experiment </DangerButton>
          <Button v-if="can.start_experiment" :disabled="experiment.variants_count < 2" @click="startExperiment"> Start Experiment </Button>
          <SecondaryButton v-if="can.edit_experiment" class="ml-2" @click="$inertia.visit(route('experiments.edit', [experiment.key]))"> Edit </SecondaryButton>
        </template>
      </FormSection>

      <SectionBorder />

      <FormSection>
        <template #title>Description</template>

        <template #description> Including a description is a great way of explaining and communicating the experiment’s goal with your team. </template>

        <template #form>
          <div v-if="!descriptionForm.editing" class="prose prose-slate col-span-full text-slate-600" v-html="description" />
          <div v-else class="col-span-6 sm:col-span-4">
            <Label value="Description" />
            <Editor :initial-content="descriptionForm.description" @content-changed="handleEditorContentChange" />
          </div>
        </template>

        <template v-if="!experiment.is_draft" #actions>
          <SecondaryButton type="button" @click="toggleEditing">{{ descriptionForm.editing ? 'Cancel' : 'Edit' }}</SecondaryButton>
          <Button v-if="descriptionForm.editing" type="button" class="ml-2" :class="{ 'opacity-25': descriptionForm.processing }" :disabled="descriptionForm.processing" @click="saveDescription">Save</Button>
        </template>
      </FormSection>

      <SectionBorder />

      <WideFormSectionClear>
        <template #title>Variants</template>

        <template #description>
          <p>Variants are the different versions of your experiment. Each variant is a unique experience that you want to test. For example, if you are testing a new landing page, you might create a variant that has a different headline or call-to-action.</p>
          <p>Every experiment needs two variants before it can be started.</p>
          <p>
            Need more help? Check out the
            <a href="https://docs.checkmango.com/experiments/variants.html" target="_blank">documentation</a>.
          </p>
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4 mb-4">
            <Label value="Event" />

            <select v-model="event" class="block rounded-md border-slate-300 bg-white shadow-sm focus:border-slate-500 focus:outline-none focus:ring-slate-500">
              <option v-for="eventKey in events" :key="eventKey" :value="eventKey">{{ eventKey }}</option>
            </select>
          </div>

          <VariantTable :experiment="experiment" :can="can" />

          <Banner v-if="!experiment.is_draft" class="w-full mt-4">
            <p class="text-slate-600" v-html="result.description" />
          </Banner>
        </template>

        <template v-if="experiment.is_draft && experiment.variants.length && experiment.event" #actions>
          <Button class="ml-2" :disabled="!can.create_variant" @click="$inertia.visit(route('experiments.variants.create', [experiment.key]))"> Create Variant </Button>
        </template>
      </WideFormSectionClear>

      <SectionBorder />

      <DangerActionSection>
        <template #title>Danger Zone</template>

        <template #description>Dragons around, be careful.</template>

        <template #content>
          <div class="space-y-4">
            <div class="max-w-xl">
              <span v-if="experiment.is_running"> This experiment is currently running. You must stop it before it can be deleted. </span>
              <span v-if="experiment.is_stopped"> This experiment has been stopped and will no longer accept new participant enrollments or conversions. </span>
              <span v-if="can.reset_experiment">Resetting an experiment will wipe all existing enrollments and conversion data. This action cannot be reversed.</span>
              <span v-if="experiment.is_draft">Your experiment has not yet started, and can be deleted at any time.</span>
            </div>

            <div v-if="can.reset_experiment || !experiment.is_running" class="flex items-center gap-x-2">
              <SecondaryButton v-if="can.reset_experiment" @click="experimentToReset = experiment"> Reset Experiment </SecondaryButton>
              <DangerButton v-if="!experiment.is_running" :disabled="!can.delete_experiment" @click="experimentToDelete = experiment"> Delete Experiment </DangerButton>
            </div>
          </div>
        </template>
      </DangerActionSection>
    </div>
    <StopExperimentModal :experiment="experimentToStop" @close="experimentToStop = null" @confirm="stopExperiment" />
    <ResetExperimentModal :experiment="experimentToReset" @close="experimentToReset = null" @confirm="resetExperiment" />
    <DeleteExperimentModal :experiment="experimentToDelete" @close="experimentToDelete = null" @confirm="deleteExperiment" />
  </div>
</template>

<script>
import confetti from 'canvas-confetti'
import { Head, Link } from '@inertiajs/vue3'

import { LinkIcon } from '@heroicons/vue/24/outline'
import AppLayout from '@/Layouts/AppLayout.vue'
import Clipboard from '@/Components/Clipboard.vue'
import DeleteExperimentModal from '@/Modals/DeleteExperimentModal.vue'
import ExperimentStatusBadge from '@/Components/ExperimentStatusBadge.vue'
import ActionMessage from '@/Jetstream/ActionMessage.vue'
import Button from '@/Jetstream/Button.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import FormSection from '@/Jetstream/FormSection.vue'
import InputError from '@/Jetstream/InputError.vue'
import Label from '@/Jetstream/Label.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import SectionBorder from '@/Jetstream/SectionBorder.vue'
import ResetExperimentModal from '@/Modals/ResetExperimentModal.vue'
import StopExperimentModal from '@/Modals/StopExperimentModal.vue'
import TrendMetric from '@/Metrics/TrendMetric.vue'
import VariantTable from '@/Pages/Experiments/Tables/VariantTable.vue'
import DangerActionSection from '@/Jetstream/DangerActionSection.vue'
import Banner from '@/Components/Banner.vue'
import WideFormSectionClear from '@/Jetstream/WideFormSectionClear.vue'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Editor: defineAsyncComponent(() => import('@/Components/Editor.vue')),
    WideFormSectionClear,
    Banner,
    DangerActionSection,
    Clipboard,
    DeleteExperimentModal,
    ExperimentStatusBadge,
    LinkIcon,
    Head,
    ActionMessage,
    DangerButton,
    FormSection,
    InputError,
    Label,
    SecondaryButton,
    SectionBorder,
    Button,
    Link,
    ResetExperimentModal,
    StopExperimentModal,
    TrendMetric,
    VariantTable,
  },
  layout: [AppLayout],
  props: {
    can: Object,
    links: {
      type: Array,
      default: () => [],
    },
    experiment: {
      type: Object,
      default: () => {},
    },
    firstParticipant: {
      type: Object,
      default: () => {},
    },
    result: Object,
    confetti: Boolean,
    events: Object,
  },
  data() {
    return {
      experimentToReset: null,
      experimentToStop: null,
      experimentToDelete: null,
      experimentRunningForm: this.$inertia.form({
        started: this.experiment.started,
      }),

      descriptionForm: this.$inertia.form({
        editing: false,
        description: this.experiment.description,
      }),

      event: this.experiment.event.key,
    }
  },
  computed: {
    description() {
      return this.experiment.description || '&mdash;'
    },
  },
  watch: {
    event(val) {
      this.$inertia.reload({
        only: ['experiment'],
        preserveState: true,
        preserveScroll: true,
        data: {
          event_key: val,
        },
      })
    },
  },
  created() {
    if (this.confetti) {
      confetti({
        particleCount: 150,
        origin: { y: 0 },
        angle: -90,
        spread: 360,
        startVelocity: 30,
      })
    }
  },
  methods: {
    startExperiment() {
      this.experimentRunningForm.post(this.route('experiments.start', this.experiment.key), {
        preserveScroll: true,
      })
    },
    stopExperiment() {
      this.$inertia.post(this.route('experiments.stop', this.experimentToStop.key))

      this.experimentToStop = null
    },
    resetExperiment() {
      this.$inertia.post(this.route('experiments.reset', this.experimentToReset.key))

      this.experimentToReset = null
    },
    deleteExperiment() {
      this.$inertia.delete(this.route('experiments.destroy', this.experimentToDelete.key))

      this.experimentToDelete = null
    },
    toggleEditing() {
      this.descriptionForm.editing = !this.descriptionForm.editing
    },
    handleEditorContentChange(content) {
      this.descriptionForm.description = content
    },
    saveDescription() {
      this.descriptionForm.put(this.route('experiments.update', this.experiment.key), {
        preserveScroll: true,
        preserveState: true,
        only: ['experiment'],
        onSuccess: () => {
          this.descriptionForm.description = this.experiment.description
          this.descriptionForm.editing = false
        },
      })
    },
  },
}
</script>
