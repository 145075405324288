<template>
  <ActionSection>
    <template #title> Two Factor Authentication </template>

    <template #description> Add additional security to your account using two factor authentication. </template>

    <template #content>
      <h3 v-if="twoFactorEnabled" class="text-lg font-medium text-slate-900">You have enabled two factor authentication.</h3>

      <h3 v-else class="text-lg font-medium text-slate-900">You have not enabled two factor authentication.</h3>

      <div class="mt-3 max-w-xl text-slate-600">
        <p>When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.</p>
      </div>

      <div v-if="twoFactorEnabled">
        <div v-if="qrCode">
          <div class="mt-4 max-w-xl text-slate-600">
            <p class="font-semibold">Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application.</p>
          </div>

          <div class="mt-4" v-html="qrCode" />
        </div>

        <div v-if="recoveryCodes.length > 0">
          <div class="mt-4 max-w-xl text-slate-600">
            <p class="font-semibold">Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.</p>
          </div>

          <div class="mt-4 grid max-w-xl gap-1 rounded-lg bg-slate-100 px-4 py-4 font-mono text-sm">
            <div v-for="code in recoveryCodes" :key="code">
              {{ code }}
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <div v-if="!twoFactorEnabled">
          <ConfirmsPassword @confirmed="enableTwoFactorAuthentication">
            <Button type="button" :class="{ 'opacity-25': enabling }" :disabled="enabling"> Enable </Button>
          </ConfirmsPassword>
        </div>

        <div v-else>
          <ConfirmsPassword @confirmed="regenerateRecoveryCodes">
            <SecondaryButton v-if="recoveryCodes.length > 0" class="mr-3"> Regenerate Recovery Codes </SecondaryButton>
          </ConfirmsPassword>

          <ConfirmsPassword @confirmed="showRecoveryCodes">
            <SecondaryButton v-if="recoveryCodes.length === 0" class="mr-3"> Show Recovery Codes </SecondaryButton>
          </ConfirmsPassword>

          <ConfirmsPassword @confirmed="disableTwoFactorAuthentication">
            <DangerButton :class="{ 'opacity-25': disabling }" :disabled="disabling"> Disable </DangerButton>
          </ConfirmsPassword>
        </div>
      </div>
    </template>
  </ActionSection>
</template>

<script>
import { defineComponent } from 'vue'
import Axios from 'axios'
import ActionSection from '@/Jetstream/ActionSection.vue'
import Button from '@/Jetstream/Button.vue'
import ConfirmsPassword from '@/Jetstream/ConfirmsPassword.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'

export default defineComponent({
  components: {
    ActionSection,
    Button,
    ConfirmsPassword,
    DangerButton,
    SecondaryButton,
  },

  data() {
    return {
      enabling: false,
      disabling: false,

      qrCode: null,
      recoveryCodes: [],
    }
  },

  computed: {
    twoFactorEnabled() {
      return !this.enabling && this.$page.props.auth.user.two_factor_enabled
    },
  },

  methods: {
    enableTwoFactorAuthentication() {
      this.enabling = true

      this.$inertia.post(
        '/user/two-factor-authentication',
        {},
        {
          preserveScroll: true,
          onSuccess: () => Promise.all([this.showQrCode(), this.showRecoveryCodes()]),
          onFinish: () => (this.enabling = false),
        },
      )
    },

    showQrCode() {
      return Axios.get('/user/two-factor-qr-code').then((response) => {
        this.qrCode = response.data.svg
      })
    },

    showRecoveryCodes() {
      return Axios.get('/user/two-factor-recovery-codes').then((response) => {
        this.recoveryCodes = response.data
      })
    },

    regenerateRecoveryCodes() {
      Axios.post('/user/two-factor-recovery-codes').then(() => this.showRecoveryCodes())
    },

    disableTwoFactorAuthentication() {
      this.disabling = true

      this.$inertia.delete('/user/two-factor-authentication', {
        preserveScroll: true,
        onSuccess: () => (this.disabling = false),
      })
    },
  },
})
</script>
