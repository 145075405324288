<template>
  <div v-if="editor" class="editor">
    <MenuBar class="editor__header" :editor="editor" />
    <EditorContent class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import MenuBar from './MenuBar.vue'

export default {
  components: {
    EditorContent,
    MenuBar,
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    initialContent: {
      type: String,
      default: '',
    },
  },

  emits: ['contentChanged'],

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    initialContent(value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.initialContent, false)
    },
    readOnly() {
      this.editor.setEditable(!this.readOnly)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.initialContent,
      editable: !this.readOnly,
      editorProps: {
        attributes: {
          class: 'focus:outline-none prose text-slate-600',
        },
      },
      extensions: [StarterKit, Highlight, Typography],
      onUpdate: () => {
        this.$emit('contentChanged', this.editor.getHTML())
      },
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style>
@import 'https://cdn.jsdelivr.net/npm/remixicon@3.5.0/fonts/remixicon.css';

.editor {
  display: flex;
  flex-direction: column;
  max-height: 26rem;
  color: #0d0d0d;
  border-width: 1px;
  @apply rounded-md border-slate-300 bg-white shadow-sm;
}

.editor__header {
  @apply flex flex-wrap items-center border-b-2 border-slate-50 p-1;
}

.editor__content {
  padding: 1.25rem 1rem;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @apply rounded-md border-slate-300 bg-white shadow-sm focus:border-slate-500 focus:outline-none focus:ring-slate-500;
}

/* Basic editor styles */
.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  @apply text-sm;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror hr {
  margin: 1rem 0;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 4px solid rgba(13, 13, 13, 0.1);
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

[contenteditable='false'] {
  @apply cursor-not-allowed text-slate-800;
}
</style>
