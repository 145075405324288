<template>
  <div>
    <Head :title="`Edit ${experiment.key} | Experiments`" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <JetFormSection @submitted="editExperiment">
        <template #title> Experiment Details </template>

        <template #description>
          <p>An experiment tests a single, unique hypothesis by assigning random participants to each variant. Think of experiments as a ”test” or ”campaign” within your website or application.</p>
          <p>This could be the current implementation (known as the <em>"control"</em> variant) or a different version.</p>
          <p>For example, if you are testing whether a button color improves conversion you could call your experiment <code>ADD_TO_BASKET_BUTTON_COLOR</code></p>

          <p>
            Need more help? Check out the
            <a href="https://docs.checkmango.com/experiments/" target="_blank">documentation</a>.
          </p>
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <JetLabel for="key" value="Key" />

            <JetInput id="key" v-model="form.key" type="text" class="mt-1 block w-full" autofocus :disabled="!experiment.is_draft" :class="{ 'bg-slate-100': !experiment.is_draft }" autocomplete="off" />

            <JetInputError :message="form.errors.key" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <JetLabel for="description" value="Description" />

            <Editor id="description" :initial-content="form.description" @content-changed="handleEditorContentChange" />

            <JetInputError :message="form.errors.description" class="mt-2" />
          </div>

          <!--          <div class="col-span-6 sm:col-span-4">-->
          <!--            <jet-label for="event" value="Algorithm" title="The algorithm will determine how Checkmango enrolls users into variants." />-->

          <!--            <select id="algorithm" v-model="form.algorithm_type" :class="{ 'bg-slate-100': !experiment.is_draft }" :disabled="!experiment.is_draft" class="mt-1 block w-full pl-3 pr-10 py-2 border-slate-300 focus:outline-none focus:ring-slate-500 focus:border-slate-500 rounded-md">-->
          <!--              <option value="blockRandomization">Equal Distribution</option>-->
          <!--              <option value="weightedSample">Weighted Variants</option>-->
          <!--              <option value="whiplash">Best Performing</option>-->
          <!--            </select>-->

          <!--            <jet-label :value="algorithmDescription(form.algorithm_type)" class="mt-2" :sub-label="true" />-->

          <!--            <jet-input-error :message="form.errors.algorithm_type" class="mt-2" />-->
          <!--          </div>-->

          <div class="col-span-6 sm:col-span-4">
            <JetLabel for="event" value="Primary Event Metric" title="Checkmango will consider a variant converted when this event is triggered." />

            <select v-if="Object.keys(events).length" id="event" v-model="form.event_key" :class="{ 'bg-slate-100': !experiment.is_draft }" :disabled="!experiment.is_draft" class="block w-full rounded-md border-slate-300 bg-white shadow-sm focus:border-slate-500 focus:outline-none focus:ring-slate-500">
              <option selected />
              <option v-for="key in events" :key="key" :value="key" :selected="key === form.event_key">
                {{ key }}
              </option>
            </select>

            <LinkButton v-else :href="route('events.create', { viaResource: 'experiments.create' })"> + Create Event </LinkButton>

            <JetInputError :message="form.errors.event_key" class="mt-2" />
          </div>
        </template>

        <template #actions>
          <JetActionMessage :on="form.recentlySuccessful" class="mr-3"> Updated. </JetActionMessage>

          <JetSecondaryButton class="mr-2" @click="$inertia.visit(route('experiments.show', [experiment.key]))">Cancel</JetSecondaryButton>

          <JetButton v-if="experiment.is_draft" :class="{ 'opacity-25': form.processing }" class="ml-3" :disabled="form.processing"> Update </JetButton>
        </template>
      </JetFormSection>
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import LinkButton from '@/Components/LinkButton.vue'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Head,
    Editor: defineAsyncComponent(() => import('@/Components/Editor.vue')),
    JetFormSection,
    JetLabel,
    JetInput,
    JetInputError,
    JetActionMessage,
    JetButton,
    JetSecondaryButton,
    LinkButton,
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    experiment: {
      type: Object,
      default: () => {},
    },
    events: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        key: this.experiment.key,
        name: this.experiment.name,
        description: this.experiment.description,
        algorithm_type: this.experiment.algorithm_type,
        event_key: this.experiment.event_key,
      }),
    }
  },
  methods: {
    editExperiment() {
      this.form.put(this.route('experiments.update', this.experiment.key), {
        preserveScroll: true,
      })
    },
    handleEditorContentChange(content) {
      this.form.description = content
    },
  },
}
</script>
