<template>
  <component :is="is" :href="href" class="button" :class="classes">
    <slot />
  </component>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
  components: {
    Link,
  },
  props: {
    is: {
      type: String,
      default: 'Link',
    },
    href: {
      type: String,
    },
    type: {
      type: String,
      default: 'green',
    },
  },
  computed: {
    classes() {
      if (this.type === 'secondary') {
        return 'button--secondary'
      }

      if (this.type === 'danger') {
        return 'button--danger'
      }

      return 'button--success'
    },
  },
}
</script>
