<template>
  <JetFormSection @submitted="updateTeamCurrency()">
    <template #title> Location </template>

    <template #description> These settings are related to where your team is located in the world. </template>

    <template #form>
      <!-- Team Timezone -->
      <div class="col-span-6 sm:col-span-4">
        <JetLabel for="timezone" value="Team Timezone" />

        <select id="timezone" v-model="form.timezone" :disabled="!permissions.canUpdateTeam" class="mt-1 block w-full rounded-md border-slate-300 py-2 pl-3 pr-10 focus:border-slate-500 focus:outline-none focus:ring-slate-500">
          <option selected />
          <option v-for="(timezone, value) in timezones" :key="timezone" :value="value" :selected="value == form.timezone">
            {{ timezone }}
          </option>
        </select>

        <JetInputError :message="form.errors.currency" class="mt-2" />
      </div>
    </template>

    <template v-if="permissions.canUpdateTeam" #actions>
      <JetActionMessage :on="form.recentlySuccessful" class="mr-3"> Saved. </JetActionMessage>

      <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Save </JetButton>
    </template>
  </JetFormSection>
</template>

<script>
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetLabel from '@/Jetstream/Label.vue'

export default {
  components: {
    JetFormSection,
    JetInputError,
    JetLabel,
    JetButton,
    JetActionMessage,
  },
  props: {
    team: Object,
    permissions: Object,
    timezones: Array,
  },
  data() {
    return {
      form: this.$inertia.form({
        timezone: this.team.timezone,
      }),
    }
  },
  methods: {
    updateTeamCurrency() {
      this.form.put(this.route('teams.timezone.update', this.team), {
        errorBag: 'updateTeamTimezone',
        preserveScroll: true,
      })
    },
  },
}
</script>
