<template>
  <GuestLayout title="About">
    <section class="relative py-12 md:py-24">
      <div class="container">
        <article class="prose prose-lg">
          <p>Hey there!</p>
          <p>Checkmango is more than just a software platform. It's a robust full-stack A/B testing solution thoughtfully crafted for solo developers, agencies, and small to medium-sized businesses.</p>
          <p>We're building a full-stack A/B testing platform for solo-developers, agencies and small to medium businesses</p>
        </article>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 my-8">
          <div class="flex flex-col">
            <img src="../../Images/james-brooks.jpg" class="rounded-lg w-56 h-56 mb-2 aspect-square" alt="James Brooks" />
            <span class="font-semibold">James Brooks</span>
            <span class="text-gray-600 text-sm">Founder &amp; Developer</span>
          </div>
          <div class="flex flex-col">
            <img src="../../Images/katie-brooks.jpg" class="rounded-lg w-56 h-56 mb-2 aspect-square" alt="Katie Brooks" />
            <span class="font-semibold">Katie Brooks</span>
            <span class="text-gray-600 text-sm">Founder, Marketing &amp; Content</span>
          </div>
        </div>

        <article class="prose prose-lg">
          <p>We started working on Checkmango in 2020 after having spent several years working at an <abbr title="Online Travel Agency">OTA</abbr>, frustrated with the expensive and complicated options available at the time.</p>
          <p>In 2023, we proudly marked our official launch on <a href="https://producthunt.com/posts/checkmango">ProductHunt</a>, marking a huge milestone for us. During our launch week, we became #3 developer tool of the week.</p>
          <p>Checkmango is a bootstrapped, self-funded business.</p>
          <p>That's all for now! 👋</p>
        </article>
      </div>
    </section>
  </GuestLayout>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const GuestLayout = defineAsyncComponent(() => import('@/Layouts/GuestLayout.vue'))

export default {
  components: {
    GuestLayout,
  },
}
</script>
