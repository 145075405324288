<template>
  <div>
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div v-if="experiment.variants.length" class="bg-white shadow-xl sm:rounded-lg">
        <VariantsTable :experiment="experiment" :variants="experiment.variants" />
      </div>

      <Panel v-else>
        <template #title> Variants </template>

        <template #actions>
          <LinkButton :href="route('experiments.variants.create', [experiment.key])"> Create Variant </LinkButton>
        </template>

        <p>A variant represents one of the possible cases a participant will receive. Every experiment needs at least one variant.</p>
      </Panel>
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import LinkButton from '@/Components/LinkButton.vue'
import Panel from '@/Components/Panel.vue'
import VariantsTable from '@/Pages/Experiments/Variants/Table.vue'

export default {
  components: {
    LinkButton,
    VariantsTable,
    Panel,
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    experiment: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
