<template>
  <ActionSection>
    <template #title> Single Sign On </template>

    <template #description> Connect to your social platforms to make signing in quick, secure and easy. </template>

    <template #content>
      <div class="col-span-6 sm:col-span-4">
        <Label value="Connect To GitHub" />

        <div class="mt-2">
          <LoginWithGitHub v-if="!connectedToGitHub" :href="route('github.redirect', { from: 'profile' })" />
          <DangerButton v-else-if="hasAccountPassword" @click="disconnectGitHub">Disconnect from GitHub</DangerButton>
          <span v-else class="text-sm">You are connected to GitHub.</span>
        </div>
      </div>
    </template>
  </ActionSection>
</template>

<script>
import ActionSection from '@/Jetstream/ActionSection.vue'
import Label from '@/Jetstream/Label.vue'
import LoginWithGitHub from '@/Components/LoginWithGitHub.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'

export default {
  components: {
    DangerButton,
    ActionSection,
    Label,
    LoginWithGitHub,
  },
  props: {
    connectedToGitHub: Boolean,
    hasAccountPassword: Boolean,
  },
  methods: {
    disconnectGitHub() {
      this.$inertia.delete(this.route('profile.github.disconnect'))
    },
  },
}
</script>
