<template>
  <div>
    <Head title="Create Event" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <JetFormSection @submitted="createEvent">
        <template #title>Event Details</template>

        <template #description>
          <p>Events are used to notify {{ $page.props.app_name }} when a participant has performed an action such as <code>CHECKOUT_STARTED</code>.</p>
          <p>It is good practise to name your events in a format that matches the type of actions users may perform on your website or application. For example, <code>SIGN_UP</code>, <code>CREATE_PROJECT</code> or <code>ADD_TO_BASKET</code>.</p>
          <p>
            Need more help? Check out the
            <a href="https://docs.checkmango.com/events" target="_blank">documentation</a>.
          </p>
        </template>

        <template #form>
          <EventForm ref="eventForm" />
        </template>

        <template #actions>
          <JetSecondaryButton class="mr-2" @click="$inertia.visit(route('events'))">Cancel</JetSecondaryButton>

          <JetButton> Create </JetButton>
        </template>
      </JetFormSection>
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import EventForm from '@/Pages/Events/EventForm.vue'

export default {
  components: {
    Head,
    JetFormSection,
    JetButton,
    JetSecondaryButton,
    EventForm,
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    createEvent() {
      this.$refs.eventForm.createEvent()
    },
  },
}
</script>
