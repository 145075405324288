<script setup>
import { Link } from '@inertiajs/vue3'
import { inject } from 'vue'

const route = inject('route')

const props = defineProps({
  tier: Object,
  frequency: Object,
  marketing: {
    type: Boolean,
    default: false,
  },
})

const linkForPlan = (tier) => {
  const variantId = tier.price[props.frequency.value].variantId

  return route('register', { variantId })
}
</script>

<template>
  <div
    class="p-2"
    :class="{
      '-mx-2 rounded-lg bg-gradient-to-tr from-rose-500/50 to-yellow-500/50': tier.mostPopular,
    }"
  >
    <div
      :class="{
        'ring-white/10': !tier.mostPopular,
        'rounded-md shadow-rose-600/40 ring-white/5': tier.mostPopular,
      }"
      class="rounded-md bg-white p-6 shadow-xl ring-1 sm:mx-0"
    >
      <h3 :id="tier.id" :class="[tier.mostPopular ? 'text-emerald-500' : 'text-slate-900', 'text-xl font-semibold leading-8']">{{ tier.name }}</h3>
      <p class="mt-4 leading-6 text-slate-600">{{ tier.description }}</p>
      <p class="mt-4 flex items-baseline gap-x-1">
        <span class="text-2xl font-bold tracking-tight text-slate-900">${{ tier.price[frequency.value].price }}</span>
        <span class="text-sm font-semibold leading-6 text-slate-600">{{ frequency.priceSuffix }}</span>
      </p>
      <Link :href="linkForPlan(tier)" :aria-describedby="tier.id" :class="[tier.mostPopular ? 'bg-emerald-500 text-white shadow-sm hover:bg-emerald-500' : 'text-emerald-500 ring-1 ring-inset ring-emerald-200 hover:ring-emerald-300', 'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500']">Start Your Trial</Link>
    </div>
  </div>
</template>
