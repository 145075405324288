<template>
  <Modal :show="participant !== null" @close="closeModal" @opened="fillDefaultValues">
    <form @submit.prevent="createAttribute">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 w-full text-center sm:mx-4 sm:mt-0 sm:text-left">
            <h3 class="font-display text-lg font-semibold tracking-normal">{{ attribute ? 'Edit' : 'Create' }} Attribute</h3>

              <div class="mt-2 w-full space-y-6">
                <div class="col-span-6 sm:col-span-4">
                  <Label for="key" value="Key" />

                  <Input id="key" ref="attribute_key" v-model="form.key" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

                  <InputError :message="form.errors.key" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <Label for="value" value="Value" />

                  <Input id="value" v-model="form.value" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

                  <InputError :message="form.errors.value" class="mt-2" />
                </div>
              </div>
          </div>
        </div>
      </div>

      <div class="bg-slate-100 px-6 py-4 text-right">
        <SecondaryButton class="mr-2" @click="closeModal">Close</SecondaryButton>

        <Button type="submit" @click.prevent="createAttribute"> Create </Button>
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from '@/Jetstream/Modal.vue'
import Button from '@/Jetstream/Button.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import InputError from '@/Jetstream/InputError.vue'
import Input from '@/Jetstream/Input.vue'
import Label from '@/Jetstream/Label.vue'
export default {
  components: {
    Label,
    Input,
    InputError,
    Modal,
    Button,
    SecondaryButton,
  },
  props: {
    attribute: {
      type: Object,
      default: () => {},
    },
    participant: Object,
  },
  emits: ['close', 'attributeCreated'],
  data() {
    return {
      form: this.$inertia.form({
        key: '',
        value: '',
      }),
    }
  },
  methods: {
    fillDefaultValues() {
      console.log(this.attribute)
      this.form.key = this.attribute?.attribute?.key || ''
      this.form.value = this.attribute?.value || ''
      this.$refs.attribute_key.focus()
    },
    createAttribute() {
      this.form.post(this.route('participants.attributes.store', { participant: this.participant.key }), {
        preserveScroll: true,
        onSuccess: () => {
          this.$emit('attributeCreated', this.form.data)
          this.form.reset()
        },
      })
    },
    attributeCreated(attribute) {
      this.$emit('attributeCreated', attribute)
    },
    closeModal() {
      this.$emit('close')
      this.form.reset()
    },
  },
}
</script>
