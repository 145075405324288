<template>
  <div class="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
    <div class="flex justify-between">
      <Link v-if="data.current_page > 1" :href="buildUrl(data.path, { page: data.current_page - 1 })" class="relative inline-flex items-center rounded-md border border-slate-300 bg-white px-4 py-2 font-medium text-slate-700 hover:text-slate-500" preserve-scroll> Previous </Link>
    </div>
    <div class="flex">
      <p class="text-slate-700">
        Showing
        <span class="font-medium">{{ numberFormat(data.from) }}</span>
        &dash;
        <span class="font-medium">{{ numberFormat(data.to) }}</span>
        of
        <span class="font-medium">{{ numberFormat(data.total) }}</span>
        results
      </p>
    </div>
    <div class="flex justify-between">
      <Link v-if="data.current_page < data.last_page" :href="buildUrl(data.path, { page: data.current_page + 1 })" class="relative ml-3 inline-flex items-center rounded-md border border-slate-300 bg-white px-4 py-2 font-medium text-slate-700 hover:text-slate-500" preserve-scroll> Next </Link>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
  components: {
    Link,
  },
  props: {
    data: Object,
  },
  methods: {
    buildUrl(url, params) {
      let uri = new URLSearchParams(params)

      return `${url}?${uri.toString()}`
    },
  },
}
</script>
