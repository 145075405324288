<template>
  <LoadingCard :loading="loading" class="h-48 px-6 py-4">
    <div class="mb-4 flex">
      <h3 class="font-display text-lg font-semibold leading-6 text-slate-900">{{ title }}</h3>

      <!--      <div v-if="helpText" class="absolute right-0 bottom-0-b p-2 z-20">-->
      <!--        <tooltip trigger="click">-->
      <!--          <icon-->
      <!--            name="help"-->
      <!--            class="cursor-pointer text-slate-700 w-4 h-4 -mb-1"-->
      <!--          />-->

      <!--          <tooltip-content-->
      <!--            slot="content"-->
      <!--            v-html="helpText"-->
      <!--            :max-width="helpWidth"-->
      <!--          />-->
      <!--        </tooltip>-->
      <!--      </div>-->
    </div>

    <div class="absolute inset-0 top-[20%] rounded rounded-b-lg">
      <VueApexCharts type="area" height="100%" :options="chartOptions" :series="chartSeries" />
    </div>
  </LoadingCard>
</template>

<script>
import chartColors from '@/chartColors.js'
import LoadingCard from '@/Components/LoadingCard.vue'
import VueApexCharts from 'vue3-apexcharts'

export default {
  components: {
    LoadingCard,
    // Tooltip,
    // TooltipContent,
    // Icon,
    VueApexCharts,
  },

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    chartData: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    helpText: null,
    helpWidth: null,
  },

  data: () => ({
    chartOptions: {
      chart: {
        type: 'area',
        height: '100%',
        // zoom: {
        //   enabled: false,
        // },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      colors: Object.values(chartColors),
      fill: {
        opacity: 0.7,
        type: 'gradient',
      },
      stroke: {
        curve: 'smooth',
        opacity: 0.4,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'dd MMM y',
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  }),

  computed: {
    chartSeries() {
      return this.chartData
    },
  },
}
</script>
