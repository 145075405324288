<template>
  <div>
    <Head :title="`${event.key} | Events`" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="grid grid-cols-1 gap-8 md:grid-cols-3">
        <TrendMetric class="col-span-1 w-full lg:col-span-2" title="Recent Conversions" help-text="Showing the event conversions over the last 7 days." :endpoint="route('stats.events.conversions', event.key)" />

        <LoadingCard :loading="false" class="col-span-1 w-full px-6 py-4">
          <div class="mb-4 flex">
            <h3 class="font-display text-lg font-semibold leading-6 text-slate-900">Total Conversions</h3>
          </div>

          <div class="mb-4 text-2xl">
            <h3 class="text-2xl font-bold text-slate-600">
              {{ numberFormat(event.conversions_count) }}
            </h3>
          </div>
        </LoadingCard>

        <!--        <loading-card :loading="false" class="px-6 py-4 h-40 w-full">-->
        <!--          <div class="flex mb-4">-->
        <!--            <h3 class="text-lg leading-6 font-display font-semibold text-slate-900">Total Event Value</h3>-->
        <!--          </div>-->

        <!--          <div class="text-2xl mb-4">-->
        <!--            <h3 class="text-3xl font-bold text-slate-600">-->
        <!--              {{ currencyFormatter(event.conversions_sum_value || 0, $page.props.team.currency_code) }}-->
        <!--            </h3>-->
        <!--          </div>-->
        <!--        </loading-card>-->
      </div>

      <SectionBorder />

      <FormSection>
        <template #title>Details</template>

        <template #description>Here you'll find the event's details.</template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <JetLabel>Key</JetLabel>

            <Clipboard>{{ event.key }}</Clipboard>
          </div>

          <div class="col-span-6 sm:col-span-4">
            <JetLabel>Type</JetLabel>

            {{ event.displayable_type }}
          </div>

          <div class="col-span-6 sm:col-span-4">
            <JetLabel>Experiments Actively Tracking This Event</JetLabel>

            {{ numberFormat(event.running_experiments_count) }}
          </div>

          <div class="col-span-6 sm:col-span-4">
            <JetLabel>Date Created</JetLabel>

            {{ dateTimeFormat(event.created_at) }}
          </div>
        </template>

        <template v-if="can.edit_event" #actions>
          <SecondaryButton @click="$inertia.visit(route('events.edit', [event.key]))">Edit</SecondaryButton>
        </template>
      </FormSection>

      <SectionBorder />

      <FormSection>
        <template #title>Description</template>

        <template #description>Including a description is helpful to share why this event exists, when it's fired and what it represents.</template>

        <template #form>
          <div v-if="!descriptionForm.editing" class="prose prose-slate col-span-full text-slate-600" v-html="description" />
          <div v-else class="col-span-6 sm:col-span-4">
            <Label value="Description" />
            <Editor :initial-content="descriptionForm.description" @content-changed="handleEditorContentChange" />
          </div>
        </template>

        <template v-if="!event.is_draft" #actions>
          <SecondaryButton type="button" @click="toggleEditing">{{ descriptionForm.editing ? 'Cancel' : 'Edit' }}</SecondaryButton>
          <Button v-if="descriptionForm.editing" type="button" class="ml-2" :class="{ 'opacity-25': descriptionForm.processing }" :disabled="descriptionForm.processing" @click="saveDescription">Save</Button>
        </template>
      </FormSection>

      <SectionBorder />

      <DangerActionSection>
        <template #title>Danger Zone</template>

        <template #description>Dragons around, be careful.</template>

        <template #content>
          <div class="space-y-4">
            <div class="max-w-xl">
              <span v-if="!can.delete_event">This event cannot be deleted. It may be assigned to an experiment.</span>
            </div>

            <DangerButton :disabled="!can.delete_event" @click="eventToDelete = event">Delete Event</DangerButton>
          </div>
        </template>
      </DangerActionSection>
    </div>

    <DeleteEventModal :event="eventToDelete" @close="eventToDelete = null" @confirm="deleteEvent" />
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import Clipboard from '@/Components/Clipboard.vue'
import FormSection from '@/Jetstream/FormSection.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetLabel from '@/Jetstream/Label.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import SectionBorder from '@/Jetstream/SectionBorder.vue'
import TrendMetric from '@/Metrics/TrendMetric.vue'
import LoadingCard from '@/Components/LoadingCard.vue'
import DeleteEventModal from '@/Modals/DeleteEventModal.vue'
import DangerActionSection from '@/Jetstream/DangerActionSection.vue'
import Label from '@/Jetstream/Label.vue'
import Button from '@/Jetstream/Button.vue'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button,
    Label,
    Editor: defineAsyncComponent(() => import('@/Components/Editor.vue')),
    DangerActionSection,
    Head,
    Clipboard,
    FormSection,
    JetLabel,
    SecondaryButton,
    DangerButton,
    SectionBorder,
    TrendMetric,
    LoadingCard,
    DeleteEventModal,
  },
  layout: [AppLayout],
  props: {
    can: Object,
    links: {
      type: Array,
      default: () => [],
    },
    event: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      eventToDelete: null,

      descriptionForm: this.$inertia.form({
        editing: false,
        description: this.event.description,
      }),
    }
  },
  computed: {
    description() {
      return this.event.description || '&mdash;'
    },
  },
  methods: {
    deleteEvent() {
      this.$inertia.delete(this.route('events.destroy', this.eventToDelete.key))

      this.eventToDelete = null
    },
    toggleEditing() {
      this.descriptionForm.editing = !this.descriptionForm.editing
    },
    handleEditorContentChange(content) {
      this.descriptionForm.description = content
    },
    saveDescription() {
      this.descriptionForm.put(this.route('events.update', this.event.key), {
        preserveScroll: true,
        preserveState: true,
        only: ['event'],
        onSuccess: () => {
          this.descriptionForm.description = this.event.description
          this.descriptionForm.editing = false
        },
      })
    },
  },
}
</script>
