<template>
  <ConfirmationModal :show="attribute !== null" @close="$emit('close')">
    <template #title> Delete Attribute ({{ attribute?.attribute.key }})</template>

    <template #content>
      <p>Are you sure you want to delete this attribute? All data will be lost, and your results may be affected.</p>

      <p class="font-semibold">This action cannot be undone and data will be lost.</p>
    </template>

    <template #footer>
      <SecondaryButton @click="$emit('close')"> Never mind </SecondaryButton>

      <DangerButton class="ml-2" @click="$emit('confirm')"> Delete Attribute </DangerButton>
    </template>
  </ConfirmationModal>
</template>

<script>
import ConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'

export default {
  components: {
    ConfirmationModal,
    SecondaryButton,
    DangerButton,
  },
  props: {
    attribute: {
      type: Object,
      required: false,
    },
  },
  emits: ['close', 'confirm'],
}
</script>
