<template>
  <FormSection @submitted="updateTeamName">
    <template #title> Team Information </template>

    <template #description> Here you may change the name of your team. We'd also love to know a little more about who's using Checkmango. </template>

    <template #form>
      <!-- Team ID -->
      <div class="col-span-6">
        <Label for="id" value="Team ID" title="You will need the team's ID to use the Checkmango API." />

        <Clipboard>{{ team.id }}</Clipboard>
      </div>

      <!-- Team Owner Information -->
      <div class="col-span-6">
        <Label value="Team Owner" />

        <div class="mt-2 flex items-center">
          <img class="h-12 w-12 rounded-full object-cover" :src="team.owner.profile_photo_url" :alt="team.owner.name" />

          <div class="ml-4 leading-tight">
            <div>{{ team.owner.name }}</div>
            <div class="text-slate-700">{{ team.owner.email }}</div>
          </div>
        </div>
      </div>

      <!-- Team Name -->
      <div class="col-span-6 sm:col-span-4">
        <Label for="name" value="Team Name" />

        <Input id="name" v-model="form.name" type="text" class="mt-1 block w-full" :disabled="!permissions.canUpdateTeam" />

        <InputError :message="form.errors.name" class="mt-2" />
      </div>

      <!-- Industry -->
      <div class="col-span-6 sm:col-span-4">
        <Label for="industry" value="Industry" />

        <select id="industry" v-model="form.industry" class="mt-2 block w-full rounded-md border-slate-300 py-2 pl-3 pr-10 text-base focus:border-slate-500 focus:outline-none focus:ring-slate-500 sm:text-sm" :disabled="!permissions.canUpdateTeam">
          <option disabled>Select Your Industry</option>
          <option />
          <option v-for="industry in industries" :key="industry">{{ industry }}</option>
        </select>
      </div>

      <!-- Company Size -->
      <div class="col-span-6 sm:col-span-4">
        <Label for="company_size" value="Company Size" />

        <select id="company_size" v-model="form.company_size" class="mt-2 block w-full rounded-md border-slate-300 py-2 pl-3 pr-10 text-base focus:border-slate-500 focus:outline-none focus:ring-slate-500 sm:text-sm" :disabled="!permissions.canUpdateTeam">
          <option disabled>How many employees are in your company?</option>
          <option />
          <option v-for="size in companySizes" :key="`company-${size}`">{{ size }}</option>
        </select>
      </div>
    </template>

    <template v-if="permissions.canUpdateTeam" #actions>
      <ActionMessage :on="form.recentlySuccessful" class="mr-3"> Saved. </ActionMessage>

      <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Save </Button>
    </template>
  </FormSection>
</template>

<script>
import ActionMessage from '@/Jetstream/ActionMessage.vue'
import Button from '@/Jetstream/Button.vue'
import FormSection from '@/Jetstream/FormSection.vue'
import Input from '@/Jetstream/Input.vue'
import InputError from '@/Jetstream/InputError.vue'
import Label from '@/Jetstream/Label.vue'
import Clipboard from '@/Components/Clipboard.vue'

export default {
  components: {
    Clipboard,
    ActionMessage,
    Button,
    FormSection,
    Input,
    InputError,
    Label,
  },

  props: {
    team: Object,
    industries: Array,
    permissions: Object,
  },

  data() {
    return {
      form: this.$inertia.form({
        name: this.team.name,
        industry: this.team.industry,
        company_size: this.team.company_size,
      }),
      companySizes: ['1-10', '11-50', '51-200', '201-500', '501-999', '1000+'],
    }
  },

  methods: {
    updateTeamName() {
      this.form.put(this.route('teams.update', this.team), {
        errorBag: 'updateTeamName',
        preserveScroll: true,
      })
    },
  },
}
</script>
