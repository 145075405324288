<template>
  <button v-tippy="{ content: title }" type="button" class="menu-item" :class="{ 'is-active': isActive ? isActive() : null }" @click="action">
    <i :class="`ri-${icon}`" />
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    action: {
      type: Function,
      required: true,
    },

    isActive: {
      type: Function,
      default: null,
    },
  },
}
</script>

<style>
.menu-item {
  width: 1.75rem;
  height: 1.75rem;
  color: #0d0d0d;
  border: none;
  background-color: transparent;
  border-radius: 0.4rem;
  padding: 0.25rem;
  margin-right: 0.25rem;
}
.menu-item i {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.menu-item.is-active,
.menu-item:hover {
  @apply text-white;
  @apply bg-blue-500;
}
</style>
