<script setup>
import Container from '@/Components/Container.vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

import screenshotTeams from '@/Images/screenshot-teams.png'
import screenshotExperiments from '@/Images/screenshot-experiments.png'
import screenshotEvents from '@/Images/screenshot-events.png'
import screenshotParticipants from '@/Images/screenshot-participants.png'
import { ref } from 'vue'

const features = [
  {
    title: 'Experiments',
    description: 'With Checkmango\'s easy to use dashboard, you can see how your experiments are performing at a glance.',
    image: screenshotExperiments,
  },
  {
    title: 'Events',
    description: 'Easily see how your events (conversion goals) are performing when running experiments.',
    image: screenshotEvents,
  },
  {
    title: 'Participants',
    description: 'View the participants of your experiments and follow their journey to conversions.',
    image: screenshotParticipants,
  },
  {
    title: 'Bring Your Teammates Along',
    description: 'Give your team the power to make data-driven decisions with Checkmango\'s team management features.',
    image: screenshotTeams,
  },
]

const tabOrientation = ref('horizontal')
const setTabOrientation = (orientation) => {
  tabOrientation.value = orientation
}

let lgMediaQuery = window.matchMedia('(min-width: 1024px)')

function onMediaQueryChange({ matches }) {
  setTabOrientation(matches ? 'vertical' : 'horizontal')
}

onMediaQueryChange(lgMediaQuery)
lgMediaQuery.addEventListener('change', onMediaQueryChange)
</script>

<template>
  <section id="overview" aria-label="Checkmango Overview" class="relative overflow-hidden bg-gradient-to-br from-emerald-500 via-emerald-600 to-emerald-700 pb-28 pt-20 sm:py-32">
    <Container class="relative">
      <div class="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
        <h2 class="marketing-headline text-white">Take a look around.</h2>
        <p class="marketing-heading mt-6 text-white">Stop making decisions based on gut feelings and use Checkmango to make data-driven decisions.</p>
      </div>
      <TabGroup v-slot="{ selectedIndex }" as="div" class="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0" :vertical="tabOrientation === 'vertical'">
        <div class="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
          <TabList class="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
            <div v-for="(feature, featureIndex) in features" :key="`feature-{featureIndex}`">
              <div :key="feature.title" class="group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6 prose" :class="selectedIndex === featureIndex ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10' : 'hover:bg-white/10 lg:hover:bg-white/5'">
                <h3>
                  <Tab class="font-display text-lg [&:not(:focus-visible)]:focus:outline-none" :class="selectedIndex === featureIndex ? 'text-emerald-600 lg:text-white' : 'text-emerald-100 hover:text-white lg:text-white'">
                    <span class="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                    {{ feature.title }}
                  </Tab>
                </h3>
                <p class="mt-2 hidden lg:block" :class="selectedIndex === featureIndex ? 'text-white' : 'text-emerald-100 group-hover:text-white'">
                  {{ feature.description }}
                </p>
              </div>
            </div>
          </TabList>
        </div>
        <TabPanels class="lg:col-span-7">
          <TabPanel v-for="(feature, featureIndex) in features" :key="`feature-${featureIndex}-panel`" :unmount="false">
            <div class="relative sm:px-6 lg:hidden">
              <div class="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
              <p class="marketing-text relative mx-auto max-w-2xl text-white sm:text-center">
                {{ feature.description }}
              </p>
            </div>
            <div class="mt-10 w-[45rem] overflow-hidden rounded-xl sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
              <img class="w-full" :src="feature.image" alt="" sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem" />
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </Container>
  </section>
</template>
