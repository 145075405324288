<template>
  <div class="md:grid md:grid-cols-8 md:gap-6">
    <SectionTitle>
      <template #title>
        <span class="font-display tracking-normal"><slot name="title" /></span>
      </template>
      <template #description>
        <div class="prose prose-sm prose-slate break-words">
          <slot name="description" />
        </div>
      </template>
    </SectionTitle>

    <div class="mt-5 md:col-span-6 md:mt-0">
      <form @submit.prevent="$emit('submitted')">
        <div class="overflow-hidden shadow-sm ring-1 ring-slate-900/5 sm:rounded-xl">
          <div class="bg-white px-4 py-5 sm:p-5">
            <div class="grid grid-cols-5 gap-4 overflow-auto p-1">
              <slot name="form" />
            </div>
          </div>

          <div v-if="$slots.actions" class="flex items-center justify-end bg-white px-4 py-3 text-right sm:px-6">
            <slot name="actions" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SectionTitle from './SectionTitle.vue'

export default {
  components: {
    SectionTitle,
  },

  emits: ['submitted'],
}
</script>
