<template>
  <AuthenticationCard>
    <Head title="Reset Your Password" />

    <template #logo>
      <div class="flex flex-row items-center space-x-2">
        <div class="mt-6 font-display text-xl font-bold tracking-tight text-slate-700 md:text-3xl">Forgot your password?</div>
      </div>
    </template>

    <Banner v-if="sessionExpired" type="danger">Your Checkmango session has expired. Please try again.</Banner>

    <ValidationErrors class="mb-4" />

    <div v-if="status" class="mb-4 font-medium text-emerald-600">
      {{ status }}
    </div>

    <form @submit.prevent="submit">
      <div class="mb-4 max-w-2xl text-slate-600">Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.</div>

      <div>
        <Label for="email" value="Email" />
        <Input id="email" v-model="form.email" type="email" class="mt-1 block w-full" required autofocus />
      </div>

      <div class="mt-4 flex items-center justify-end">
        <Link :href="route('login')" class="mr-4 text-slate-600 underline hover:text-slate-900"> I Remembered My Password </Link>

        <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Email Password Reset Link </Button>
      </div>
    </form>
  </AuthenticationCard>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3'
import AuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
import Button from '@/Jetstream/Button.vue'
import Input from '@/Jetstream/Input.vue'
import Label from '@/Jetstream/Label.vue'
import ValidationErrors from '@/Jetstream/ValidationErrors.vue'
import AuthenticationLayout from '@/Layouts/AuthenticationLayout.vue'
import Banner from '@/Jetstream/Banner.vue'

export default {
  components: {
    Banner,
    Head,
    Link,
    AuthenticationCard,
    Button,
    Input,
    Label,
    ValidationErrors,
  },

  layout: [AuthenticationLayout],

  props: {
    status: String,
    sessionExpired: Boolean,
  },

  data() {
    return {
      form: this.$inertia.form({
        email: '',
      }),
    }
  },

  methods: {
    submit() {
      this.form.post(this.route('password.email'))
    },
  },
}
</script>
