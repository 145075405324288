<template>
  <div>
    <Head title="Create Feature" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <FormSection @submitted="createFeature">
        <template #title>Feature Details</template>

        <template #description>
          <p>Features are used to enable or disable functionality throughout the application.</p>
          <p>
            Need more help? Check out the
            <a href="https://docs.checkmango.com/features" target="_blank">documentation</a>.
          </p>
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label for="key" value="Key" />

            <Input id="key" v-model="form.key" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

            <InputError :message="form.errors.key" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="description" value="Description" />

            <Editor id="description" :initial-content="form.description" @content-changed="handleEditorContentChange" />

            <InputError :message="form.errors.description" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label for="value" value="Value" />

            <Input id="value" v-model="form.value" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

            <InputError :message="form.errors.value" class="mt-2" />
          </div>

          <div class="col-span-5 sm:col-span-4">
            <ul class="divide-y divide-slate-200">
              <li class="flex flex-wrap items-center justify-between pb-4">
                <div class="flex flex-col">
                  <Label for="control" value="Enabled" />
                  <p class="text-slate-500">Should the feature be enabled by default?</p>
                  <InputError :message="form.errors.enabled" />
                </div>
                <div class="flex">
                  <Toggle off-text="No" on-text="Yes" :value="form.enabled" class="mt-1" @toggled="form.enabled = !form.enabled" />
                </div>
              </li>
            </ul>
          </div>
        </template>

        <template #actions>
          <SecondaryButton class="mr-2" @click="$inertia.visit(route('features.index'))">Cancel</SecondaryButton>

          <Button> Create </Button>
        </template>
      </FormSection>
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import FormSection from '@/Jetstream/FormSection.vue'
import Button from '@/Jetstream/Button.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import Label from '@/Jetstream/Label.vue'
import Input from '@/Jetstream/Input.vue'
import Editor from '@/Components/Editor.vue'
import Toggle from '@/Components/Toggle.vue'
import InputError from '@/Jetstream/InputError.vue'

export default {
  components: {
    InputError,
    Toggle,
    Editor,
    Input,
    Label,
    Head,
    FormSection,
    Button,
    SecondaryButton,
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        key: '',
        description: '',
        value: null,
        enabled: true,
      }),
    }
  },
  methods: {
    handleEditorContentChange(content) {
      this.form.description = content
    },
    createFeature() {
      this.form.post(this.route('features.store'), {
        preserveScroll: true,
      })
    },
  },
}
</script>
