<template>
  <div class="flow-root">
    <ul role="list">
      <li v-for="(step, stepIdx) in steps" :key="step.identifier">
        <div class="relative pb-8" :class="[(stepIsComplete(step) || !stepIsActive(step)) && 'opacity-40']">
          <span v-if="stepIdx !== steps.length - 1" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-slate-100" aria-hidden="true" />

          <div class="relative flex space-x-3">
            <div>
              <span :class="[stepIconBackground(step), 'flex size-8 items-center justify-center rounded-full ring-8 ring-white']">
                <component :is="stepIcon(step)" class="size-5 text-white" aria-hidden="true" />
              </span>
            </div>
            <component :is="stepComponent(step)" v-bind="stepAttributes(step)" class="flex min-w-0 flex-1 justify-between space-x-4 pb-1.5">
              <div>
                <h2 class="font-semibold text-slate-700">{{ step.title }}</h2>
                <p class="text-sm text-slate-500">
                  {{ step.headline }}
                </p>
              </div>
              <div v-if="!stepIsComplete(step)" class="flex items-center gap-x-2 text-sm font-medium text-slate-700 md:text-base">
                <span>{{ step.cta }}</span>
                <span class="hidden md:block" aria-hidden="true">&rarr;</span>
              </div>
              <div v-else class="flex items-center gap-x-2 text-sm font-medium text-slate-700 md:text-base">
                <span>Completed</span>
                <span class="hidden md:block" aria-hidden="true">&checkmark;</span>
              </div>
            </component>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { CheckIcon, EllipsisHorizontalIcon, CreditCardIcon } from '@heroicons/vue/16/solid'
import { Link } from '@inertiajs/vue3'

export default {
  components: {
    CheckIcon,
    EllipsisHorizontalIcon,
    Link,
  },
  props: {
    steps: Array,
    activeStep: Number,
  },
  methods: {
    stepIcon(step) {
      if (this.stepIsComplete(step)) {
        return CheckIcon
      }

      if (step.identifier === 'subscription') {
        return CreditCardIcon
      }

      return EllipsisHorizontalIcon
    },
    stepIconBackground(step) {
      if (this.stepIsComplete(step)) {
        return 'bg-emerald-400'
      }

      if (this.stepIsActive(step)) {
        return 'bg-slate-400'
      }

      return 'bg-rose-400'
    },
    stepIsComplete(step) {
      return step.complete
    },
    stepIsActive(step) {
      return step.step === this.activeStep
    },
    stepComponent(step) {
      if (this.stepIsComplete(step)) {
        return 'div'
      }

      return 'Link'
    },
    stepAttributes(step) {
      return {
        href: this.stepLink(step),
      }
    },
    stepLink(step) {
      if (step.complete) {
        return
      }

      switch (step.identifier) {
      case 'subscription':
        return this.route('teams.billing.index', this.$page.props.user.current_team)
      case 'team':
        return this.route('teams.show', this.$page.props.auth.user.user.current_team)
      case 'events':
        return this.route('events.create')
      case 'experiments':
        return this.route('experiments.create')
      case 'variants':
        return this.route('experiments')
      case 'start':
        return this.route('experiments', { 'filter[status]': 'draft' })
      }
    },
  },
}
</script>
