<template>
  <Badge v-if="experiment.is_stopped" v-tippy :content="'Experiment stopped: ' + dateTimeFormat(experiment.stopped_at)" :type="type">
    <template #icon>
      <StopIcon class="size-4" />
    </template>

    {{ label }}
  </Badge>
  <Badge v-else :type="type">
    <template #icon>
      <PlayIcon v-if="experiment.is_running" class="size-4 animate-pulse" />
      <PencilIcon v-else-if="experiment.is_draft" class="h-3 w-3" />
    </template>

    {{ label }}
  </Badge>
</template>

<script>
import Badge from '@/Components/Badge.vue'
import { StopIcon, PencilIcon, PlayIcon } from '@heroicons/vue/16/solid'

export default {
  components: {
    Badge,
    PencilIcon,
    PlayIcon,
    StopIcon,
  },
  props: {
    experiment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    label() {
      return this.experiment.displayable_status
    },
    type() {
      if (this.experiment.is_draft) {
        return 'default'
      }

      if (this.experiment.is_running) {
        return 'info'
      }

      if (this.experiment.deleted_at) {
        return 'warning'
      }

      return 'error'
    },
  },
}
</script>
