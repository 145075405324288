<template>
  <label class="mb-1 block font-display text-sm leading-4 tracking-wide" :class="textSize">
    <span v-if="value" class="flex items-center">
      {{ value }}
      <QuestionMarkCircleIcon v-show="hasTitle" v-tippy="{ content: title }" class="ml-2 size-4 cursor-help" />
    </span>
    <span v-else class="flex items-center">
      <slot />
      <QuestionMarkCircleIcon v-show="hasTitle" v-tippy="{ content: title }" class="ml-2 size-4 cursor-help" />
    </span>
  </label>
</template>

<script>
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    QuestionMarkCircleIcon,
  },
  inheritAttrs: true,
  props: {
    value: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasTitle() {
      return !!this.title
    },
    textSize() {
      return !this.subLabel ? 'font-semibold text-slate-900' : 'text-xs text-slate-500'
    },
  },
}
</script>
