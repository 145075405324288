<template>
  <JetConfirmationModal :show="event !== null" @close="$emit('close')">
    <template #title> Delete Event </template>

    <template #content>
      <p>Are you sure you want to delete this event? All data will be lost, and your results may be affected.</p>

      <p class="font-semibold">This action cannot be undone and data will be lost.</p>
    </template>

    <template #footer>
      <JetSecondaryButton @click="$emit('close')"> Never mind </JetSecondaryButton>

      <JetDangerButton class="ml-2" @click="$emit('confirm')"> Delete Event </JetDangerButton>
    </template>
  </JetConfirmationModal>
</template>

<script>
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'

export default {
  components: {
    JetConfirmationModal,
    JetSecondaryButton,
    JetDangerButton,
  },
  props: {
    event: {
      type: Object,
      required: false,
    },
  },
  emits: ['close', 'confirm'],
}
</script>
