<template>
  <div>
    <!-- Generate API Token -->
    <JetFormSection @submitted="createApiToken">
      <template #title> Create API Token </template>

      <template #description> API tokens allow third-party services to authenticate with our application on your behalf. </template>

      <template #form>
        <!-- Token Name -->
        <div class="col-span-6 sm:col-span-4">
          <JetLabel for="name" value="Name" />
          <JetInput id="name" v-model="createApiTokenForm.name" type="text" class="mt-1 block w-full" autofocus />
          <JetInputError :message="createApiTokenForm.errors.name" class="mt-2" />
        </div>

        <!-- Token Permissions -->
        <div v-if="availablePermissions.length > 0" class="col-span-6">
          <JetLabel for="permissions" value="Permissions" />

          <div class="mt-2 grid grid-cols-1 gap-4 md:grid-cols-2">
            <div v-for="permission in availablePermissions" :key="permission">
              <label class="flex items-center">
                <JetCheckbox v-model:checked="createApiTokenForm.permissions" :value="permission" />
                <span class="ml-2 text-slate-600">{{ permission }}</span>
              </label>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <JetActionMessage :on="createApiTokenForm.recentlySuccessful" class="mr-3"> Created. </JetActionMessage>

        <JetButton :class="{ 'opacity-25': createApiTokenForm.processing }" :disabled="createApiTokenForm.processing"> Create </JetButton>
      </template>
    </JetFormSection>

    <div v-if="tokens.length > 0">
      <JetSectionBorder />

      <!-- Manage API Tokens -->
      <div class="mt-10 sm:mt-0">
        <JetActionSection>
          <template #title> Manage API Tokens </template>

          <template #description> You may delete any of your existing tokens if they are no longer needed. </template>

          <!-- API Token List -->
          <template #content>
            <div class="space-y-6">
              <div v-for="token in tokens" :key="token.id" class="flex items-center justify-between">
                <div>
                  {{ token.name }}
                </div>

                <div class="flex items-center">
                  <div v-if="token.last_used_ago" class="text-slate-400">Last used {{ token.last_used_ago }}</div>

                  <button v-if="availablePermissions.length > 0" class="ml-6 cursor-pointer text-slate-400 underline" @click="manageApiTokenPermissions(token)">Permissions</button>

                  <button class="ml-6 cursor-pointer text-red-500" @click="confirmApiTokenDeletion(token)">Delete</button>
                </div>
              </div>
            </div>
          </template>
        </JetActionSection>
      </div>
    </div>

    <!-- Token Value Modal -->
    <JetDialogModal :show="displayingToken" @close="displayingToken = false">
      <template #title> API Token </template>

      <template #content>
        <div>Please copy your new API token. For your security, it won't be shown again.</div>

        <div v-if="$page.props.jetstream.flash.token" class="mt-4 rounded bg-slate-100 px-4 py-2 font-mono text-slate-500">
          {{ $page.props.jetstream.flash.token }}
        </div>
      </template>

      <template #footer>
        <JetSecondaryButton @click="displayingToken = false"> Close </JetSecondaryButton>
      </template>
    </JetDialogModal>

    <!-- API Token Permissions Modal -->
    <JetDialogModal :show="managingPermissionsFor" @close="managingPermissionsFor = null">
      <template #title> API Token Permissions </template>

      <template #content>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div v-for="permission in availablePermissions" :key="permission">
            <label class="flex items-center">
              <JetCheckbox v-model:checked="updateApiTokenForm.permissions" :value="permission" />
              <span class="ml-2 text-slate-600">{{ permission }}</span>
            </label>
          </div>
        </div>
      </template>

      <template #footer>
        <JetSecondaryButton @click="managingPermissionsFor = null"> Cancel </JetSecondaryButton>

        <JetButton class="ml-3" :class="{ 'opacity-25': updateApiTokenForm.processing }" :disabled="updateApiTokenForm.processing" @click="updateApiToken"> Save </JetButton>
      </template>
    </JetDialogModal>

    <!-- Delete Token Confirmation Modal -->
    <JetConfirmationModal :show="apiTokenBeingDeleted" @close="apiTokenBeingDeleted = null">
      <template #title> Delete API Token </template>

      <template #content> Are you sure you would like to delete this API token? </template>

      <template #footer>
        <JetSecondaryButton @click="apiTokenBeingDeleted = null"> Cancel </JetSecondaryButton>

        <JetDangerButton class="ml-3" :class="{ 'opacity-25': deleteApiTokenForm.processing }" :disabled="deleteApiTokenForm.processing" @click="deleteApiToken"> Delete </JetDangerButton>
      </template>
    </JetConfirmationModal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import JetActionSection from '@/Jetstream/ActionSection.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import JetDialogModal from '@/Jetstream/DialogModal.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetSectionBorder from '@/Jetstream/SectionBorder.vue'

export default defineComponent({
  components: {
    JetActionMessage,
    JetActionSection,
    JetButton,
    JetConfirmationModal,
    JetDangerButton,
    JetDialogModal,
    JetFormSection,
    JetInput,
    JetCheckbox,
    JetInputError,
    JetLabel,
    JetSecondaryButton,
    JetSectionBorder,
  },

  props: {
    tokens: Array,
    availablePermissions: Array,
    defaultPermissions: Array,
  },

  data() {
    return {
      createApiTokenForm: this.$inertia.form({
        name: '',
        permissions: this.defaultPermissions,
      }),

      updateApiTokenForm: this.$inertia.form({
        permissions: [],
      }),

      deleteApiTokenForm: this.$inertia.form({}),

      displayingToken: false,
      managingPermissionsFor: null,
      apiTokenBeingDeleted: null,
    }
  },

  methods: {
    createApiToken() {
      this.createApiTokenForm.post(this.route('api-tokens.store'), {
        preserveScroll: true,
        onSuccess: () => {
          this.displayingToken = true
          this.createApiTokenForm.reset()
        },
      })
    },

    manageApiTokenPermissions(token) {
      this.updateApiTokenForm.permissions = token.abilities

      this.managingPermissionsFor = token
    },

    updateApiToken() {
      this.updateApiTokenForm.put(this.route('api-tokens.update', this.managingPermissionsFor), {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => (this.managingPermissionsFor = null),
      })
    },

    confirmApiTokenDeletion(token) {
      this.apiTokenBeingDeleted = token
    },

    deleteApiToken() {
      this.deleteApiTokenForm.delete(this.route('api-tokens.destroy', this.apiTokenBeingDeleted), {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => (this.apiTokenBeingDeleted = null),
      })
    },
  },
})
</script>
