<template>
  <svg width="236" height="60" viewBox="0 0 236 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M223.239 46V14.08H230.919V46H223.239ZM218.727 27.232V20.512H235.863V27.232H218.727Z" fill="#141414" />
    <path d="M205.146 46.48C202.33 46.48 199.882 45.92 197.802 44.8C195.722 43.648 194.106 42.096 192.954 40.144C191.802 38.16 191.226 35.904 191.226 33.376C191.226 31.424 191.546 29.632 192.186 28C192.826 26.368 193.722 24.96 194.874 23.776C196.026 22.56 197.386 21.632 198.954 20.992C200.554 20.32 202.298 19.984 204.186 19.984C205.978 19.984 207.61 20.304 209.082 20.944C210.586 21.584 211.882 22.48 212.97 23.632C214.058 24.784 214.89 26.144 215.466 27.712C216.042 29.28 216.298 30.992 216.234 32.848L216.186 34.912H195.882L194.778 30.592H209.946L209.13 31.504V30.544C209.066 29.744 208.81 29.04 208.362 28.432C207.946 27.792 207.386 27.296 206.682 26.944C205.978 26.592 205.178 26.416 204.282 26.416C203.034 26.416 201.962 26.672 201.066 27.184C200.202 27.664 199.546 28.384 199.098 29.344C198.65 30.272 198.426 31.424 198.426 32.8C198.426 34.208 198.714 35.44 199.29 36.496C199.898 37.52 200.762 38.32 201.882 38.896C203.034 39.472 204.394 39.76 205.962 39.76C207.05 39.76 208.01 39.6 208.842 39.28C209.706 38.96 210.634 38.416 211.626 37.648L215.226 42.736C214.234 43.6 213.178 44.304 212.058 44.848C210.938 45.392 209.786 45.792 208.602 46.048C207.45 46.336 206.298 46.48 205.146 46.48Z" fill="#141414" />
    <path d="M162.533 46V10.48H170.069V25.696L168.533 26.272C168.885 25.12 169.509 24.08 170.405 23.152C171.333 22.192 172.437 21.424 173.717 20.848C174.997 20.272 176.341 19.984 177.749 19.984C179.669 19.984 181.285 20.384 182.597 21.184C183.909 21.952 184.901 23.12 185.573 24.688C186.245 26.224 186.581 28.112 186.581 30.352V46H178.853V30.976C178.853 29.952 178.709 29.104 178.421 28.432C178.133 27.76 177.685 27.264 177.077 26.944C176.501 26.592 175.781 26.432 174.917 26.464C174.245 26.464 173.621 26.576 173.045 26.8C172.469 26.992 171.973 27.296 171.557 27.712C171.141 28.096 170.805 28.544 170.549 29.056C170.325 29.568 170.213 30.128 170.213 30.736V46H166.421C165.525 46 164.757 46 164.117 46C163.477 46 162.949 46 162.533 46Z" fill="#141414" />
    <path d="M147.561 46.48C145.097 46.48 142.889 45.904 140.937 44.752C138.985 43.6 137.433 42.032 136.281 40.048C135.161 38.032 134.601 35.76 134.601 33.232C134.601 30.704 135.161 28.448 136.281 26.464C137.433 24.448 138.985 22.864 140.937 21.712C142.889 20.56 145.097 19.984 147.561 19.984C149.833 19.984 151.913 20.384 153.801 21.184C155.721 21.984 157.241 23.104 158.361 24.544L154.185 29.536C153.737 29.024 153.209 28.544 152.601 28.096C151.993 27.648 151.321 27.296 150.585 27.04C149.849 26.784 149.081 26.656 148.281 26.656C147.065 26.656 145.993 26.944 145.065 27.52C144.137 28.064 143.417 28.832 142.905 29.824C142.393 30.816 142.137 31.952 142.137 33.232C142.137 34.448 142.393 35.552 142.905 36.544C143.449 37.536 144.185 38.32 145.113 38.896C146.041 39.472 147.097 39.76 148.281 39.76C149.113 39.76 149.881 39.664 150.585 39.472C151.289 39.248 151.929 38.928 152.505 38.512C153.113 38.096 153.673 37.584 154.185 36.976L158.313 42.016C157.225 43.392 155.705 44.48 153.753 45.28C151.833 46.08 149.769 46.48 147.561 46.48Z" fill="#141414" />
    <path d="M114.144 46.432C112 46.432 110.08 45.872 108.384 44.752C106.72 43.6 105.392 42.032 104.4 40.048C103.408 38.032 102.912 35.76 102.912 33.232C102.912 30.64 103.408 28.352 104.4 26.368C105.392 24.384 106.752 22.832 108.48 21.712C110.208 20.56 112.176 19.984 114.384 19.984C115.6 19.984 116.704 20.16 117.696 20.512C118.72 20.864 119.616 21.36 120.384 22C121.152 22.608 121.808 23.328 122.352 24.16C122.896 24.96 123.296 25.824 123.552 26.752L121.968 26.56V20.512H129.6V46H121.824V39.856L123.552 39.808C123.296 40.704 122.88 41.552 122.304 42.352C121.728 43.152 121.024 43.856 120.192 44.464C119.36 45.072 118.432 45.552 117.408 45.904C116.384 46.256 115.296 46.432 114.144 46.432ZM116.256 39.952C117.44 39.952 118.464 39.68 119.328 39.136C120.192 38.592 120.864 37.824 121.344 36.832C121.824 35.808 122.064 34.608 122.064 33.232C122.064 31.856 121.824 30.672 121.344 29.68C120.864 28.656 120.192 27.872 119.328 27.328C118.464 26.752 117.44 26.464 116.256 26.464C115.104 26.464 114.096 26.752 113.232 27.328C112.4 27.872 111.744 28.656 111.264 29.68C110.784 30.672 110.544 31.856 110.544 33.232C110.544 34.608 110.784 35.808 111.264 36.832C111.744 37.824 112.4 38.592 113.232 39.136C114.096 39.68 115.104 39.952 116.256 39.952Z" fill="#141414" />
    <path d="M87.488 46.48C84.96 46.48 82.624 46.064 80.48 45.232C78.368 44.368 76.528 43.168 74.96 41.632C73.392 40.064 72.176 38.224 71.312 36.112C70.48 33.968 70.064 31.616 70.064 29.056C70.064 26.56 70.512 24.256 71.408 22.144C72.304 20.032 73.536 18.208 75.104 16.672C76.704 15.104 78.576 13.888 80.72 13.024C82.896 12.16 85.264 11.728 87.824 11.728C89.424 11.728 90.992 11.936 92.528 12.352C94.064 12.768 95.488 13.392 96.8 14.224C98.144 15.024 99.296 15.984 100.256 17.104L95.264 22.816C94.592 22.112 93.872 21.504 93.104 20.992C92.368 20.448 91.552 20.032 90.656 19.744C89.76 19.456 88.8 19.312 87.776 19.312C86.496 19.312 85.28 19.552 84.128 20.032C83.008 20.48 82.016 21.136 81.152 22C80.32 22.832 79.664 23.856 79.184 25.072C78.704 26.288 78.464 27.648 78.464 29.152C78.464 30.624 78.704 31.968 79.184 33.184C79.664 34.368 80.336 35.392 81.2 36.256C82.064 37.12 83.104 37.776 84.32 38.224C85.568 38.672 86.944 38.896 88.448 38.896C89.472 38.896 90.448 38.752 91.376 38.464C92.304 38.176 93.152 37.792 93.92 37.312C94.72 36.8 95.424 36.224 96.032 35.584L99.968 41.824C99.168 42.72 98.096 43.52 96.752 44.224C95.44 44.928 93.968 45.488 92.336 45.904C90.736 46.288 89.12 46.48 87.488 46.48Z" fill="#141414" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.01913 22.7112C-1.00638 26.7367 -1.00638 33.2633 3.01914 37.2889L22.7112 56.9809C26.7367 61.0064 33.2633 61.0064 37.2889 56.9809L56.9809 37.2888C61.0064 33.2633 61.0064 26.7367 56.9809 22.7111L54.2343 19.9646L29.5493 44.2837L13.5538 29.0651L21.0467 21.6696L29.423 29.6228L46.7906 12.5209L37.2888 3.01913C33.2633 -1.00638 26.7367 -1.00638 22.7111 3.01914L3.01913 22.7112Z" fill="#04C147" />
  </svg>
</template>
