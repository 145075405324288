<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div class="overflow-hidden shadow-sm ring-1 ring-slate-900/5 md:rounded-xl">
          <div class="flex items-start justify-between p-6">
            <div>
              <h2 class="font-display text-lg font-semibold leading-6 text-slate-900">{{ title }}</h2>
              <p v-if="subtitle" class="pt-1 text-slate-600">
                {{ subtitle }}
              </p>
            </div>

            <div>
              <slot name="filter" />
            </div>
          </div>

          <template v-if="data.length > 0">
            <table class="min-w-full divide-y divide-slate-200 border-0 border-t border-slate-200">
              <thead class="bg-slate-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-sm font-medium tracking-tight text-slate-500">Key</th>
                  <th scope="col" class="px-6 py-3 text-left text-sm font-medium tracking-tight text-slate-500">No. Participants</th>
                  <th scope="col" class="px-6 py-3 text-left text-sm font-medium tracking-tight text-slate-500">Statistical Significance</th>
                  <th scope="col" class="px-6 py-3 text-left text-sm font-medium tracking-tight text-slate-500">Status</th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(experiment, idx) in data" :key="experiment.id" class="border-b border-slate-200" :class="idx % 2 === 0 ? 'bg-white' : 'bg-slate-100 bg-opacity-40'">
                  <td class="whitespace-nowrap px-6 py-3 text-sm">
                    <Clipboard>{{ experiment.key }}</Clipboard>
                  </td>
                  <td class="whitespace-nowrap px-6 py-3 text-sm">
                    <span v-if="experiment.participant_count">{{ numberFormat(experiment.participant_count) }}</span>
                    <span v-else>&mdash;</span>
                  </td>
                  <td class="whitespace-nowrap px-6 py-3 text-sm">
                    <span v-if="experiment.is_draft">&mdash;</span>
                    <Badge v-else-if="experiment.statistically_significant" type="success">
                      <template #icon>
                        <TrophyIcon class="size-4" />
                      </template>

                      Yes
                      <span v-if="bestVariant(experiment)" class="ml-1">({{ percentFormatter(bestVariant(experiment).confidence) }})</span>
                    </Badge>
                    <Badge v-else type="warning">No</Badge>
                  </td>
                  <td class="whitespace-nowrap px-6 py-3 text-sm">
                    <ExperimentStatusBadge :experiment="experiment" />
                  </td>
                  <td class="whitespace-nowrap px-6 py-3 text-right text-sm font-medium">
                    <Link v-if="experiment.can.view_experiment" :href="route('experiments.show', [experiment.key])"> View </Link>
                  </td>
                </tr>
              </tbody>
            </table>

            <Pagination :data="experiments" />
          </template>

          <div v-else class="px-4 py-5 sm:p-6">
            <Link :href="route('experiments.create')" class="group relative block w-full rounded-lg border-2 border-dashed border-slate-300 p-12 text-center no-underline hover:border-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">
              <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-xl bg-white text-slate-400 ring-1 ring-black/5 group-hover:text-emerald-500 group-hover:shadow-xl group-hover:shadow-emerald-500/10">
                <BeakerIcon class="size-6" />
              </div>

              <span class="mt-2 block font-semibold text-slate-800">
                <template v-if="noFilterResults">No results match your filters. Please try again or click to create a new one.</template>
                <template v-else>Create a new experiment</template>
              </span>

              <div class="flex justify-center space-x-2">
                <a href="https://docs.checkmango.com/experiments/" target="_blank" class="mt-2 block font-medium text-slate-600 underline"> Documentation </a>
                <a href="https://developers.checkmango.com/#endpoint-experiments" target="_blank" class="mt-2 block font-medium text-slate-600 underline"> API Documentation </a>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import Clipboard from '@/Components/Clipboard.vue'
import ExperimentStatusBadge from '@/Components/ExperimentStatusBadge.vue'
import { BeakerIcon, TrophyIcon } from '@heroicons/vue/24/outline'
import Pagination from '@/Components/Pagination.vue'
import Badge from '@/Components/Badge.vue'

export default {
  components: {
    Badge,
    ExperimentStatusBadge,
    BeakerIcon,
    Clipboard,
    Link,
    Pagination,
    TrophyIcon,
  },
  props: {
    experiments: Object,
    title: String,
    subtitle: String,
    noFilterResults: Boolean,
  },
  computed: {
    data() {
      return this.experiments.data
    },
  },
  methods: {
    bestVariant(experiment) {
      return experiment.variants.find((variant) => variant?.is_best || false)
    },
  },
}
</script>
