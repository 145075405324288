<template>
  <JetFormSection @submitted="updateTeamCurrency()">
    <template #title> Currency </template>

    <template #description> The currency that event values are displayed in. </template>

    <template #form>
      <!-- Team Currency -->
      <div class="col-span-6 sm:col-span-4">
        <JetLabel for="currency" value="Team Currency" />

        <select id="currency" v-model="form.currency_code" :disabled="!permissions.canUpdateTeam" class="mt-1 block w-full rounded-md border-slate-300 py-2 pl-3 pr-10 focus:border-slate-500 focus:outline-none focus:ring-slate-500">
          <option selected />
          <option v-for="currency in currencies" :key="currency" :value="currency" :selected="currency === form.currency_code">
            {{ currency }}
          </option>
        </select>

        <JetInputError :message="form.errors.currency" class="mt-2" />
      </div>
    </template>

    <template v-if="permissions.canUpdateTeam" #actions>
      <JetActionMessage :on="form.recentlySuccessful" class="mr-3"> Saved. </JetActionMessage>

      <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Save </JetButton>
    </template>
  </JetFormSection>
</template>

<script>
import currencies from 'currency-codes'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetActionMessage from '@/Jetstream/ActionMessage.vue'

export default {
  components: {
    JetFormSection,
    JetInputError,
    JetLabel,
    JetButton,
    JetActionMessage,
  },
  props: {
    team: Object,
    permissions: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        currency_code: this.team.currency_code,
      }),
      currencies: [],
    }
  },
  mounted() {
    this.currencies = currencies.codes()
  },
  methods: {
    updateTeamCurrency() {
      this.form.put(this.route('teams.currency.update', this.team), {
        errorBag: 'updateTeamCurrency',
        preserveScroll: true,
      })
    },
  },
}
</script>
