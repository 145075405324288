<script setup>
import Banner from '@/Jetstream/Banner.vue'
import { computed } from 'vue'

const props = defineProps({
  subscription: {
    type: Object,
    default: () => ({}),
  },
})

const showTrialBanner = computed(() => {
  return !props.subscription.freeAccount && (props.subscription.trialing || !props.subscription.subscribed)
})

const trialText = computed(() => {
  if (props.subscription.trialExpired) {
    return `Your <strong>FREE</strong> trial has expired. <a href="/teams/${props.subscription.team_id}/billing" class="font-medium">Subscribe &rarr;</a> to start using Checkmango.`
  } else if (props.subscription.trialing) {
    const trialDaysRemaining = props.subscription.trialEndsAt
    let parts = ['You are currently trialing Checkmango.']

    if (trialDaysRemaining === 1) {
      parts.push('Your <strong>FREE</strong> trial ends today.')
    } else {
      parts.push(`Your <strong>FREE</strong> trial ends in ${trialDaysRemaining} days.`)
    }

    return parts.join(' ')
  }

  return `<a href="/teams/${props.subscription.team_id}/billing" class="font-medium">Start your 14-day FREE trial today &rarr;</a>`
})
</script>

<template>
  <div v-if="showTrialBanner">
    <Banner :banner="trialText" banner-style="special" :can-close="false" />
  </div>
</template>
