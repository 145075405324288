<template>
  <div class="md:grid md:grid-cols-8 md:gap-6">
    <SectionTitle>
      <template #title>
        <span class="font-display tracking-normal text-red-700"><slot name="title" /></span>
      </template>
      <template #description>
        <div class="prose prose-sm text-red-700">
          <slot name="description" />
        </div>
      </template>
    </SectionTitle>

    <div class="mt-5 md:col-span-6 md:mt-0">
      <div class="bg-red-50 px-4 py-5 text-red-900 shadow ring-2 ring-red-400 sm:rounded-xl sm:p-6">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from './SectionTitle.vue'

export default {
  components: {
    SectionTitle,
  },
}
</script>
