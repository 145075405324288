<template>
  <span
    class="inline-flex items-center rounded-md px-3 py-1 text-sm font-medium"
    :class="{
      'bg-slate-100 text-slate-700': type === 'default',
      'bg-red-100 text-red-700/80': type === 'error',
      'bg-yellow-100 text-yellow-700': type === 'warning',
      'bg-emerald-100 text-emerald-700': type === 'success',
      'bg-blue-50 text-blue-700/80': type === 'info',
      'bg-slate-100 text-slate-500': type === 'special',
    }"
  >
    <div v-if="hasIconSlot" class="-ml-0.5 mr-1.5">
      <slot name="icon" />
    </div>
    <svg
      v-else-if="indicator"
      class="-ml-0.5 mr-1.5 size-2"
      :class="{
        'text-slate-600': type === 'default' || type === 'special',
        'text-red-600': type === 'error',
        'text-yellow-600': type === 'warning',
        'text-emerald-600': type === 'success',
        'text-blue-600': type === 'info',
      }"
      fill="currentColor"
      viewBox="0 0 8 8"
    >
      <circle cx="4" cy="4" r="3" />
    </svg>
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    indicator: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasIconSlot() {
      return !!this.$slots.icon
    },
  },
}
</script>
