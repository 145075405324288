<template>
  <span>
    <span @click="startConfirmingPassword">
      <slot />
    </span>

    <JetDialogModal :show="confirmingPassword" @close="closeModal">
      <template #title>
        <h3 class="font-display text-lg font-semibold">
          {{ title }}
        </h3>
      </template>

      <template #content>
        {{ content }}

        <div class="mt-4">
          <JetInput ref="password" v-model="form.password" type="password" class="mt-1 block w-3/4" placeholder="Password" @keyup.enter="confirmPassword" />

          <JetInputError :message="form.error" class="mt-2" />
        </div>
      </template>

      <template #footer>
        <JetSecondaryButton @click="closeModal"> Never mind </JetSecondaryButton>

        <JetButton class="ml-2" :class="{ 'opacity-25': form.processing }" :disabled="form.processing" @click="confirmPassword">
          {{ button }}
        </JetButton>
      </template>
    </JetDialogModal>
  </span>
</template>

<script>
import Axios from 'axios'
import JetButton from './Button.vue'
import JetDialogModal from './DialogModal.vue'
import JetInput from './Input.vue'
import JetInputError from './InputError.vue'
import JetSecondaryButton from './SecondaryButton.vue'

export default {
  components: {
    JetButton,
    JetDialogModal,
    JetInput,
    JetInputError,
    JetSecondaryButton,
  },

  props: {
    title: {
      type: String,
      default: 'Confirm Password',
    },
    content: {
      type: String,
      default: 'For your security, please confirm your password to continue.',
    },
    button: {
      type: String,
      default: 'Confirm',
    },
  },
  emits: ['confirmed'],

  data() {
    return {
      confirmingPassword: false,
      form: {
        password: '',
        error: '',
      },
    }
  },

  methods: {
    startConfirmingPassword() {
      Axios.get(this.route('password.confirmation')).then((response) => {
        if (response.data.confirmed) {
          this.$emit('confirmed')
        } else {
          this.confirmingPassword = true

          setTimeout(() => this.$refs.password.focus(), 250)
        }
      })
    },

    confirmPassword() {
      this.form.processing = true

      Axios.post(this.route('password.confirm'), {
        password: this.form.password,
      })
        .then(() => {
          this.form.processing = false
          this.closeModal()
          this.$nextTick(() => this.$emit('confirmed'))
        })
        .catch((error) => {
          this.form.processing = false
          this.form.error = error.response.data.errors.password[0]
          this.$refs.password.focus()
        })
    },

    closeModal() {
      this.confirmingPassword = false
      this.form.password = ''
      this.form.error = ''
    },
  },
}
</script>
