<template>
  <JetModal :show="show">
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div class="mt-3 w-full text-center sm:mx-4 sm:mt-0 sm:text-left">
          <h3 class="text-lg">Create Event</h3>

          <form @submit.prevent="createEvent">
            <div class="mt-2 w-full space-y-6">
              <EventForm ref="eventForm" :inline="true" @event-created="eventCreated" />
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="bg-slate-100 px-6 py-4 text-right">
      <JetSecondaryButton class="mr-2" @click="$emit('close')">Close</JetSecondaryButton>

      <JetButton @click="createEvent"> Create </JetButton>
    </div>
  </JetModal>
</template>

<script>
import JetModal from '@/Jetstream/Modal.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import EventForm from '@/Pages/Events/EventForm.vue'

export default {
  components: {
    JetModal,
    JetButton,
    JetSecondaryButton,
    EventForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'eventCreated'],
  methods: {
    createEvent() {
      this.$refs.eventForm.createEvent()
    },
    eventCreated(event) {
      this.$emit('eventCreated', event)
    },
  },
}
</script>
