<template>
  <AuthenticationCard>
    <Head title="Verify Your Email" />

    <div class="mb-4 text-slate-600">Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.</div>

    <div v-if="verificationLinkSent" class="mb-4 font-medium text-emerald-600">A new verification link has been sent to the email address you provided during registration.</div>

    <form @submit.prevent="submit">
      <div class="mt-4 flex items-center justify-between">
        <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Resend Verification Email </Button>

        <Link :href="route('logout')" method="post" as="button" class="text-slate-600 underline hover:text-slate-900"> Log out </Link>
      </div>
    </form>
  </AuthenticationCard>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3'
import AuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
import Button from '@/Jetstream/Button.vue'
import AuthenticationLayout from '@/Layouts/AuthenticationLayout.vue'

export default {
  components: {
    Head,
    Link,
    AuthenticationCard,
    Button,
  },

  layout: [AuthenticationLayout],

  props: {
    status: String,
  },

  data() {
    return {
      form: this.$inertia.form({}),
    }
  },

  computed: {
    verificationLinkSent() {
      return this.status === 'verification-link-sent'
    },
  },

  methods: {
    submit() {
      this.form.post(this.route('verification.send'))
    },
  },
}
</script>
