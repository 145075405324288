<template>
  <JetFormSection @submitted="updateProfileInformation">
    <template #title> Profile Information </template>

    <template #description> Update your account's profile information and email address. </template>

    <template #form>
      <!-- Profile Photo -->
      <div v-if="$page.props.jetstream.managesProfilePhotos" class="col-span-6 sm:col-span-4">
        <!-- Profile Photo File Input -->
        <input ref="photo" type="file" class="hidden" @change="updatePhotoPreview" />

        <JetLabel for="photo" value="Photo" />

        <!-- Current Profile Photo -->
        <div v-show="!photoPreview" class="mt-2">
          <img :src="user.profile_photo_url" :alt="user.name" class="h-20 w-20 rounded-full object-cover" />
        </div>

        <!-- New Profile Photo Preview -->
        <div v-show="photoPreview" class="mt-2">
          <span class="block h-20 w-20 rounded-full" :style="'background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'' + photoPreview + '\');'" />
        </div>

        <JetSecondaryButton class="mt-2 mr-2" type="button" @click.prevent="selectNewPhoto"> Select A New Photo </JetSecondaryButton>

        <JetSecondaryButton v-if="user.profile_photo_path" type="button" class="mt-2" @click.prevent="deletePhoto"> Remove Photo </JetSecondaryButton>

        <JetInputError :message="form.errors.photo" class="mt-2" />
      </div>

      <!-- Name -->
      <div class="col-span-6 sm:col-span-4">
        <JetLabel for="name" value="Name" />
        <JetInput id="name" v-model="form.name" type="text" class="mt-1 block w-full" autocomplete="name" />
        <JetInputError :message="form.errors.name" class="mt-2" />
      </div>

      <!-- Email -->
      <div class="col-span-6 sm:col-span-4">
        <JetLabel for="email" value="Email" />
        <JetInput id="email" v-model="form.email" type="email" class="mt-1 block w-full" />
        <JetInputError :message="form.errors.email" class="mt-2" />
      </div>

      <!-- Marketing Emails -->
      <div class="col-span-6 sm:col-span-4">
        <JetLabel for="marketing">
          <div class="flex items-center">
            <JetCheckbox id="marketing" v-model="form.marketing_emails" name="marketing" :checked="form.marketing_emails" />

            <div class="ml-2">I would like to receive marketing emails</div>
          </div>
        </JetLabel>
      </div>
    </template>

    <template #actions>
      <JetActionMessage :on="form.recentlySuccessful" class="mr-3"> Saved. </JetActionMessage>

      <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Save </JetButton>
    </template>
  </JetFormSection>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'

export default {
  components: {
    JetActionMessage,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetSecondaryButton,
    JetCheckbox,
  },

  props: {
    user: Object,
  },

  data() {
    return {
      form: this.$inertia.form({
        _method: 'PUT',
        name: this.user.name,
        email: this.user.email,
        photo: null,
        marketing_emails: this.user.marketing_emails,
      }),

      photoPreview: null,
    }
  },

  methods: {
    updateProfileInformation() {
      if (this.$refs.photo) {
        this.form.photo = this.$refs.photo.files[0]
      }

      this.form.post(this.route('user-profile-information.update'), {
        errorBag: 'updateProfileInformation',
        preserveScroll: true,
      })
    },

    selectNewPhoto() {
      this.$refs.photo.click()
    },

    updatePhotoPreview() {
      const photo = this.$refs.photo.files[0]

      if (!photo) return

      const reader = new FileReader()

      reader.onload = (e) => {
        this.photoPreview = e.target.result
      }

      reader.readAsDataURL(photo)
    },

    deletePhoto() {
      this.$inertia.delete(this.route('current-user-photo.destroy'), {
        preserveScroll: true,
        onSuccess: () => (this.photoPreview = null),
      })
    },
  },
}
</script>
