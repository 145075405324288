<template>
  <div>
    <div
      v-if="show && message"
      :class="{
        'bg-emerald-500': style === 'success',
        'bg-purple-600': style === 'info',
        'bg-rose-500': style === 'danger',
        'bg-gradient-to-r from-rose-500 via-yellow-500 to-emerald-500': style === 'special',
      }"
    >
      <div class="mx-auto w-full py-2 px-3 sm:px-6 lg:px-8">
        <div class="flex flex-wrap items-center justify-between">
          <div class="flex w-0 min-w-0 flex-1 items-center">
            <span
              class="flex rounded-lg p-2"
              :class="{
                'bg-emerald-600': style === 'success',
                'bg-slate-200 bg-opacity-20': style === 'info',
                'bg-rose-600': style === 'danger',
                'bg-amber-500/40': style === 'special',
              }"
            >
              <svg v-if="style === 'success'" class="size-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              <svg v-if="style === 'info'" class="size-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              <svg v-if="style === 'danger'" class="size-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>

              <svg v-if="style === 'alert'" class="size-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>

              <svg v-if="style === 'special'" class="size-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
              </svg>
            </span>

            <div class="ml-3 truncate font-normal text-white" v-html="message" />
          </div>

          <div v-if="isClosable" class="flex-shrink-0 sm:ml-3">
            <button
              type="button"
              class="-mr-1 flex rounded-md p-2 transition focus:outline-none sm:-mr-2"
              :class="{
                'hover:focus:bg-emerald-600': style === 'success',
                'hover:focus:bg-slate-600': style === 'info',
                'hover:focus:bg-rose-600': style === 'danger',
                'hover:focus:bg-amber-600': style === 'special',
              }"
              aria-label="Dismiss"
              @click.prevent="show = false"
            >
              <svg class="size-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div v-if="hasActionSlot" class="flex-shrink-0 sm:ml-3">
            <slot name="action" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerStyle: {
      default: '',
      type: String,
    },
    banner: {
      default: '',
      type: String,
    },
    canClose: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      show: true,
    }
  },

  computed: {
    style() {
      return this.bannerStyle || this.$page.props.jetstream.flash?.bannerStyle
    },

    message() {
      return this.banner || this.$page.props.jetstream.flash?.banner
    },

    autoClear() {
      return this.$page.props.jetstream.flash?.autoClear || 0
    },

    isClosable() {
      return this.canClose || this.$page.props.jetstream.flash?.closable
    },

    hasActionSlot() {
      return !this.isClosable && !!this.$slots.action
    },
  },

  mounted() {
    if (this.autoClear > 0) {
      setTimeout(() => (this.show = false), this.autoClear)
    }
  },
}
</script>
