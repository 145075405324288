<template>
  <div>
    <Head :title="`${webhook.id} | Webhooks`" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <FormSection>
        <template #title>Details</template>

        <template #description>Here you'll find the feature's details.</template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label>URL</Label>

            <Clipboard>{{ webhook.url }}</Clipboard>
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Secret</Label>

            {{ webhook.secret }}
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Status</Label>

            <Toggle off-text="Disabled" on-text="Enabled" :value="webhook.enabled" class="mt-1" disabled />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Date Created</Label>

            {{ dateTimeFormat(webhook.created_at) }}
          </div>
        </template>

        <template v-if="can.edit_webhook" #actions>
          <SecondaryButton @click="$inertia.visit(route('webhooks.edit', [webhook]))">Edit</SecondaryButton>
        </template>
      </FormSection>

      <SectionBorder />

      <DangerActionSection>
        <template #title>Danger Zone</template>

        <template #description>Dragons around, be careful.</template>

        <template #content>
          <div class="space-y-4">
            <DangerButton :disabled="!can.delete_webhook" @click="webhookToDelete = webhook">Delete Webhook</DangerButton>
          </div>
        </template>
      </DangerActionSection>
    </div>

    <DeleteWebhookModal :webhook="webhookToDelete" @close="webhookToDelete = null" @confirm="deleteWebhook" />
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import Clipboard from '@/Components/Clipboard.vue'
import FormSection from '@/Jetstream/FormSection.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import Label from '@/Jetstream/Label.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import SectionBorder from '@/Jetstream/SectionBorder.vue'
import DeleteWebhookModal from '@/Modals/DeleteWebhookModal.vue'
import DangerActionSection from '@/Jetstream/DangerActionSection.vue'
import Toggle from '@/Components/Toggle.vue'

export default {
  components: {
    Toggle,
    DangerActionSection,
    Head,
    Clipboard,
    FormSection,
    Label,
    SecondaryButton,
    DangerButton,
    SectionBorder,
    DeleteWebhookModal,
  },
  layout: [AppLayout],
  props: {
    can: Object,
    links: {
      type: Array,
      default: () => [],
    },
    webhook: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      webhookToDelete: null,
    }
  },
  methods: {
    deleteWebhook() {
      this.$inertia.delete(this.route('webhooks.destroy', [this.webhookToDelete.id]))
      this.webhookToDelete = null
    },
  },
}
</script>
