<template>
  <AuthenticationCard>
    <Head title="Register" />

    <Banner v-if="teamInvitation" class="mb-4"> Register or <Link :href="route('login')" class="font-semibold underline">log in</Link> to join the {{ teamInvitation }} team. </Banner>

    <ValidationErrors class="mb-4" />

    <form @submit.prevent="submit">
      <div class="mt-4">
        <Label for="name" value="Name" />
        <Input id="name" v-model="form.name" type="text" class="mt-1 block w-full" required />
      </div>

      <div class="mt-4">
        <Label for="email" value="Email" />
        <Input id="email" v-model="form.email" type="email" class="mt-1 block w-full" required />
      </div>

      <div class="mt-4">
        <Label for="team_name" value="Team Name" />
        <Input id="team_name" v-model="form.team_name" type="text" class="mt-1 block w-full" />
      </div>

      <div class="mt-4">
        <Label for="password" value="Password" />
        <Input id="password" v-model="form.password" type="password" class="mt-1 block w-full" required autocomplete="new-password" />
      </div>

      <div class="mt-4">
        <Label for="password_confirmation" value="Confirm Password" />
        <Input id="password_confirmation" v-model="form.password_confirmation" type="password" class="mt-1 block w-full" required autocomplete="new-password" />
      </div>

      <div v-if="$page.props.jetstream.hasTermsAndPrivacyPolicyFeature" class="mt-4">
        <Label for="terms">
          <div class="flex items-center">
            <Checkbox id="terms" v-model:checked="form.terms" name="terms" />

            <div class="ml-2 font-normal">
              I agree to the
              <Link target="_blank" :href="route('terms.show')" class="text-slate-600 underline hover:text-slate-900">Terms of Service</Link>
              and
              <Link target="_blank" :href="route('policy.show')" class="text-slate-600 underline hover:text-slate-900">Privacy Policy</Link>
            </div>
          </div>
        </Label>
        <Label for="marketing">
          <div class="flex items-center">
            <Checkbox id="marketing" v-model:checked="form.marketing_emails" name="marketing" />

            <div class="ml-2 font-normal">Sign me up for marketing emails</div>
          </div>
        </Label>
      </div>

      <div class="mt-4 flex items-center justify-end gap-4">
        <span class="text-sm text-slate-600"> Already have an account? <Link :href="route('login')" class="font-semibold underline hover:text-slate-900">Log in</Link>. </span>
        <Button :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Register </Button>
      </div>
    </form>

    <hr class="my-4 border-t" />

    <div class="mb-4 flex items-center justify-center">
      <a :href="route('github.signup')" class="mb-4 inline-flex items-center rounded-lg bg-[#24292F] px-5 py-2.5 text-center font-medium text-white hover:bg-[#24292F]/90 focus:outline-none focus:ring-4 focus:ring-[#24292F]/50 dark:hover:bg-[#050708]/30 dark:focus:ring-slate-500">
        <svg class="mr-2 -ml-1 size-4" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" /></svg>
        Sign up with Github
      </a>
    </div>
  </AuthenticationCard>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3'
import AuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
import Button from '@/Jetstream/Button.vue'
import Input from '@/Jetstream/Input.vue'
import Checkbox from '@/Jetstream/Checkbox.vue'
import Label from '@/Jetstream/Label.vue'
import ValidationErrors from '@/Jetstream/ValidationErrors.vue'
import AuthenticationLayout from '@/Layouts/AuthenticationLayout.vue'
import pluralize from 'pluralize'
import Banner from '@/Components/Banner.vue'

export default {
  components: {
    Banner,
    AuthenticationCard,
    Head,
    Link,
    Button,
    Input,
    Checkbox,
    Label,
    ValidationErrors,
  },

  layout: [AuthenticationLayout],

  props: {
    oauthUser: Object,
  },

  data() {
    return {
      form: this.$inertia.form({
        github_user_id: '',
        name: '',
        email: '',
        team_name: null,
        password: '',
        password_confirmation: '',
        terms: false,
        marketing_emails: false,
      }),
    }
  },

  computed: {
    teamInvitation() {
      return this.$page.props.session?.teamInvitation || null
    },
  },

  watch: {
    'form.name'(newValue) {
      if (newValue) {
        const newTeamName = pluralize(newValue.split(' ')[0], 0)

        this.form.team_name = `${newTeamName} Team`
      } else {
        this.form.team_name = ''
      }
    },
  },

  mounted() {
    if (this.oauthUser.github_user_id) {
      this.form.github_user_id = this.oauthUser.github_user_id
      this.form.name = this.oauthUser.name
      this.form.email = this.oauthUser.email
    }
  },

  methods: {
    submit() {
      this.form.post(this.route('register'), {
        // onFinish: () => this.form.reset('password', 'password_confirmation'),
      })
    },
  },
}
</script>
