<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 528 214">
    <polygon fill="#0C0A09" points="10,10 10,76 10,82 10,142 10,148 10,214 82,214 82,148 142,148 142,82 202,82 202,10" />
    <path fill="#0C0A09" d="M0,0v198v6h6h66v-66h60V72h60V0H0z M66,198H6v-60h60V198z M126,132H72h-6H6V72h120V132z M186,66h-54h-6H6V6h180 V66z" />
    <rect fill="white" x="6" y="6" width="180" height="60" />
    <rect fill="white" x="6" y="72" width="120" height="60" />
    <rect fill="white" x="6" y="138" width="60" height="60" />
    <path fill="#0c0a09" d="M315.6,69c-1.7-4.3-8.1-9-12.3-10.2c-2.4-0.8-5-0.9-7.6-0.8c-12.3,1.2-17,9.8-18.1,21.1 c-0.5,4.7-0.6,9.5-0.8,14.3c-1.8,0-10,0.5-11.7,1.1c-7.3,2.6-6.4,13.3,1.2,14.6c1.5,0.3,8.8-0.5,10.5-0.5 c0.3,13.7,1.7,27.6,1.2,41.3c-0.2,2.9,1.4,5.5,3.8,6.9c1.1,0.6,2.4,0.9,3.7,1.1c1.7,0.2,3.5-0.8,4.9-1.7c1.5-1.1,2.3-2.9,2.7-4.6 c0.2-0.5,0.2-3.1,0.2-3.7c0.3-13.3-0.9-26.4-1.2-39.7c2.7,0,5.6,0.3,8.5,0.6c4.1,0.3,7.3-3.4,7.6-7.6c0.2-1.7-0.8-3.5-1.7-4.9 c-1.1-1.4-2.7-2.1-4.4-2.6c-1.5-0.5-8.5-0.8-10-0.8c0.2-4.6,0.2-9.3,0.8-13.7c0.3-1.2,0.6-4.6,1.7-5.3c1.1-0.8,3.5-1.1,4.6-0.5 c1.4,0.8,2.7,1.7,3.5,2.9c1.4,2,4.3,2.9,6.7,2.7C314,78.8,317.2,73.8,315.6,69z M341.8,92c-34.7-0.6-39.8,58.4-5.6,63.8c5.8,0.6,12.4,0.2,17.1-3.1c4.6-3.1,3.2-11.1-2-12.9 c-2.5-0.9-5.3-0.1-7.4,0.9c-9.5,2.8-16.4-6.1-15.9-15c10.5,7.8,27.5,2.1,33.5-8.7C367.4,104.7,354.9,91.4,341.8,92z M344.2,113 c-4.1,3-8.1,0.4-11.4-2.3c3.7-4.2,12-6.1,15-0.4C346.8,111.5,345.4,112.3,344.2,113z M417.7,120c-2.4-13.7-9-25.5-23.7-27.8c-5.8-0.6-12.5-0.2-17.2,3.1c-4.6,3.1-3.3,11.1,2,12.9 c2.5,0.9,5.3,0.1,7.5-0.9c9.5-2.8,16.5,6.1,16,15c-10.6-7.8-27.7-2.1-33.7,8.7c-5.9,12.3,6.7,25.5,19.8,24.9 c8.1,0.1,14.5-2.9,19.3-7.7c0.2,0.8,0.4,1.6,0.6,2.5c0.9,3.8,5,5.8,8.6,5.2c1.8-0.3,3.4-1.1,4.5-2.7c1.3-1.7,2.1-4.1,1.6-6.2 C419.6,133.5,420,133.2,417.7,120z M382.2,137.7c1.1-1.2,2.4-1.9,3.7-2.7c4.7-3,7.6-0.5,11.4,2.3 C393.6,141.4,385.2,143.4,382.2,137.7z M495.8,79.7c-0.1-2.3-0.4-13.6-0.8-15.7c-1.8-7.5-12.4-8.1-14.8-0.8c-1,11.3,1,23.6,1.6,35.1 c-29.6-20.7-67.4,13.2-48.8,44.4c11.8,18,39.1,14.1,50.7-2.2c0.9,6.6-0.8,17.5,8.7,17.5c7.4-0.1,7.9-7.5,7.5-13.3 C498.5,123.3,497.6,101.1,495.8,79.7z M457.3,139.4c-16.1,1.3-17.5-20.7-6.7-28.4c8.7-6.8,21.5-3.3,28.4,4.4 C476.2,125.2,469.3,138.9,457.3,139.4z M519,140.1c-5.5-0.7-10.4,2.8-11,7.7c-0.6,4.9,3.4,9.5,8.9,10.1c5.5,0.7,10.4-2.8,11-7.7 C528.5,145.3,524.5,140.8,519,140.1z" />
  </svg>
</template>
