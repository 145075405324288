<template>
  <JetActionSection>
    <template #title> Browser Sessions </template>

    <template #description> Manage and logout your active sessions on other browsers and devices. </template>

    <template #content>
      <div class="max-w-xl text-slate-600">If necessary, you may logout of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.</div>

      <!-- Other Browser Sessions -->
      <div v-if="sessions.length > 0" class="mt-5 space-y-6">
        <div v-for="(session, i) in sessions" :key="i" class="flex items-center">
          <div>
            <svg v-if="session.agent.is_desktop" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor" class="size-8 text-slate-500">
              <path d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>

            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" class="size-8 text-slate-500">
              <path d="M0 0h24v24H0z" stroke="none" />
              <rect x="7" y="4" width="10" height="16" rx="1" />
              <path d="M11 5h2M12 17v.01" />
            </svg>
          </div>

          <div class="ml-3">
            <div class="text-slate-600">{{ session.agent.platform }} - {{ session.agent.browser }}</div>

            <div>
              <div class="text-xs text-slate-500">
                {{ session.ip_address }},

                <span v-if="session.is_current_device" class="font-semibold text-emerald-500">This device</span>
                <span v-else>Last active {{ session.last_active }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5 flex items-center">
        <JetButton @click="confirmLogout"> Logout Other Browser Sessions </JetButton>

        <JetActionMessage :on="form.recentlySuccessful" class="ml-3"> Done. </JetActionMessage>
      </div>

      <!-- Logout Other Devices Confirmation Modal -->
      <JetDialogModal :show="confirmingLogout" @close="closeModal">
        <template #title> Logout Other Browser Sessions </template>

        <template #content>
          Please enter your password to confirm you would like to logout of your other browser sessions across all of your devices.

          <div class="mt-4">
            <JetInput ref="password" v-model="form.password" type="password" class="mt-1 block w-3/4" placeholder="Password" @keyup.enter="logoutOtherBrowserSessions" />

            <JetInputError :message="form.errors.password" class="mt-2" />
          </div>
        </template>

        <template #footer>
          <JetSecondaryButton @click="closeModal"> Never mind </JetSecondaryButton>

          <JetButton class="ml-2" :class="{ 'opacity-25': form.processing }" :disabled="form.processing" @click="logoutOtherBrowserSessions"> Logout Other Browser Sessions </JetButton>
        </template>
      </JetDialogModal>
    </template>
  </JetActionSection>
</template>

<script>
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import JetActionSection from '@/Jetstream/ActionSection.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetDialogModal from '@/Jetstream/DialogModal.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'

export default {
  components: {
    JetActionMessage,
    JetActionSection,
    JetButton,
    JetDialogModal,
    JetInput,
    JetInputError,
    JetSecondaryButton,
  },
  props: {
    sessions: Array,
  },

  data() {
    return {
      confirmingLogout: false,

      form: this.$inertia.form({
        password: '',
      }),
    }
  },

  methods: {
    confirmLogout() {
      this.confirmingLogout = true

      setTimeout(() => this.$refs.password.focus(), 250)
    },

    logoutOtherBrowserSessions() {
      this.form.delete(this.route('other-browser-sessions.destroy'), {
        preserveScroll: true,
        onSuccess: () => this.closeModal(),
        onError: () => this.$refs.password.focus(),
        onFinish: () => this.form.reset(),
      })
    },

    closeModal() {
      this.confirmingLogout = false

      this.form.reset()
    },
  },
}
</script>
