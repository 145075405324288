<template>
  <div>
    <Head :title="`Edit ${event.key} | Events`" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <JetFormSection @submitted="updateEvent">
        <template #title>Event Details</template>

        <template #description>
          <p>Events are used to notify {{ $page.props.app_name }} when a participant has performed an action such as <code>CHECKOUT_STARTED</code>.</p>
          <p>It is good practise to name your events in a format that matches the type of actions users may perform on your website or application. For example, <code>SIGN_UP</code>, <code>CREATE_PROJECT</code> or <code>ADD_TO_BASKET</code>.</p>
          <p>
            Need more help? Check out the
            <a href="https://docs.checkmango.com/events" target="_blank">documentation</a>.
          </p>
        </template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <JetLabel for="key" value="Key" />

            <JetInput id="key" v-model="form.key" type="text" class="mt-1 block w-full" autofocus autocomplete="off" />

            <JetInputError :message="form.errors.key" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <JetLabel for="type" value="Type" />

            <select id="type" v-model="form.type" class="block w-full rounded-md border-slate-300 bg-white shadow-sm focus:border-slate-500 focus:outline-none focus:ring-slate-500">
              <option selected />
              <option value="unique">Unique</option>
              <option value="count">Count</option>
              <!--                <option value="duration">Duration - How long something takes (time on site, loading speed etc)</option>-->
              <!--                <option value="revenue">Revenue - How much money a customer pays (revenue, average order value, etc)</option>-->
            </select>

            <JetLabel :value="eventTypeDescription" class="mt-2" :sub-label="true" />

            <JetInputError :message="form.errors.type" class="mt-2" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <JetLabel for="description" value="Description" />

            <Editor id="description" :initial-content="form.description" @content-changed="handleEditorContentChange" />

            <JetInputError :message="form.errors.description" class="mt-2" />
          </div>
        </template>

        <template #actions>
          <JetActionMessage :on="form.recentlySuccessful" class="mr-3"> Updated. </JetActionMessage>

          <JetSecondaryButton class="mr-2" @click="$inertia.visit(route('events.show', [event.key]))">Cancel</JetSecondaryButton>

          <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Update </JetButton>
        </template>
      </JetFormSection>
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Head,
    JetFormSection,
    JetLabel,
    JetInput,
    JetInputError,
    JetActionMessage,
    JetButton,
    JetSecondaryButton,
    Editor: defineAsyncComponent(() => import('@/Components/Editor.vue')),
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    event: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        key: this.event.key,
        description: this.event.description,
        type: this.event.type,
      }),
    }
  },
  computed: {
    eventTypeDescription() {
      switch (this.form.type) {
      case 'unique':
        return 'Counts one conversion per participant (created account, logged in)'
      case 'count':
        return 'Counts multiple conversions per participant (clicks, views, downloads)'
      default:
        return ''
      }
    },
  },
  methods: {
    updateEvent() {
      this.form.put(this.route('events.update', [this.event.key]), {
        preserveScroll: true,
      })
    },
    handleEditorContentChange(content) {
      this.form.description = content
    },
  },
}
</script>
