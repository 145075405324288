<template>
  <Card class="relative overflow-hidden">
    <div v-if="loading" class="absolute inset-0 flex items-center justify-center rounded-lg bg-white">
      <Loader class="h-20 w-20 text-pink-500" />
    </div>

    <slot />
  </Card>
</template>

<script>
import Card from '@/Components/Card.vue'
import Loader from '@/Components/Loader.vue'

export default {
  components: {
    Card,
    Loader,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped></style>
