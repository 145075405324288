import { DateTime } from 'luxon'
import numbro from '@/Util/numbro'

export const debounce = (func, delay, { leading } = {}) => {
  let timerId

  return (...args) => {
    if (!timerId && leading) {
      func(...args)
    }
    clearTimeout(timerId)

    timerId = setTimeout(() => func(...args), delay)
  }
}

export default {
  methods: {
    dateTimeFormat(timestamp) {
      if (!timestamp) {
        return '—'
      }

      return DateTime
        .fromISO(timestamp)
        .toLocaleString(DateTime.DATETIME_MED)
    },
    dateFormat(timestamp) {
      if (!timestamp) {
        return '—'
      }

      return DateTime
        .fromISO(timestamp)
        .toLocaleString(DateTime.DATE_FULL)
    },
    numberFormat(value, format) {
      const num = numbro(value)

      if (format !== undefined) {
        return num.format(format)
      }

      return num.format()
    },
    percentFormatter(value) {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'percent',
        maximumFractionDigits: 2,
      })

      return formatter.format(value)
    },
    currencyFormatter(value, currency) {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency || 'USD',
      })

      return formatter.format(value)
    },
    getConversionTitle() {
      return 'Conversion Rate'
    },
    conversionRateFormat(type, value) {
      value = value || 0

      if (type === 'count') {
        return value.toFixed(2)
      }

      if (type === 'revenue') {
        return this.currencyFormatter(value)
      }

      return this.percentFormatter(value)
    },
    round(value, places) {
      places = places || 2

      return parseFloat(value).toFixed(places)
    },
    algorithmDescription(algorithmType) {
      if (algorithmType === 'blockRandomization') {
        return 'Checkmango will always select the variant with the least enrolled participants, ensuring equal distribution.'
      } else if (algorithmType === 'weightedSample') {
        return 'Variants can be weighted to prefer one over another.'
      } else if (algorithmType === 'whiplash') {
        return 'Checkmango will automatically select the winning variant as time goes on. Requires a longer testing time.'
      }

      return ''
    },
  },
}
