<template>
  <div class="md:col-span-2">
    <div class="px-4 sm:px-0">
      <h3 class="text-lg font-semibold text-slate-900">
        <slot name="title" />
      </h3>

      <div class="mt-1 space-y-4 text-slate-600">
        <slot name="description" />
      </div>
    </div>
  </div>
</template>
