<template>
  <div>
    <dl class="mb-6 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div v-for="item in stats" :key="item.name" class="overflow-hidden rounded-lg border border-slate-200 bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate font-medium text-slate-500">
          {{ item.name }}
        </dt>
        <dd class="mt-1 text-3xl font-medium text-slate-900">
          {{ numberFormat(item.stat) }}
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Array,
    },
  },
}
</script>
