<template>
  <div>
    <Head title="Participants" />
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="mb-8 overflow-hidden bg-white shadow-sm ring-1 ring-slate-900/5 md:rounded-xl">
        <ParticipantsTable :participants="participants" title="Participants" subtitle="The participants registered into your team." :sort="sort">
          <template #filter>
            <JetInput id="search" v-model="search" type="text" placeholder="Search" class="w-full max-w-sm flex-1" @keyup="searchParticipant" />
          </template>
        </ParticipantsTable>
      </div>
    </div>
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import ParticipantsTable from './Partials/Table.vue'
import JetInput from '@/Jetstream/Input.vue'
import { debounce } from '@/base'

const debounced = debounce((callback) => callback(), 500)

export default {
  components: {
    Head,
    JetInput,
    ParticipantsTable,
  },
  layout: [AppLayout],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    participants: {
      type: Object,
    },
    filter: Object,
    sort: String,
  },
  data() {
    return {
      search: '',
    }
  },
  created() {
    this.search = this.filter?.search
  },
  methods: {
    searchParticipant() {
      debounced(() => {
        this.$inertia.get(
          '/dashboard/participants',
          {
            filter: {
              search: this.search,
            },
          },
          {
            preserveScroll: true,
            preserveState: true,
          },
        )
      })
    },
  },
}
</script>
