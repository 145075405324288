<template>
  <div>
    <Head title="Dashboard" />
    <div class="mx-auto max-w-7xl space-y-8 sm:px-6 lg:px-8">
      <Panel v-if="activeStep">
        <template #title>Welcome to Checkmango</template>

        <div class="px-4 py-6 sm:p-6 md:py-0">
          <Onboarding :steps="steps.data" :active-step="activeStep" />
        </div>
      </Panel>

      <Panel v-if="participantSeries[0].data.length > 0">
        <template #title> Participant Growth </template>

        <template #actions>
          <select id="tabs" name="tabs" class="rounded-md border-slate-300 py-2 pl-3 pr-10 text-base focus:border-slate-500 focus:outline-none focus:ring-slate-500 sm:text-sm" @change="changeRange">
            <template v-for="dataRange in dataRanges" :key="dataRange">
              <option :value="slug(dataRange)" :selected="slug(dataRange) === currentRange">
                {{ dataRange }}
              </option>
            </template>
          </select>
        </template>

        <VueApexCharts type="area" height="350" :options="chartOptions" :series="participantSeries" />
      </Panel>

      <Panel>
        <template #title>Dashboard</template>

        <div class="px-4 py-6 sm:p-6 md:py-0">
          <EmptyState :items="dashboardActions" />
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import { markRaw } from 'vue'
import { BeakerIcon, AcademicCapIcon, CodeBracketIcon, BoltIcon } from '@heroicons/vue/16/solid'
import Panel from '@/Components/Panel.vue'
import EmptyState from '@/Components/EmptyState.vue'
import Onboarding from './Partials/Onboarding.vue'
import ParticipantsIcon from '@/Icons/ParticipantsIcon.vue'
import ToggleIcon from '@/Icons/ToggleIcon.vue'

export default {
  components: {
    Head,
    EmptyState,
    Onboarding,
    Panel,
    VueApexCharts,
  },
  layout: [AppLayout],
  props: {
    currentRange: String,
    growth: {
      type: Array,
      default: () => [],
    },

    steps: Object,
    activeStep: Number,
  },
  data() {
    return {
      dataRanges: ['Last 7 days', 'Last 30 days', 'Last 90 days', 'This month', 'This year', 'All time'],
      chartOptions: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#FFAF3F'],
        fill: {
          type: 'gradient',
        },
        stroke: {
          curve: 'smooth',
          lineCap: 'round',
          width: 4,
          opacity: 0.7,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: this.getDateLabelFormat(),
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          borderColor: 'rgba(0,0,0,0.08)',
          strokeDashArray: 0,
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      },
    }
  },
  computed: {
    participantSeries() {
      return [
        {
          name: 'Participants',
          data: this.growth,
        },
      ]
    },
    dashboardActions() {
      return [
        {
          title: 'Create An Event',
          description: 'Your application needs to trigger an event when a user has completed an action, for example; creating a new project, adding an item to a basket etc.',
          icon: markRaw(BoltIcon),
          background: 'bg-yellow-400',
          link: {
            href: this.route('events.create'),
            component: 'Link',
          },
        },
        {
          title: 'Create An Experiment',
          description: 'Think of experiments as a single \'test\' or \'campaign\' within your website or application. An experiment will have two or more variants that you test against each other.',
          icon: markRaw(BeakerIcon),
          background: 'bg-red-500',
          link: {
            href: this.route('experiments.create'),
            component: 'Link',
          },
        },
        {
          title: 'Create A Feature',
          description: 'Feature flags allow you to control the visibility of a feature in your application. You can use feature flags to enable or disable a feature.',
          icon: markRaw(ToggleIcon),
          background: 'bg-amber-500',
          link: {
            href: this.route('features.create'),
            component: 'Link',
          },
        },
        {
          title: 'Enroll Participants',
          description: 'Participants are users that you want to include in your experiments. You can enroll participants by using the Checkmango SDKs or by using the API.',
          icon: markRaw(ParticipantsIcon),
          background: 'bg-teal-500',
          link: {
            href: this.route('features.create'),
            component: 'Link',
          },
        },
        {
          title: 'Learn How To Integrate Checkmango',
          description: 'With your events and experiments created, you\'ll need to integrate Checkmango into your site using the available SDKs and Checkmango API.',
          icon: markRaw(AcademicCapIcon),
          background: 'bg-blue-500',
          link: {
            href: 'https://docs.checkmango.com',
            component: 'a',
            target: '_blank',
          },
        },
        {
          title: 'Browse The API Documentation',
          description: 'Checkmango provides a full JSON API, available as a fully documented OpenAPI spec file. You can do everything from creating experiments, listing teams, to ingesting data.',
          icon: markRaw(CodeBracketIcon),
          background: 'bg-emerald-500',
          link: {
            href: 'https://developers.checkmango.com',
            component: 'a',
            target: '_blank',
          },
        },
      ]
    },
  },
  methods: {
    changeRange(dateRange) {
      this.$inertia.visit(this.route('dashboard', { range: dateRange.target.value }), {
        preserveScroll: true,
        only: ['currentRange', 'growth'],
      })
    },
    slug(string) {
      return string.toLowerCase().replaceAll(' ', '-')
    },
    getDateLabelFormat() {
      switch (this.currentRange) {
      case 'last-7-days':
      case 'last-30-days':
      case 'last-90-days':
        return 'MMM dd'
      case 'this-year':
        return 'MMM'
      case 'this-month':
        return 'dd'
      case 'all-time':
        return 'MMM yyyy'
      }
    },
  },
}
</script>
