<template>
  <JetConfirmationModal :show="experiment !== null" @close="$emit('close')">
    <template #title> Reset Experiment </template>

    <template #content>
      <p>Are you sure you want to reset this experiment? All participant enrollments and metrics will be wiped.</p>

      <p>The experiment will also be moved back to the "draft" status.</p>

      <p class="font-semibold">This will result in lost data and cannot be undone.</p>
    </template>

    <template #footer>
      <JetSecondaryButton @click="$emit('close')"> Never mind </JetSecondaryButton>

      <JetDangerButton class="ml-2" @click="$emit('confirm')"> Yes, Reset This Experiment </JetDangerButton>
    </template>
  </JetConfirmationModal>
</template>

<script>
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'

export default {
  components: {
    JetConfirmationModal,
    JetSecondaryButton,
    JetDangerButton,
  },
  props: {
    experiment: {
      type: Object,
      required: false,
    },
  },
  emits: ['close', 'confirm'],
}
</script>
