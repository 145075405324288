<template>
  <button @click="sort">
    <ArrowsUpDownIcon v-if="activeSortColumn !== column || direction === 0" class="size-4" />
    <ArrowDownIcon v-else-if="activeSortColumn === column && direction === -1" class="size-4" />
    <ArrowUpIcon v-else-if="activeSortColumn === column && direction === 1" class="size-4" />
  </button>
</template>

<script>
import { ArrowDownIcon, ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/vue/24/solid'

export default {
  components: {
    ArrowsUpDownIcon,
    ArrowDownIcon,
    ArrowUpIcon,
  },
  props: {
    column: String,
    activeSort: String,
  },
  emits: ['sort'],
  data() {
    return {
      direction: 0, // 0 = inactive, -1 = desc, 1 = asc
    }
  },
  computed: {
    sortString() {
      return (this.direction === -1 ? '-' : '') + this.column
    },
    activeSortColumn() {
      return this.activeSort?.replace('-', '')
    },
  },
  watch: {
    activeSort: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }

        const column = value.replace('-', '')

        if (column !== this.column) {
          return
        }

        this.direction = value[0] === '-' ? -1 : 1
      },
    },
  },
  methods: {
    sort() {
      // If the direction === 0 (inactive) then set to desc.
      if (this.direction === 0) {
        this.direction = -1
      } else if (this.direction === -1) {
        this.direction = 1
      } else if (this.direction === 1) {
        this.direction = -1
      }

      this.$emit('sort', this.sortString)
    },
  },
}
</script>
