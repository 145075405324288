<template>
  <div>
    <Head :title="`${variant.key} (${experiment.key}) | Variants`" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="grid grid-cols-1 gap-8 md:grid-cols-3">
        <LoadingCard :loading="false" class="h-40 w-full px-6 py-4">
          <div class="mb-4 flex">
            <h3 class="text-lg font-medium leading-6 text-slate-900">Enrolled Participants</h3>
          </div>

          <p class="mb-4 flex items-center text-4xl">
            <span v-if="variant.participant_count">{{ numberFormat(variant.participant_count) }}</span>
            <span v-else>&mdash;</span>
          </p>
        </LoadingCard>

        <LoadingCard :loading="false" class="h-40 w-full px-6 py-4">
          <div class="mb-4 flex">
            <h3 class="text-lg font-medium leading-6 text-slate-900">Impressions</h3>
          </div>

          <p class="mb-4 flex items-center text-4xl">
            {{ numberFormat(variant.impression_count) }}
          </p>
        </LoadingCard>

        <LoadingCard :loading="false" class="h-40 w-full px-6 py-4">
          <div class="mb-4 flex">
            <h3 class="text-lg font-medium leading-6 text-slate-900">Conversions</h3>
          </div>

          <p class="mb-4 flex items-center text-4xl">
            {{ numberFormat(variant.conversion_count) }}
          </p>
        </LoadingCard>
      </div>

      <JetSectionBorder />

      <JetFormSection>
        <template #title>Details</template>

        <template #description>Here you'll find all of the details for this variant.</template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <JetLabel>Key</JetLabel>

            <Clipboard>{{ variant.key }}</Clipboard>
          </div>

          <div class="col-span-6 sm:col-span-4">
            <JetLabel>Control</JetLabel>

            <CheckCircleIcon v-if="variant.control" class="size-5 text-emerald-500" />
            <XCircleIcon v-else class="size-5 text-red-500" />
          </div>

          <div v-if="experiment.algorithm_uses_weights" class="col-span-6 sm:col-span-4">
            <JetLabel>Traffic</JetLabel>

            {{ variant.traffic }}%
          </div>

          <div class="col-span-6 sm:col-span-4">
            <JetLabel>Date Created</JetLabel>

            {{ dateTimeFormat(variant.created_at) }}
          </div>
        </template>

        <template v-if="can.edit_variant" #actions>
          <JetSecondaryButton class="ml-2" @click="$inertia.visit(route('experiments.variants.edit', [experiment.key, variant.key]))">Edit</JetSecondaryButton>
        </template>
      </JetFormSection>

      <JetSectionBorder />

      <JetFormSection>
        <template #title>Description</template>

        <template #description>Including a description is a great way of explaining why this variant is being tested.</template>

        <template #form>
          <div class="prose prose-slate col-span-full text-slate-600" v-html="description" />
        </template>
      </JetFormSection>

      <JetSectionBorder />

      <DangerActionSection>
        <template #title>Danger Zone</template>

        <template #content>
          <div class="space-y-4">
            <div class="max-w-xl">
              <span v-if="!experiment.is_running" type="error">Once you delete a variant, there is no going back. Please be certain.</span>
              <span v-if="experiment.is_running" type="warning">This variant is part of a running experiment and cannot be deleted at this time. Please finish the experiment first.</span>
              <span v-else-if="experiment.is_stopped">The experiment that this variant is part of has been stopped.</span>
            </div>

            <div v-if="can.delete_variant">
              <JetDangerButton @click="variantToDelete = variant">Delete Variant</JetDangerButton>
            </div>
          </div>
        </template>
      </DangerActionSection>
    </div>

    <JetConfirmationModal :show="variantToDelete !== null" @close="variantToDelete = null">
      <template #title> Delete Variant </template>

      <template #content>
        <p>Are you sure you want to delete this variant? Collected data for this variant will be lost.</p>

        <p>Keep in mind that this action cannot be undone.</p>
      </template>

      <template #footer>
        <JetSecondaryButton @click="variantToDelete = null"> Never mind </JetSecondaryButton>

        <JetDangerButton class="ml-2" @click="variantDeleteRequest"> Delete Variant </JetDangerButton>
      </template>
    </JetConfirmationModal>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import Clipboard from '@/Components/Clipboard.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import JetSectionBorder from '@/Jetstream/SectionBorder.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import LoadingCard from '@/Components/LoadingCard.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import DangerActionSection from '@/Jetstream/DangerActionSection.vue'

export default {
  components: {
    DangerActionSection,
    Head,
    Clipboard,
    LoadingCard,
    JetSectionBorder,
    JetConfirmationModal,
    JetLabel,
    JetDangerButton,
    JetFormSection,
    JetSecondaryButton,
    CheckCircleIcon,
    XCircleIcon,
  },
  layout: [AppLayout],
  props: {
    can: Object,
    links: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: Object,
      default: () => {},
    },
    experiment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      variantToDelete: null,
    }
  },
  computed: {
    description() {
      return this.variant.description || '&mdash;'
    },
  },
  methods: {
    variantDeleteRequest() {
      this.$inertia.delete(this.route('experiments.variants.destroy', [this.experiment.key, this.variantToDelete.key]))

      this.variantToDelete = null
    },
  },
}
</script>
