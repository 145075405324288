// require('./bootstrap')

import * as Sentry from '@sentry/vue'

import Vapor from 'laravel-vapor'
Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)

import '../css/app.css'
import 'tippy.js/dist/tippy.css'

import { createApp, h } from 'vue'
import { createInertiaApp, router } from '@inertiajs/vue3'
import VueClipboard from 'vue3-clipboard'
import VueTippy from 'vue-tippy'
import { default as base } from './base'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'

import { ZiggyVue } from 'ziggy-js'
import { Ziggy } from '@/ziggy.js'

createInertiaApp({
  title: (title) => `${title} | Checkmango`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue', { eager: true })),
  setup({ el, App, props, plugin }) {
    const app = createApp({render: () => h(App, props) })

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: ['checkmango.com'],
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.5,
    })

    app.use(ZiggyVue, Ziggy)
      .use(plugin)
      .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
      })
      .mixin(base)
      .mixin({
        methods: {
          asset: Vapor.asset,
        },
      })
      .use(VueTippy)
      .mount(el)
  },
  progress: {
    delay: 250,
    includeCSS: true,
    color: '#1d4ed8',
  },
})

router.on('navigate', () => {
  if (typeof window === 'undefined') {
    return
  }

  if (window.fathom) {
    window.fathom.trackPageview()
  }

  if (window.Beacon) {
    setTimeout(() => {
      window.Beacon(
        'event',
        {
          type: 'page-viewed',
          url: window.document.location.href,
          title: window.document.title,
        },
        50,
      )
    })
  }
})
