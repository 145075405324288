<template>
  <a :href="href" v-bind="$attrs" class="block px-4 py-2 leading-5 text-slate-700 transition duration-150 ease-in-out hover:bg-slate-100 focus:bg-slate-100 focus:outline-none">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    href: String,
  },
}
</script>
