<template>
  <button :type="type" class="button button--secondary">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
}
</script>
