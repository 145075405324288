<template>
  <div>
    <Head :title="`${feature.key} | Features`" />

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <FormSection>
        <template #title>Details</template>

        <template #description>Here you'll find the feature's details.</template>

        <template #form>
          <div class="col-span-6 sm:col-span-4">
            <Label>Key</Label>

            <Clipboard>{{ feature.key }}</Clipboard>
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Value</Label>

            <Clipboard v-if="feature.value">{{ feature.value }}</Clipboard>
            <span v-else>&mdash;</span>
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Enabled</Label>

            {{ feature.enabled ? 'Yes' : 'No' }}
          </div>

          <div class="col-span-6 sm:col-span-4">
            <Label>Date Created</Label>

            {{ dateTimeFormat(feature.created_at) }}
          </div>
        </template>

        <template v-if="can.edit_feature" #actions>
          <SecondaryButton @click="$inertia.visit(route('features.edit', [feature.key]))">Edit</SecondaryButton>
        </template>
      </FormSection>

      <SectionBorder />

      <FormSection>
        <template #title>Description</template>

        <template #description>Including a description is helpful to share why this feature exists and what it represents.</template>

        <template #form>
          <div v-if="!descriptionForm.editing" class="prose prose-slate col-span-full text-slate-600" v-html="description" />
          <div v-else class="col-span-6 sm:col-span-4">
            <Label value="Description" />
            <Editor :initial-content="descriptionForm.description" @content-changed="handleEditorContentChange" />
          </div>
        </template>

        <template #actions>
          <SecondaryButton type="button" @click="toggleEditing">{{ descriptionForm.editing ? 'Cancel' : 'Edit' }}</SecondaryButton>
          <Button v-if="descriptionForm.editing" type="button" class="ml-2" :class="{ 'opacity-25': descriptionForm.processing }" :disabled="descriptionForm.processing" @click="saveDescription">Save</Button>
        </template>
      </FormSection>

      <SectionBorder />

      <DangerActionSection>
        <template #title>Danger Zone</template>

        <template #description>Dragons around, be careful.</template>

        <template #content>
          <div class="space-y-4">
            <DangerButton :disabled="!can.delete_feature" @click="featureToDelete = feature">Delete Feature</DangerButton>
          </div>
        </template>
      </DangerActionSection>
    </div>

    <DeleteFeatureModal :feature="featureToDelete" @close="featureToDelete = null" @confirm="deleteFeature" />
  </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import { Head } from '@inertiajs/vue3'
import Clipboard from '@/Components/Clipboard.vue'
import FormSection from '@/Jetstream/FormSection.vue'
import SecondaryButton from '@/Jetstream/SecondaryButton.vue'
import Label from '@/Jetstream/Label.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import SectionBorder from '@/Jetstream/SectionBorder.vue'
import DeleteFeatureModal from '@/Modals/DeleteFeatureModal.vue'
import DangerActionSection from '@/Jetstream/DangerActionSection.vue'
import Button from '@/Jetstream/Button.vue'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button,
    Editor: defineAsyncComponent(() => import('@/Components/Editor.vue')),
    DangerActionSection,
    Head,
    Clipboard,
    FormSection,
    Label,
    SecondaryButton,
    DangerButton,
    SectionBorder,
    DeleteFeatureModal,
  },
  layout: [AppLayout],
  props: {
    can: Object,
    links: {
      type: Array,
      default: () => [],
    },
    feature: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      featureToDelete: null,

      descriptionForm: this.$inertia.form({
        editing: false,
        description: this.feature.description,
      }),
    }
  },
  computed: {
    description() {
      return this.feature.description || '&mdash;'
    },
  },
  methods: {
    deleteFeature() {
      this.$inertia.delete(this.route('features.destroy', [this.featureToDelete.key]))

      this.featureToDelete = null
    },
    toggleEditing() {
      this.descriptionForm.editing = !this.descriptionForm.editing
    },
    handleEditorContentChange(content) {
      this.descriptionForm.description = content
    },
    saveDescription() {
      this.descriptionForm.put(this.route('features.update', this.feature.key), {
        preserveScroll: true,
        preserveState: true,
        only: ['feature'],
        onSuccess: () => {
          this.descriptionForm.description = this.feature.description
          this.descriptionForm.editing = false
        },
      })
    },
  },
}
</script>
