<template>
  <button :type="type" class="button button--success">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'submit',
    },
  },
}
</script>
