<template>
  <input v-model="proxyChecked" type="checkbox" :value="value" class="rounded border-slate-300 text-slate-600 shadow-sm focus:border-slate-300 focus:ring focus:ring-slate-200 focus:ring-opacity-50" />
</template>

<script>
export default {
  props: {
    checked: {
      type: [Array, Boolean],
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
  emits: ['update:checked'],

  computed: {
    proxyChecked: {
      get() {
        return this.checked
      },
      set(val) {
        this.$emit('update:checked', val)
      },
    },
  },
}
</script>
